import {Form, Row} from 'antd';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {CheckboxInput} from 'components/inputs/checkboxInput';
import {DynamicButton} from 'components/inputs/dinamicButton';
import {EmailInput} from 'components/inputs/emailInput';
import {PasswordInput, PasswordInput2} from 'components/inputs/passwordInput';
import {PhoneInput} from 'components/inputs/phoneInput';
import {TextInput} from 'components/inputs/textInput';
import {normalizeName} from 'components/normilize/name';
import {validatePasswordFormatted} from 'components/validate/passwordValidate';
import {createUser} from 'store/slices/auth';
import {LeftColumn} from 'style/colomn/leftColumn';
import {RightColumn} from 'style/colomn/rightColumn';
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {MainCenterTitleStyle} from 'style/titles/mainCenterTitleStyle';
import {WrapperSignUpStyled, WrapperTextStyled} from './styles';

export const SignUp: FC = memo(() => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  let history = useHistory();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch(createUser(values, form, () => {
      history.push(`/coworking`);
    }));
  };
  let url_prefix = 'prd'
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'develop' :
      url_prefix = 'dev'
      break;
    case 'stage' :
      url_prefix = 'stage'
      break;
  }
  return (
    <WrapperSignUpStyled>
      <WrapperContentStyled className="content" style={{maxWidth: 630, marginLeft: 'auto', marginRight: 'auto'}}>
        <Form form={form} onFinish={onFinish}>

          <MainCenterTitleStyle
            style={{float: 'none', textAlign: 'center', marginBottom: 50}}>{t('registration')}</MainCenterTitleStyle>

          <Row>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t('administrator_name')}</InputTitleStyled>
              <TextInput name={'first_name'} normalize={normalizeName} required={true}/>
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t('administrator_surname')}</InputTitleStyled>
              <TextInput name={'last_name'} normalize={normalizeName} required={true}/>
            </RightColumn>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t('password')}</InputTitleStyled>
              <PasswordInput name={'password'} required={true} validator={validatePasswordFormatted}/>
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t('password_repeat')}</InputTitleStyled>
              <PasswordInput2 name={'password2'} required={true}/>
            </RightColumn>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t('phone')}</InputTitleStyled>
              <PhoneInput name={'phone'} required={true}/>
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t('email')}</InputTitleStyled>
              <EmailInput name={'email'} required={true}/>
            </RightColumn>
          </Row>

          <CheckboxInput name={'conditions'} title={<WrapperTextStyled>{t("I_agree_with_the")}
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url_prefix}.cowork.co/media/about_us/privacy_policy_${window.navigator.language}.pdf`}
            >{t('privacy_policy')}</a> {t('and')}
            <a
              target="_blank"
              rel="noreferrer"
              href={`${url_prefix}.cowork.co/media/about_us/about_us_${window.navigator.language}.pdf`}
            >
              {' '}{t('terms_of_use')}
            </a>
            .</WrapperTextStyled>} required={true}/>

          <DynamicButton text={t('sign_up')} style={{width: '100%'}}/>
          <WrapperTextStyled style={{textAlign: 'center'}}>{t('perhaps_you_want_to')} <Link
            to="/">{t('log_in')}</Link>.</WrapperTextStyled>
        </Form>
      </WrapperContentStyled>
    </WrapperSignUpStyled>
  );
});
