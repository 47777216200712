import {UploadChangeParam, UploadFile} from "antd/lib/upload/interface";
import React, {FC, memo, useEffect} from 'react';
import {Upload, Form, message} from 'antd';
import {getLangKey} from "utils/lang";
import {
  CustomUpload,
  DeleteIconStyled,
  ImageStyled,
  MoreInformationWrapper,
  TextUploadStyled,
  UploadAreaWrapper,
  UploadButtonStyled,
  WrapperAreaTextStyled,
  WrapperDeleteStyled,
  WrapperErrorMessage,
  WrapperImageItem,
  WrapperUploadForm,
} from './styles';

import {useState, useCallback} from 'react';
import {Tooltip} from 'antd';
import {useDrag, useDrop} from 'react-dnd';
import update from 'immutability-helper';
import {useDispatch, useSelector} from 'react-redux';
import {dragUploadFileId, setDragUploadFileId} from 'store/slices/coworking';
import {useTranslation} from 'react-i18next';
import docIcon from './icons/doc.svg';
import pdfIcon from './icons/pdf.svg';
import {TextInput} from '../textInput';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {MAIN_BLUE_COLOR} from 'style/globalStyle/Color';
import {uploadImageValidate} from '../../validate/uploadImageValidate';
import {isErrorValidate} from '../../validate/isErrorValidate';


const type = 'DragableUploadList';

const DragableUploadListItem = memo((props: any) => {
  const fileId = useSelector(dragUploadFileId);
  const ref: any = React.useRef();
  const index: any = props.fileList.indexOf(props.file);
  const dispatch = useDispatch();
  const [{isOver, dropClassName}, drop] = useDrop({
    accept: type,
    collect: (monitor) => {
      const {index: dragIndex}: any = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      props.moveRow(item.index, index);
    },
  });
  const [{opacity}, drag] = useDrag({
    type,
    item: {index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      opacity: monitor.isDragging() ? 0.2 : 1,
    }),
  });
  drop(drag(ref));
  const errorNode = <Tooltip title="Upload Error">{props.originNode.props.children}</Tooltip>;

  const test = (opacity: any) => {
    if (opacity === 0.2) {
      dispatch(setDragUploadFileId(props.file?.uid));
    }
    return opacity;
  };

  return (
    <div
      ref={ref}
      className={`ant-upload-draggable-list-item ${isOver && props.fileList.find((item: any) => item.uid === fileId) ? dropClassName : ''}`}
      style={{cursor: 'move', opacity: test(opacity)}}
    >
      {props.file.status === 'error' ? errorNode : props.originNode}
    </div>
  );
});

const RenderItem: FC<any> = memo((props) => {
  const [isAnimation, setIsAnimation] = useState(false);
  const [t] = useTranslation();
  useEffect(() => {
    setTimeout(() => setIsAnimation(true), 1);
  }, []);
  let urlIcon = props.thumbUrl;
  if (props.type === 'application/pdf') {
    urlIcon = pdfIcon
  }
  if (props.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || props.type === 'application/msword') {
    urlIcon = docIcon;
  }
  if (props.type === '') {
    urlIcon = docIcon;
  }

  let error = false;
  if (props.file.size > 10485760) {
    error = true
  }
  if (props.types.indexOf(props.type) === -1) {
    error = true
  }

  const onDeleteClicked = (e: React.MouseEvent<HTMLDivElement>) => {
    if (props.disabled) {
      return
    }
    e.stopPropagation();
    props.deleteFile(props.id);
    setIsAnimation(false)
  }

  return (
    <WrapperImageItem isError={error} isAnimation={isAnimation} className="test">
      <ImageStyled style={{
        backgroundImage: `url(${urlIcon})`,
        backgroundSize: urlIcon === docIcon || urlIcon === pdfIcon ? "contain" : ""
      }} onClick={() => {
        if (props.file.fileUrl) {
          window.open(props.file.fileUrl, 'blank')
        }
      }
      }/>
      {!props.hideDescription && (
        <WrapperAreaTextStyled>
          <InputTitleStyled>{t('name')}</InputTitleStyled>
          <TextInput
            name={[...props.name, ...[getLangKey()]]}
            required={true}
          />
        </WrapperAreaTextStyled>
      )}

      <WrapperDeleteStyled onClick={onDeleteClicked}>
        <DeleteIconStyled/>
        <a style={{borderBottom: `1px solid ${MAIN_BLUE_COLOR}`}}>{t("delete")}</a>
      </WrapperDeleteStyled>
    </WrapperImageItem>
  );
});

interface IUploadForm {
  name: Array<any>;
  required: boolean;
  types: Array<string>;
  text?: string;
  form: any;
  maxCount?: number;
  minCount?: number;
  nameParent?: any;
  onDeleteItem?: (id: number) => Promise<void>;
  type?: any;
  disabledLength?: number;
  disabled?: boolean;
  hideDescription?: boolean;
  defaultDescriptionName?: (index: number) => string
}

export interface IUpload {
  images: UploadChangeParam,
  isError: boolean,
  order: []
}

export interface IUploadWithFileUrl extends IUpload {
  images: UploadChangeParam<UploadFile & { fileUrl: string }>
}

const createOreder = (list: any) => {
  const newOreder = []
  for (let i in list) {
    newOreder.push(+i)
  }
  return newOreder
}

export const UploadForm: FC<IUploadForm> = (props) => {
  const [t] = useTranslation()
  const [, forceUpdate] = useState({})
  let fileList: any;
  if (props.nameParent) {
    fileList = props.form.getFieldValue([...[props.nameParent], ...props.name, ...['images']])?.fileList || []
  } else {
    fileList = props.form.getFieldValue([...props.name, ...['images']])?.fileList || []
  }

  const setFileList = (value: any) => {
    if (props.nameParent) {
      props.form.setFields([
        {
          name: [...[props.nameParent], ...props.name, ...['images', 'fileList']],
          value: value,
        },
      ]);
    } else {
      props.form.setFields([
        {
          name: [...props.name, ...['images', 'fileList']],
          value: value,
        },
      ]);
    }
  }
  const [, setMainPage] = useState('');
  const [isError, setIsError] = useState(false);
  const fileId = useSelector(dragUploadFileId);
  const [messageError, setMessageError] = useState('')
  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const dragRow = fileList[dragIndex];
      if (fileList.find((item: any) => item.uid === fileId)) {
        setFileList(
          update(fileList, {
            $splice: [
              [dragIndex, 1],
              [hoverIndex, 0, dragRow],
            ],
          })
        );
        forceUpdate({})
      }
    },
    [fileList, fileId]
  );

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    setFileList(info.fileList);
    let name = [...props.name, ...[info.file.uid], getLangKey()]
    if (props.nameParent) {
      name = [...[props.nameParent], ...props.name, ...[info.file.uid], getLangKey()]
    }
    props.form.setFields([{
      name,
      value: props.defaultDescriptionName ? props.defaultDescriptionName(info.fileList.length) : ''
    }])
    forceUpdate({})
  };

  const deleteFile = (id: any) => {
    setFileList(
      fileList.filter((file: any) => {
        return file.uid !== id;
      })
    );
    if (props.onDeleteItem) {
      props.onDeleteItem(id)
    }
    forceUpdate({})
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow: any = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  const dummyRequest = ({onSuccess}: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  useEffect(() => {
    let error = false;

    if (fileList.length === 0 && props.required) {
      error = true
      setMessageError(t("upload_the_files_please"))
    }

    if ((fileList.length !== 0) || (props.form.getFieldValue('isError'))) {
      for (let i of fileList) {
        if (props.types.indexOf(i.type) === -1) {
          error = true
          setMessageError(t("invalid_file_format"))
        }
        if (i.size > 10485760) {
          error = true
          setMessageError(t("the_file_is_too_large"))
        }
      }

      if (fileList.length > (props.maxCount || 3)) {
        error = true
        setMessageError(`${t("maximum_number_of_files")} ${props.maxCount || 3}.`)
      }

      if (fileList.length < (props.minCount || 0)) {
        error = true
        setMessageError(`${t("minimum_number_of_files")} ${props.minCount || 0}.`)
      }
    }
    if (props.nameParent) {
      props.form.setFields([
        {
          value: error,
          name: [...[props.nameParent], ...props.name, ...['isError']],
          errors: error ? [error] : null,
        },
      ]);
    } else {
      props.form.setFields([
        {
          value: error,
          name: [...props.name, ...['isError']],
          errors: error ? [error] : null,
        },
      ]);
    }

    setIsError(error)
  }, [fileList, fileId]);

  const disabled = props?.disabledLength !== undefined ? fileList.length >= props?.disabledLength : false
  return (
    <WrapperUploadForm>
      <MoreInformationWrapper isError={isError}>
        <Form.Item name={[...props.name, ...['isError']]} rules={[{validator: isErrorValidate}]} noStyle
                   initialValue={false}/>
        <Form.Item name={[...props.name, ...['order']]} style={{display: 'none'}}
                   initialValue={createOreder(fileList)}/>
        <Form.Item
          name={[...props.name, ...['images']]}
          rules={[{
            required: props.required,
            message: 'dfsgfdgdfg',
            validator: uploadImageValidate
          }]}
          style={{margin: '0px !important'}}
        >
          <CustomUpload
            beforeUpload={(file) => {
              const isPNG = file.type === 'image/png';
              const isJPEG = file.type === 'image/jpeg';
              const isGIF = file.type === 'image/gif';
              const isDOC = file.type === 'application/msword';
              const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
              const isPDF = file.type === 'application/pdf';
              switch (props.type) {
                case 'docs':
                  if (!isDOC && !isPDF && !isDOCX) {
                    message.error(`${file.name} ${t("is_not_a_correct_file")}`);
                  }
                  break
                case 'images':
                  if (!isPNG && !isJPEG && !isGIF) {
                    message.error(`${file.name} ${t("is_not_a_correct_file")}`);
                  }
              }
              return props?.type === 'images' ?
                isPNG || isJPEG || isGIF || Upload.LIST_IGNORE :
                isDOC || isPDF || isDOCX || Upload.LIST_IGNORE
            }}
            customRequest={dummyRequest}
            multiple={true}
            maxCount={props.maxCount}
            fileList={fileList}
            onChange={onChange}
            itemRender={() => {
              return <></>;
            }}
            onPreview={onPreview}
          >
            <UploadAreaWrapper>
              <TextUploadStyled>{props.text || t("Drag & drop your images here (jpg, png and gif) or upload by default")}</TextUploadStyled>
              <UploadButtonStyled disabled={disabled || props.disabled}>{t("upload")}</UploadButtonStyled>
            </UploadAreaWrapper>
            {isError &&
              <WrapperErrorMessage>
                {messageError}
              </WrapperErrorMessage>
            }
          </CustomUpload>
        </Form.Item>
      </MoreInformationWrapper>
      <Form.Item
        name={[...props.name, ...['images']]}
        noStyle
      >
        <Upload
          listType="picture-card"
          customRequest={dummyRequest}
          fileList={fileList}
          // onChange={onChange}
          multiple={true}
          itemRender={(originNode, file, currFileList) => (
            <DragableUploadListItem
              originNode={
                <RenderItem
                  thumbUrl={file.thumbUrl}
                  file={file}
                  isMainFile={currFileList?.[0]?.uid === file?.uid}
                  setMainFile={setMainPage}
                  type={file.type}
                  id={file.uid}
                  deleteFile={deleteFile}
                  name={[...props.name, ...[file?.uid]]}
                  types={props.types}
                  // fileUrl={props.fileUrl}
                  disabled={props.disabled}
                  hideDescription={props.hideDescription}
                />
              }
              file={file}
              fileList={currFileList}
              moveRow={moveRow}
            />
          )}
        ></Upload>
      </Form.Item>
    </WrapperUploadForm>
  );
};
