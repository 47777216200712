import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR } from '../../../style/globalStyle/Color';

export const MoreInformationButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  left: 7px;
  bottom: -35px;
  cursor: pointer;
  transform: rotate(-90deg);
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  span {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
    fill: ${MAIN_BLUE_COLOR};
  }
`;

export const WrapperWeekInputStyled = styled.div<any>`
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  * {
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  ${(props) =>
    props.isView
      ? `
    height: 330px;
    margin-top: -55px;
    & > div:first-child{
      opacity : 0;
    }
    & > div{
      opacity : 1;
    }
  `
      : `
    & > div{
      opacity : 0;
    }
    & > div:first-child{
      opacity : 1;
    }
    height: 55px;
  `}
`;

export const WrapperChangeStyled = styled.div`
  width: 100%;
  height: 55px;
  margin-bottom: 0px;
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  cursor: pointer;
  overflow: hidden;
  & >div:first-child{
    border-right: 1px solid ${MAIN_BORDER_COLOR};
  }
`

export const ChoiceStyled = styled.div<any>`
  width: 50%;
  height: 100%;
  float: left;
  text-align: center;
  ${props => props.isActive ? `
    color: white;
    background-color: ${MAIN_BLUE_COLOR};
  `:``}
  display: flex;
  justify-content: center;
  align-items: center;
`

