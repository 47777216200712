import styled from 'styled-components';
import phone from './icons/phone.svg';

export const CustomWrapperMaskStyled = styled.div`
  input {
    height: 40px;
    font-size: 16px;
  }
  .ant-form-item-control-input-content{
    position: relative;
    &:before{
      content: '';
      display: block;
      position: absolute;
      right: 11px;
      top: 11px;
      width: 18px;
      height: 18px;
      z-index: 2;
      background-image: url(${phone});
      background-size: cover;
      background-position: center;
    }
  }
`;
