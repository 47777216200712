export const onlyNumber = (value: string): any => {
  if (value === undefined) {
    return 0;
  }
  if (value === '') {
    return value;
  }
  return (value + '').replace(' ', '').replace(/\D/g, '').replace(/^0+/, '') || 0;
};
export const onlyAnyNumber = (value?: string): any => {
  if (value === undefined) {
    return 0;
  }
  if (value === '') {
    return value;
  }
  return (value + '').replace(' ', '').replace(/\D/g, '').replace(/[^\d]+|^0+(?!$)/g, '') || 0;
};
export const onlyOneNumber = (value: string): any => {
  if (value === undefined) {
    return 1;
  }
  return (value + '').replace(' ', '').replace(/\D/g, '').replace(/^0+/, '') || 0;
};

export const onlyNumberString = (value?: string): string => {
  return value ? value.replace(' ', '').replace(/\D/g, '') || '0' : '0';
};

export const onlyNumberWithZero = (value: string): any => {
  if (value === undefined) {
    return 0;
  }
  return (value + '').replace(' ', '').replace(/[^0-9.]/g, '')
};

export const NumberOrNull = (value: string | number | undefined): number | null => {
  if (typeof value === 'undefined') {
    return null
  }
  if (value === '') {
    return null
  }
  const result = Number((value + '').replace(' ', '').replace(/\D/g, '').replace(/^0+/, ''))
  return !Number.isNaN(result) ? result : null
}