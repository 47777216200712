import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR, MAIN_RED_COLOR } from '../../style/globalStyle/Color';
import ICoworkIcon from './icons/ICowork.svg';
import PhoneIcon from './icons/phone.svg';

interface IIsView {
  isViewMobile: boolean;
}

export const WrapperHeaderStyled = styled.div<IIsView>`
  width: 100%;
  height: 80px;
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  box-shadow: 4px 4px 8px rgba(88, 100, 159, 0.06);
  background-color: white;
  .active{
    position: relative;
  }
  .active::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 100%;
    height: 2px;
    background-color: ${MAIN_RED_COLOR};
  }
  @media (max-width: 1480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (min-width: 318px) and (max-width: 991px) {
    .active::after{
      bottom: -10px;
    }
    /* position: absolute; */
    transition-duration: 0.15s;
    transition-timing-function: ease-in-out;
    left: 0;
    top: 0;
    height: 64px;
    ${props => !props.isViewMobile ? `
      top: -64px;
    `:``}
  }
`;

export const ICoworkIconStyled = styled.div`
  margin-left: 2px;
  width: 164px;
  height: 56px;
  background-image: url(${ICoworkIcon});
  background-size: cover;
  background-position: center;
  @media (min-width: 318px) and (max-width: 991px) {
    height: 40px;
    width: 118px;
  }
`;

export const PhoneStyled = styled.a`
  float: right;
  margin-right: 30px;
  -webkit-font-smoothing: antialiased;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.75;
  text-transform: uppercase;
  box-sizing: inherit;
  font-family: PT_Root_UI_Regular, sans-serif;
  text-decoration: none;
  color: #000 !important;
  font-size: 18px;
  font-weight: 200;
  letter-spacing: 1.8px;
  float: right;
  margin-left: auto;
  margin-top: auto;
  position: relative;
  @media (min-width: 318px) and (max-width: 768px) {
    display: none;
  }
`;

export const UserNameStyled = styled.div`
  float: right;
  margin-top: auto;
  padding-right: 13px;
  font-size: 14px;
  letter-spacing: 0.3px;
  line-height: 80px;
  height: 100%;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0em;

  max-width: 110px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const UserIconStyled = styled.div`
  float: right;
  width: 21px;
  margin-left: auto;
  margin-top: auto;
  background-size: cover;
  margin-right: 15px;
  height: 21px;
  margin-top: 29px;
  cursor: pointer;
`;

export const AboutUsStyled = styled.div`
  float: right;
  cursor: pointer;
  text-transform: uppercase;
  color: #505b67;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-family: PT_Root_UI_Regular, sans-serif;
  font-stretch: normal;
  letter-spacing: 0.3px;
  box-sizing: inherit;
  padding: 8px;
  padding-bottom: 13px;
  padding-top: 13px;
  margin-left: auto;
  margin-top: auto;
  transition-duration: 0.2s;
  margin-top: -4px;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const PhoneIconStyled = styled.div`
  width: 24px;
  height: 24px;
  margin-left: auto;
  margin-top: auto;
  position: absolute;
  left: -35px;
  top: 4px;
  background-image: url(${PhoneIcon});
  background-size: cover;
  background-position: center;
`;

export const WrapperMenuStyled = styled.div`
  position: absolute;
  height: 100%;
  width: calc(100% - 420px);
  top: 0;
  left: 270px;
  display: flex;
  -webkit-justify-content:space-between;      /*Chrome */
  justify-content: space-between;
  font-size: 16px;
  letter-spacing: 0em;
  @media (min-width: 991px) and (max-width: 1350px) {
    font-size: 14px !important;
    letter-spacing: -0.5px;
  }
  @media (max-width: 991px) {
    display: none;
  }
  @media (min-width: 318px) and (max-width: 991px) {
    display: block;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
`

export const MenuItemStyled = styled.div`
  text-align: center;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 100%;
  line-height: 80px;
  @media (min-width: 318px) and (max-width: 991px) {
    display: block;
    height: 50px;
    text-align: left;
    margin-left: 20px;
  }
`

export const ItemStyled = styled.div`
  display: inline-table;
  margin-left: 0;
  margin-right: 0;
  cursor: pointer;
  @media (min-width: 318px) and (max-width: 991px) {
    display: inline;

    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
  }
`

export const WrapperMobileMenu = styled.div`
  position: absolute;
  right: 4px;
  top: 32px;
  path, svg{
    fill: ${MAIN_BLUE_COLOR};
    height: 22px;
    width: 33px;
  }
  @media (min-width: 901px) {
    display: none;
  }
  cursor: pointer;
  @media (min-width: 318px) and (max-width: 991px) {
    top: 24px;
    path, svg{
      height: 16px;
      width: 24px;
    }
  }
`

export const WrapperUserIconStyled = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  top: 0;
  @media (max-width: 991px) {
    display: none;
  }
`

export const WrapperMobileStyled = styled.div`
  display: none;
  @media (max-width: 991px) {
    display: block;
    position: absolute;
    right: 56px;
    top: 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

  }
  @media (min-width: 318px) and (max-width: 991px) {
    top: 23px;
  }
`

interface IBurgerMenuIsconStyled {
  isActive: boolean;
}

export const BurgerMenuIsconStyled = styled.div<IBurgerMenuIsconStyled>`
  width: 24px;
  height: 2px;
  background: ${MAIN_BLUE_COLOR};
  margin-top: 7px;
  ${props => (props.isActive) ? `
      background: rgba(0,0,0,0);
    `:``}
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  &:before{
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 2px;
    background: ${MAIN_BLUE_COLOR};
    top: 0px;
    left: 0;
    ${props => (props.isActive) ? `
      background: grey;
      transform: rotate(45deg);
      top: 8px;
    `:``}
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
  &:after{
    content: '';
    display: block;
    position: absolute;
    width: 24px;
    height: 2px;
    background: ${MAIN_BLUE_COLOR};
    top: 14px;
    left: 0;
    ${props => (props.isActive) ? `
      background: grey;
      transform: rotate(-45deg);
      top: 8px;
    `:``}
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }
`

export const WrapperBurgerMenuStyled = styled.div`
  position: absolute;
  width: 23px;
  height: 2px;
  right: 20px;
  top: 27px;
  cursor: pointer;
  width: 23px;
  height: 16px;
`

export const WrapperMobileMenuStyled = styled.div<IBurgerMenuIsconStyled>`
  position: fixed;
  left: 0;
  top: 64px;
  width: 100%;
  height: calc(100% - 64px);
  background-color: white;
  border-top: 1px solid ${MAIN_BORDER_COLOR};
  left: 100%;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  z-index: 2;
  ${props => (props.isActive) ? `
    left: 0%;
  `:``}
`

export const WrapperLanguageSelect = styled.div`
  margin-left: 10px;
  height: 40px !important;
  .ant-select-selector{
    height: 40px !important;
  }
  span{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

export const WrapperOptionsStyled = styled.div`
  width: 30px;
  height: 30px;
  background-size: cover;
  background-position: center;
`
