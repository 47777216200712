import {List} from "pages/coworking/List/List";
import React, { memo, FC, useEffect } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import 'antd/dist/antd.less';
import 'material-icons/iconfont/material-icons.css';
import 'react-phone-input-2/lib/style.css'
import './App.css';
import { OrderManagement } from './pages/orderManagement';
import { Header } from './components/header';
import { SignUp } from './pages/auth/signup';
import { SignIn } from './pages/auth/signin';
import { HomePage } from './pages/home';
import { useDispatch, useSelector } from 'react-redux';
import { isPromptSelector, setUserData, userDataSelector } from './store/slices/auth';
import { useCookies } from 'react-cookie';
import { WrapperAppStyled } from './style/globalStyle/wrapperApp';
import { ResetPassword } from './pages/auth/resetPassword';
import { Footer } from './components/footer';
import { NewPassword } from './pages/auth/newPassword';
import {Coworking} from "./pages/coworking";

const App: FC = () => {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);
  const [cookie, setCookie] = useCookies<any>(['userData']);
  const isPrompt = useSelector(isPromptSelector)
  // const userCoworkings = useSelector(userCoworkingsSelector)
  useEffect(() => {
    if (cookie.userData && userData === null) {
      dispatch(setUserData(cookie.userData));
    }
    if (userData !== null && userData?.id !== cookie.userData?.id) {
      setCookie('userData', userData, { path: '/' });
    }

    if (window?.tokens && Object.keys(cookie?.tokens || {}).length === 0) {
      setCookie('tokens', window?.tokens, { path: '/' });
    }
  }, [cookie?.tokens, cookie.userData, dispatch, setCookie, userData])
  
  if (userData === null && !cookie.tokens && !cookie.userData?.id && location.pathname !== '/' && location.pathname.toLocaleLowerCase() !== '/signup' && location.pathname.toLocaleLowerCase() !== '/resetpassword/' && location.pathname.toLocaleLowerCase() !== '/new-password') {
    history.push('/');
  }

  if (userData !== null && cookie.userData?.id && (location.pathname === '/' || location.pathname.toLocaleLowerCase() === '/signup' || location.pathname.toLocaleLowerCase() === '/new-password')) {
    history.push('/homePage/UpdateUser');
  }

  if (!window?.tokens && Object.keys(cookie?.tokens || {}).length !== 0) {
    window.tokens = cookie.tokens;
  }
  return (
    <>
      <Header />
      <WrapperAppStyled isPrompt={isPrompt}>
        <Switch>
          <Route exact path="/" component={SignIn} />
          <Route exact path="/SignUp" component={SignUp} />
          <Route exact path="/ResetPassword" component={ResetPassword} />
          <Route exact path="/new-password" component={NewPassword} />
          <Route exact path="/OrderManagement" component={OrderManagement} />
          <Route path="/list" component={List} />
          <Route exact path="/coworking" component={Coworking} />
          <Route path="/coworking/:id" component={Coworking} />
          <Route path="/HomePage" component={HomePage} />
        </Switch>
      </WrapperAppStyled>
      <Footer />
      {/* <MobileMenu /> */}
    </>
  );
};

export default memo(App);
