import {Dropdown, Menu, Row} from 'antd';
import {FC, memo, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {resetAuth, userDataSelector} from 'store/slices/auth';
import {
  ICoworkIconStyled,
  ItemStyled,
  MenuItemStyled,
  UserIconStyled,
  UserNameStyled,
  WrapperHeaderStyled,
  WrapperMenuStyled,
  WrapperUserIconStyled
} from './style';
import UserIcon from './icons/userIcon.svg';
import ActiveUserIcon from './icons/activeUserIcon.svg';
import RedActiveUserIcon from './icons/redActiveUserIcon.svg';
import {useHistory, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useCookies} from 'react-cookie';
import {
  getUserCoworkings,
  resetCoworking,
  userCoworkingsSelector
} from 'store/slices/coworking';
import {useWindowSize} from 'react-use-size';
import {BurgerMenu} from './burgerMenu';
import {LanguageSelect} from './languageSelect';

export const pagesHome: any = {
  '/homepage/usercoworkings': 1,
  '/homepage/updateuser': 2,
};

export const Header: FC = memo(() => {
  const [t] = useTranslation();
  let location = useLocation();
  let history = useHistory();
  const dispatch = useDispatch();
  const userData = useSelector(userDataSelector);
  const page = pagesHome[location.pathname.toLowerCase()];
  const [, setCookie] = useCookies<any>(['userData']);
  const coworkings = useSelector(userCoworkingsSelector);
  const [isViewMobile, setIsViewMobile] = useState(true)
  const [isView, setIsView] = useState(false)

  useEffect(() => {
    if (userData?.id && !coworkings) {
      dispatch(getUserCoworkings())
    }
  }, [coworkings, dispatch, userData])

  const logout = () => {
    window.tokens = null;
    dispatch(resetAuth());
    dispatch(resetCoworking());

    setCookie('userData', {}, {path: '/'});
    setCookie('tokens', {}, {path: '/'});
    history.push('/');
  };

  useEffect(() => {
    let last_known_scroll_position = 0;
    const onScrollBody = () => {
      if (window.scrollY > last_known_scroll_position && window.scrollY > 50) {
        setIsViewMobile(false)
      } else if ((window.scrollY < last_known_scroll_position) && !(window.scrollY + window.innerHeight + 100 > document.documentElement.scrollHeight)) {
        setIsViewMobile(true)
      }
      last_known_scroll_position = window.scrollY;
    }
    window.addEventListener('scroll', onScrollBody);
    return () => document.removeEventListener('scroll', onScrollBody)
  }, [])

  const {width} = useWindowSize()
  const menu = (
    <Menu>
      <Menu.Item onClick={() => history.push(`/homePage/UpdateUser`)}>
        {t("account")}
      </Menu.Item>
      <Menu.Item onClick={logout}>
        {t("sign_out_of_your_account")}
      </Menu.Item>
    </Menu>
  );

  const desctopMenu = (
    <WrapperMenuStyled>
      {coworkings !== null && (coworkings?.active?.length !== 0 || coworkings?.approve?.length !== 0 || coworkings?.disabled?.length !== 0) &&
        <MenuItemStyled onClick={() => {
          width < 992 && setIsView(!isView)
          history.push('/list')
        }}>
          <ItemStyled className={page === 1 ? 'active' : ''}>
            {t("my_coworking_spaces")}
          </ItemStyled>
        </MenuItemStyled>
      }
      {coworkings !== null &&
        <>
          <MenuItemStyled onClick={() => {
            history.push('/coworking')
          }}>
            <ItemStyled className={(page !== 2 && page !== 1) ? 'active' : ''}>
              {t("add_coworking_space")}
            </ItemStyled>
          </MenuItemStyled>
        </>
      }
      <MenuItemStyled>
        <ItemStyled>
          {t("prices_and_tariffs")}
        </ItemStyled>
      </MenuItemStyled>
      <MenuItemStyled>
        <ItemStyled>
          {t("reviews")}
        </ItemStyled></MenuItemStyled>
      <MenuItemStyled>
        <ItemStyled>
          {t("users")}
        </ItemStyled>
      </MenuItemStyled>
      {userData?.id && width < 992 &&
        <>
          <MenuItemStyled onClick={() => {
            setIsView(!isView)
            history.push(`/homePage/UpdateUser`)
          }}>
            <ItemStyled className={page === 2 ? 'active' : ''}>
              {t("account")}
            </ItemStyled>
          </MenuItemStyled>
          <MenuItemStyled onClick={logout}>
            <ItemStyled>
              {t("sign_out_of_your_account")}
            </ItemStyled>
          </MenuItemStyled>
        </>
      }
    </WrapperMenuStyled>
  );
  return (
    <WrapperHeaderStyled isViewMobile={isViewMobile}>
      <Row style={{
        height: '100%',
        position: 'relative',
        alignItems: 'center',
        maxWidth: 1440,
        marginLeft: 'auto',
        marginRight: 'auto'
      }}>
        <ICoworkIconStyled/>
        <LanguageSelect/>
        {userData?.id && width >= 992 &&
          desctopMenu
        }

        {/* {userData?.id && width >= 576 &&
          <Dropdown overlay={mobileMenu} placement="bottomRight">
            <WrapperMobileMenu>
              <MenuOutlined width={24} height={16}/>
            </WrapperMobileMenu>
          </Dropdown>
        } */}

        {userData?.id && width < 992 &&
          <>
            <BurgerMenu isView={isView} setIsView={setIsView} menu={desctopMenu}/>
            {/* <WrapperMobileStyled>+ 7 (495) 149-89-99</WrapperMobileStyled> */}
          </>
        }

        {userData?.id &&
          <Dropdown overlay={menu} placement="bottomRight">
            <WrapperUserIconStyled>
              <UserIconStyled
                style={{backgroundImage: userData?.id ? (page === 2 ? `url(${RedActiveUserIcon})` : `url(${ActiveUserIcon})`) : `url(${UserIcon})`}}
                onClick={() => userData?.id ? history.push(`/homePage/UpdateUser`) : ''}
              ></UserIconStyled>
              <UserNameStyled>
                {userData?.first_name} {userData?.last_name}
              </UserNameStyled>
            </WrapperUserIconStyled>
          </Dropdown>
        }
        {/* <PhoneStyled href="tel:+74951498999"><PhoneIconStyled/>8(494) 149 89 99</PhoneStyled> */}
      </Row>
    </WrapperHeaderStyled>
  );
});






