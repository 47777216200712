import { FC, memo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { ButtonExitStyled, ButtonStyled, ButtonSuccessStyled, ModalStyled, TextStyled, WrapperModalStyled } from './styles';

const DeleteItemModal: FC<any> = memo((props) => {
  const [t] = useTranslation()
  const close = () => {
    ReactDOM.render(
      <></>,
      document.getElementById('modal')
    );
  }

  useEffect(()=>{
    if(window.innerWidth <= 576){
      const body = document.getElementsByTagName('body')[0];
      const scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      return(()=>{
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      })
    }
  },[])

  return (
    <WrapperModalStyled>
      <ModalStyled>
        <TextStyled>{t("are_you_sure_you_want_to_remove_the_tariff")}?</TextStyled>

        <ButtonStyled onClick={close}>{t("no")}</ButtonStyled>
        <ButtonSuccessStyled onClick={()=>{props.onSuccess();close()}}>{t("yes")}</ButtonSuccessStyled>
        <ButtonExitStyled onClick={close}/>
      </ModalStyled>
    </WrapperModalStyled>
  );
});


export const createDeleteModal = (onSuccess:any) => { 
  ReactDOM.render(
    <DeleteItemModal onSuccess={onSuccess}/>,
    document.getElementById('modal')
  );
}