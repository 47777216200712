import {postRequest, putRequest} from "core/fetchWrapper";
import {throwErrorIfFailed} from "pages/coworking/api/api";
import {I18NLangString, IAddress, ICoworking, ICreateAddress, ICRM, ICreateCoworking} from "pages/coworking/types";
import {getLocalOrEn, setLocalOrEverything} from "utils/lang";

export const createOrUpdateCoworkingGeneralData = (id: number | null, coworkingData: {
  title: I18NLangString,
  description: I18NLangString,
  website: string,
}, crms: {
  existingCrmList: ICRM[],
  newCrmTitles: string[],
}, userData: {
  first_name: string,
  last_name: string,
  phone: string,
  email: string
}) => {
  return new Promise<ICoworking>(async (resolve, reject) => {
    try {
      const existingCrm = crms.existingCrmList.filter(crm => crms.newCrmTitles.includes(getLocalOrEn(crm.name)!))
      const nonExistingCrm = crms.newCrmTitles.filter(crm => !existingCrm.map(c => getLocalOrEn(c.name)).includes(crm))
      let crmIds = existingCrm.map(c => c.id)

      const responses = await Promise.all(nonExistingCrm.map(crm => {
        return updateCrm({
          name: setLocalOrEverything(null, crm),
        });
      }))
      crmIds = crmIds.concat(responses.map(r => r.id))

      const body: ICreateCoworking = {
        title: coworkingData.title,
        description: coworkingData.description,
        web_site: coworkingData.website,
        crm: crmIds,
        managers: [
          {
            name: {description: userData.first_name + ' ' + userData.last_name},
            phone: userData.phone,
            email: userData.email,
          }
        ],
      };
      resolve(sendCreateOrUpdateCoworking(id, body))
    } catch (e) {
      reject(e)
    }
  })
};

export function sendCreateOrUpdateCoworking(id: number | null, data: ICreateCoworking): Promise<ICoworking> {
  let request;
  if (id) {
    request = putRequest(`/smp_api/coworking/${id}/`, data);
  } else {
    request = postRequest('/smp_api/coworking/', data);
  }
  return request.then(throwErrorIfFailed)
}

export function updateCrm(data: { name: I18NLangString }): Promise<ICRM> {
  return postRequest('/smp_api/crm/', data).then(throwErrorIfFailed)
}

export function updateAddress(id: number, data: ICreateAddress): Promise<IAddress> {
  return postRequest(`/smp_api/coworking/${id}/address/`, data).then(throwErrorIfFailed)
}
