import {getCoworkingUrlByStep, ICoworkingStep} from "pages/coworking/Steppers/Steppers";
import {CSSProperties, useEffect, useMemo, useState} from "react";
import {useCookies} from "react-cookie";
import {useTranslation} from "react-i18next";
import {EmptyBlock, ExitButtonStyled, WrapperPromptStyled, WrapperProptsStyled, WrapperTextStyled} from "./styles";

interface IProps {
  step: ICoworkingStep | null,
  style?: CSSProperties,
}

const key = 'prompts_new'

export const Prompts = (props: IProps) => {
  const [t] = useTranslation();
  const urlPage = props.step !== null ? getCoworkingUrlByStep(props.step) : null
  const [cookie, setCookie] = useCookies<any>([key]);
  const [openPrompts, setOpenPrompts] = useState<Record<string, number[]> | null>(null)

  const promptsList: Record<string, string[]> = t('prompts', {returnObjects: true})

  useEffect(() => {
    if (openPrompts === null) {
      if (cookie[key]) {
        setOpenPrompts((cookie[key]))
      } else {
        const promptsListKeys: any = {}
        for (let i in promptsList) {
          promptsListKeys[i] = Object.keys(promptsList[i]).map(s => Number(s))
        }
        setOpenPrompts(promptsListKeys)
        setCookie(key, promptsListKeys, {path: '/'});
      }
    }
  }, [cookie, openPrompts, promptsList, setCookie])

  const hasPromptsToShow: boolean = useMemo(() => {
    if (props.step === ICoworkingStep.ActivationPage) {
      return false
    }
    return !!(openPrompts && ((urlPage && openPrompts[urlPage] && openPrompts[urlPage].length > 0) || (openPrompts.all && openPrompts.all.length > 0)));
  }, [openPrompts, props.step, urlPage])

  if (!promptsList || !openPrompts || !hasPromptsToShow) {
    return null
  }

  const deletePrompt = (key: number, urlPage: string) => {
    const newPrompts = {...openPrompts}
    newPrompts[urlPage] = openPrompts[urlPage].filter(i => i !== key)
    setOpenPrompts(newPrompts)
    setCookie(key, newPrompts, {path: '/'});
  }

  return (
    <WrapperProptsStyled style={props.style}>
      {urlPage && openPrompts[urlPage].map(key => (
        <Prompt
          key={`page-${key}`}
          text={promptsList[urlPage][key]}
          delete={() => deletePrompt(key, urlPage)}
        />
      ))}
      {openPrompts.all.map(key => (
        <Prompt
          key={`all-${key}`}
          text={promptsList.all[key]}
          delete={() => deletePrompt(key, 'all')}
        />
      ))}
      <EmptyBlock></EmptyBlock>
    </WrapperProptsStyled>
  );
}

interface IPrompt {
  text: string;
  delete: () => void;
}

export const Prompt = (props: IPrompt) => {
  return (
    <WrapperPromptStyled>
      <WrapperTextStyled>
        {props.text}
      </WrapperTextStyled>
      <ExitButtonStyled onClick={() => props.delete()}/>
    </WrapperPromptStyled>
  );
}
