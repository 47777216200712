import { RuleObject } from 'antd/lib/form';

export const validatePasswordFormatted = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';

  if (value?.length < 7) {
    error = true;
    text +=
      localStorage.getItem('i18nextLng') === 'en'
        ? 'At least 8 characters are required. '
        : 'Требуется не менее 8 символов. ';
  }

  if (value?.search(/\d/) === -1 || value?.toLocaleLowerCase()?.search(/[a-z]/) === -1) {
    error = true;
    text +=
      localStorage.getItem('i18nextLng') === 'en'
        ? 'Password must have at least one number, one letter. '
        : 'Пароль должен содержать как минимум одну цифру, одну букву. ';
  }

  if (!rule.required && !value) {
    error = false;
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};

export const validatePasswordRequired = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';
  if (rule.required && !value) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Required field' : 'Обязательное поле';
  }

  if (!rule.required && !value) {
    error = false;
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};