import {Form} from 'antd';
import {FormItemProps} from "antd/lib/form/FormItem";
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomImputStyled} from './styles';
import {getAddress} from './yandexApi';

interface IYandexAutoCompileInput extends FormItemProps {
  onSelect: (address: string, ru: any, en: any) => void;
}

function getIsValid(item: any) {
  return item.properties.get('metaDataProperty.GeocoderMetaData.precision') === 'exact'
}

export const YandexAutoCompileInput = ({onSelect, ...rest}: IYandexAutoCompileInput) => {
  const [t] = useTranslation()
  const value = Form.useWatch(rest.name!)
  const [isValid, setIsValid] = useState(false)
  useEffect(() => {
    if (window.ymaps) {
      window.ymaps.ready(init);
    }

    function init() {
      const suggestView = new window.ymaps.SuggestView('suggest2');
      suggestView.events.add("select", async (e: any) => {
        const value = e.get('item').value.trim()
        const geocodes = await window.ymaps.geocode(value)
        let address;
        if (geocodes.geoObjects.getLength() > 1) {
          const addresses = geocodes.geoObjects.toArray()
          address = addresses.find((a: any) => a.getAddressLine() === value)
        }
        if (!address) {
          address = geocodes.geoObjects.get(0);
        }
        const multiLanguageData = await getAddress(value);
        setIsValid(getIsValid(address))
        if (address) {
          onSelect(address, multiLanguageData.ru, multiLanguageData.en);
        }
      })
    }
  }, [window.ymaps]);

  useEffect(() => {
    const check = async () => {
      try {
        const geocodes = await window.ymaps.geocode(value)
        let address: any;
        if (geocodes.geoObjects.getLength() > 1) {
          const addresses = geocodes.geoObjects.toArray()
          address = addresses.find((a: any) => a.getAddressLine() === value)
        }
        if (!address) {
          address = geocodes.geoObjects.get(0);
        }

        const multiLanguageData: any = await getAddress(value);
        setIsValid(getIsValid(address))
        if (address) {
          onSelect(address, multiLanguageData.ru, multiLanguageData.en);
        }
      } catch {

      }
    }

    if (value) {
      check()
    }
  }, [value])

  return (
    <div style={{width: '100%'}}>
      <Form.Item
        rules={[
          {
            validator: () => {
              return new Promise<void>((resolve, reject) => {
                if (!isValid) {
                  reject()
                }
                resolve()
              })
            },
            message: t("specify_the_address"),
          }
        ]}
        noStyle
        {...rest}
      />
      <CustomImputStyled id="suggest2" type="text" defaultValue={value}/>
    </div>
  );
};
