import {validURL} from "components/validate/urlValidate";
import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {useTranslation} from "react-i18next";
import {Form, notification} from "antd";
import {I18NLangString, ICRM} from "../types";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {ResetButton} from "components/buttons/resetButton";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {TextInput} from "components/inputs/textInput";
import {AreatextInput} from "components/inputs/areatextInput";
import {SelectCRMInput} from "components/inputs/selectCRMInput";
import {useEffect, useMemo, useState} from "react";
import {getLocalOrEn, setLocalOrEverything} from "utils/lang";

interface IProps {
  title: I18NLangString | null;
  description: I18NLangString | null;
  crm: ICRM[] | null;
  crmList: ICRM[];
  website: string | null;
  isActivated: boolean;
  onChange: (title: I18NLangString, description: I18NLangString, crm: string[], website: string) => Promise<void>,
  onNextPageRequest: () => void,
  isLastStep: boolean,
}

interface IForm {
  title: string,
  description: string,
  crm: string[],
  website: string,
}

export function General(props: IProps) {
  const {t} = useTranslation();
  const [form] = Form.useForm<IForm>();

  const onFinish = (values: IForm): Promise<void> => {
    setShouldPrompt(false)
    setIsLoading(true)
    return new Promise<void>((resolve, reject) => {
      props.onChange(
        setLocalOrEverything(props.title, values.title),
        setLocalOrEverything(props.description, values.description),
        values.crm,
        values.website,
      ).then(() => {
        notification.success({
          message: t('pages.general.save_notification'),
        });
      }).then(() => {
        if (!props.isActivated) {
          props.onNextPageRequest()
        }
        setShouldPrompt(true)
        resolve()
      })
    }).finally(() => {
      setIsLoading(false)
    })
  };

  const userCRM = useMemo(() => {
    if (!props.crm) return []
    return props.crm.map(crm => getLocalOrEn(crm.name))
  }, [props.crm])

  const allActiveCRM = useMemo(() => {
    return props.crmList.filter(crm => crm.is_active).map(crm => ({
      title: getLocalOrEn(crm.name),
      value: crm.id,
    }))
  }, [props.crmList])

  const initialValue = useMemo(() => ({
    title: getLocalOrEn(props.title),
    description: getLocalOrEn(props.description),
    crm: userCRM,
    website: props.website,
  }), [props.description, props.title, props.website, userCRM])
  useEffect(() => {
    form.resetFields()
  }, [form, initialValue])

  const [shouldPrompt, setShouldPrompt] = useState(!props.isLastStep)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <WrapperContentStyled className="content">
      <Form
        form={form}
        initialValues={initialValue}
        onFinish={onFinish}
      >
        <PromptOnUnsavedChanges
          shouldPrompt={shouldPrompt}
          onFinish={onFinish}
        />

        <MainCenterTitleStyle style={{marginTop: 0}}>{t('general_information')}</MainCenterTitleStyle>
        <ResetButton onClick={() => form.resetFields()}>{t('reset')}</ResetButton>

        <div style={{clear: 'both', height: 25}}/>

        <InputTitleStyled>{t('coworking_name')}*</InputTitleStyled>
        <TextInput name={'title'} required={true}/>

        <InputTitleStyled>{t('description_coworking')}*</InputTitleStyled>
        <AreatextInput name={'description'} required={true}/>

        <InputTitleStyled>{t('your_CRM')}</InputTitleStyled>
        <SelectCRMInput
          userCRM={userCRM}
          form={form}
          name={'crm'}
          data={allActiveCRM}
          required={false}
          multiple={true}
        />

        <InputTitleStyled>{t('coworking_site')}</InputTitleStyled>
        <TextInput name="website" required={true} validate={validURL}/>

        <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
      </Form>
    </WrapperContentStyled>
  );
}
