import styled from 'styled-components';

export const WrapperAppStyled = styled.div<{isPrompt: boolean}>`
  ${props => !props.isPrompt ? `
    .content{
      width: 100%;
    }
  `:``}
  height: calc(100% - 80px);
  overflow-y: visible;
  overflow-x: visible;
  position: relative;
  left: 0;
  width: 100%;
  @media (min-width: 318px) and (max-width: 576px) {
    height: auto;
    min-height: calc(100vh - 200px);
  }
  @media (min-width: 318px) and (max-width: 1000px) {
    overflow: visible;
    height: auto;
    min-height: calc(100vh - 400px);
    &::after{
      display: none;
    }
  }
  &::after{
    display: block;
    content: '';
    width: 100%;
    height: 80px;
  }
`;
