import {Form, Row} from 'antd';
import {RuleObject} from 'antd/lib/form';
import {useRef} from 'react';
import {IMaskInput} from "react-imask";
import {TitleTimeStyled} from './styles';

interface ITime {
  name: string;
  title: string;
  required: boolean;
  value?: string;
  startRef?: any;
  nextRef?: any;
}

export function timeIsFilled(value: string): boolean {
  return value.length === 5 && value.indexOf(' ') === -1
}

function timeIsEmpty(value?: string): boolean {
  return !value || value === ''
}

const timeValidateAnt = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!rule.required && timeIsEmpty(value)) {
      resolve()
    }
    if (!timeIsFilled(value)) {
      reject()
    }
    const splits = (value.split(':') as [string, string]).map(x => Number(x))
    if (isNaN(splits[0]) || isNaN(splits[1]) || splits[0] > 23 || splits[1] > 59 || splits[0] < 0 || splits[1] < 0) {
      reject()
    }
    resolve()
  })
};

export function TimeInput(props: ITime) {
  const form = Form.useFormInstance()
  const refInputEnd = useRef<HTMLInputElement>(null);

  const checkCorrectInputTime = async (rule: RuleObject) => {
    return new Promise<void>((resolve, reject) => {
      const start = form.getFieldValue([props.name, 'start']);
      const end = form.getFieldValue([props.name, 'end']);
      if (!rule.required && timeIsEmpty(start) && timeIsEmpty(end)) {
        resolve()
      }
      if (!timeIsFilled(start) || !timeIsFilled(end)) {
        reject()
      }

      if (start > end) {
        reject()
      }
      resolve()
    })
  };
  return (
    <Form.Item
      noStyle>
      <Row style={{position: 'relative', marginBottom: 5}}>
        <div>
          <TitleTimeStyled>{props.title}</TitleTimeStyled>
        </div>
        <div style={{position: 'absolute', right: '0', display: 'flex'}}>
          <Form.Item
            name={[props.name, 'start']}
            trigger="onAccept"
            rules={[
              {
                required: props.required,
                message: 'Required field!',
                validator: timeValidateAnt,
              },
              {
                required: props.required,
                validator: checkCorrectInputTime,
              },
            ]}
            dependencies={[
              [props.name, "end"]
            ]}
          >
            <IMaskInput
              mask="00:00"
              placeholderChar=" "
              inputRef={props.startRef}
              // @ts-ignore
              className="ant-input"
              // @ts-ignore
              placeholder="HH:MM"
              onAccept={(value) => {
                if (timeIsFilled(value as string) && refInputEnd && refInputEnd.current) {
                  refInputEnd.current.focus();
                }
              }}
            />
          </Form.Item>

          <div style={{fontSize: 20, paddingTop: 3, marginLeft: 10, marginRight: 10}}> -</div>

          <Form.Item
            name={[props.name, 'end']}
            trigger="onAccept"
            rules={[
              {
                required: props.required,
                message: 'Required field!',
                validator: timeValidateAnt,
              },
              {
                required: props.required,
                validator: checkCorrectInputTime,
              },
            ]}
            dependencies={[
              [props.name, "start"]
            ]}
          >
            <IMaskInput
              mask="00:00"
              placeholderChar=" "
              // @ts-ignore
              className="ant-input"
              // @ts-ignore
              placeholder="HH:MM"
              onKeyDown={(e: any) => {
                if (e.key === "Tab" && props.nextRef && props.nextRef.current) {
                  e.preventDefault();
                  props.nextRef.current.focus();
                  props.nextRef.current.select();
                }
              }}
              onAccept={(value) => {
                if (timeIsFilled(value as string) && refInputEnd && refInputEnd.current) {
                  refInputEnd.current.focus();
                }
              }}
              inputRef={refInputEnd}
            />
          </Form.Item>
        </div>
      </Row>
    </Form.Item>
  );
}

