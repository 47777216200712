import { Button } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR, MAIN_RED_COLOR } from '../../style/globalStyle/Color';

export const WrapperCoworkingStyled = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
`;

export const WrapperContactsStyled = styled.div`

`;

export const WrapperDaysStyled = styled.div`
  width: 40%;
  height: calc(100% - 40px);
  float: left;
`;

export const WrapperGalleryStyled = styled.div`
  width: 100%;
  height: calc(100% - 40px);
  float: left;
`;

export const WrapperTariffStyled = styled.div`
  width: 40%;
  height: calc(100% - 40px);
  float: left;
`;

export const ProgressTitle = styled.div`
  text-align: center;
  font-weight: 900;
  color: white;
`;

export const AvatarStyled = styled.div`
  width: 90%;
  height: 100px;
  background-image: url("https://icowork.co:8443/media/storage/3-min.jpg");
  background-size: cover;
  background-position: center;
  margin-left: 5%;
`;

export const TitleStyled = styled.div``;

export const NameStyled = styled.div`
  margin-top: 10px;
  font-size: 16px;
  width: 30%;
  text-align: center;
  float: left;
`;

export const WrapperProgressBar = styled.div`
  margin-top: 10px;
  width: 70%;
  height: 25px;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
`;

export const ProgressStyled = styled.div`
  transition-duration: 2s;
  height: 100%;
  /* background: ${MAIN_BLUE_COLOR}; */
  overflow: hidden;
`;

export const WrapperAnimation = styled.div`
  width: 100%;
  height: 100%;
  box-shadow: 4px 4px 8px rgba(88, 100, 159, 0.06);
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;
  .not-active .progress {
    background-color: ${MAIN_BLUE_COLOR} !important;
    width: 0 !important;
  }
  position: relative;
`;

export const AvatarCoworkingStyled = styled.div`
  width: 100%;
  height: 172px;
  background: grey;
  background-size: cover;
  background-position: center;
`

export const TitleCoworkingStyled = styled.div`
  margin-top: 16px;
  margin-left: 16px;
  font-style: normal;
font-weight: bold;
font-size: 18px;
line-height: 22px;
`

export const StatusCoworkingStyled = styled.div<any>`
  margin-left: 16px;
  font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 24px;
`

export const AddressCoworkingStyled = styled.div`
margin-top: 16px;
  margin-left: 16px;
  font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
`

export const EmailCoworkingStyled = styled.div`
margin-top: 16px;
  margin-left: 16px;
  font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
`

export const PhoneCoworkingStyled = styled.div`
  margin-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
`

export const DisabledButtonStyled = styled(Button)<{$isActive: boolean}>`
  width: calc(50% - 20px);
  right: 8px;
  height: 40px;
  position: absolute;
  bottom: 16px;
  left: 16px;
  ${props => !props.$isActive ? `
    color: #9DADD0 !important;
    svg , path{
      fill: #9DADD0 !important;
    }
  `:`
    border-color: #9DADD0 !important;
    color: black !important;
    svg, path{
      fill: ${MAIN_RED_COLOR} !important;
    }
  `}
`

export const UpdateButtonStyled = styled(Button)`
  width: calc(50% - 20px);
  height: 40px;
  position: absolute;
  bottom: 0;
  right: 16px;
  bottom: 16px;

  background-color: ${MAIN_BLUE_COLOR} !important;
  color: white !important;
`

// export const DisabledIconStyled = styled.div`
//   position: absolute;
//   left: 8px;
//   top: 13px;
//   background-image: url("${cloud}");
//   background-size: cover;
//   background-position: center;
//   height: 15px;
//   width: 20px;

// `

// export const UpdateIconStyled = styled.div`
//   position: absolute;
//   left: 8px;
//   top: 13px;
//   background-image: url("${update}");
//   background-size: cover;
//   background-position: center;
//   height: 15px;
//   width: 15px;
// `

