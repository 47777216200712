import styled from "styled-components";
import { MAIN_BORDER_COLOR } from "../../style/globalStyle/Color";
import exit from './icons/exit.svg';

export const WrapperPromptsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
`

export const WrapperProptsStyled = styled.div`
  width: calc(35% - 100px);
  max-height: 100%;
  margin-bottom: 1000px;
`


export const WrapperPromptStyled = styled.div`
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%;
  border-radius: 8px;
  margin-top: 16px;
  background: #FFFFFF;

  border: 1px solid ${MAIN_BORDER_COLOR};
  box-sizing: border-box;

  box-shadow: 4px 4px 8px rgba(88, 100, 159, 0.06);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media (min-width: 318px) and (max-width: 900px) {
    display: none;
  }
`

export const EmptyBlock = styled.div`
position: relative;
height: 100px
`

export const WrapperTextStyled = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  padding-left: 30px;
  padding-right: 65px;
`

export const ExitButtonStyled = styled.div`
  position: absolute;
  width: 9px;
  height: 9px;
  top: 27px;
  right: 29px;
  background-image: url(${exit});
  background-size: cover;
  background-position: center;
  cursor: pointer;
`