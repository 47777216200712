import { FC, memo, useEffect } from "react"
import { BurgerMenuIsconStyled, WrapperBurgerMenuStyled, WrapperMobileMenuStyled } from "./style"

interface IBurgerMenu {
  menu: any;
  isView: boolean;
  setIsView: React.Dispatch<React.SetStateAction<boolean>>;
}

export const BurgerMenu: FC<IBurgerMenu> = memo((props) => {

  useEffect(()=>{
    if(window.innerWidth <= 991){
      const body = document.getElementsByTagName('body')[0];
      if(props.isView){
        const scrollY = window.scrollY;
        body.style.position = 'fixed';
        body.style.top = `-${scrollY}px`;
      }else{
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  },[props.isView])

  return (
    <>
      <WrapperBurgerMenuStyled onClick={()=>props.setIsView(!props.isView)}>
        <BurgerMenuIsconStyled isActive={props.isView}/>
      </WrapperBurgerMenuStyled>

      <WrapperMobileMenuStyled isActive={props.isView}>
        {props.menu}
      </WrapperMobileMenuStyled>
    </>
  )
})