import {Form} from 'antd';
import {FC, memo, useEffect, useState} from 'react';
import {ICountry} from "store/slices/profile";
import {formatSize} from '../../validate/formatSize';
import {onlyNumber} from '../../validate/onlyNumber';
import {CustomInputStyled, WrapperPlaceholderStyled, WrapperSizeInput} from './styles';
import {useTranslation} from 'react-i18next';
import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from 'store/store';

interface ISize {
  name: string | Array<any>;
  required: boolean;
  forceUpdate?: any;
  fieldKey?: any;
  validate?: any;
  initialValue?: any;
  readName?: any[];
  disabled?: boolean;
}

export const SizeInput: FC<ISize> = memo((props) => {
  const [value, setValue] = useState();
  const [t] = useTranslation();

  const ref: any = React.createRef();
  const country = useSelector((state: RootState) => state.profile.country)
  const areaMeasurements = (
    <div style={{display: 'inline', position: 'relative', paddingLeft: '4px'}}>
      {country !== ICountry.Russia ? 'ft' : 'м'}
      <sup>
        <small>2</small>
      </sup>
    </div>
  )

  const form = Form.useFormInstance()
  const readName = props.readName ? props.readName : props.name
  const readValue = Form.useWatch(readName, form)
  useEffect(() => {
    setValue(readValue)
  }, [readValue])

  return (
    <WrapperSizeInput>
      <Form.Item
        name={props.name}
        rules={[{
          required: props.required,
          message: t('required_field'),
          validator: props.validate
        }]}
        normalize={(value) => formatSize(onlyNumber(value))}
        style={{position: 'relative'}}
        label={
          <WrapperPlaceholderStyled>
            {value}

            {areaMeasurements}
          </WrapperPlaceholderStyled>
        }
      >
        <CustomInputStyled
          disabled={props.disabled}
          onChange={(e: any) => {
            setValue(formatSize(onlyNumber(e.target.value)));
            if (props.forceUpdate) {
              props.forceUpdate({});
            }
          }}
          ref={ref}
        />
      </Form.Item>
    </WrapperSizeInput>
  );
});
