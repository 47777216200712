import { Button } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../../style/globalStyle/Color';

export const ResetButtonStyled = styled(Button)<{$isDisabled?:boolean}>`
  line-height: 30px;
  border: none;
  border-bottom: 2px solid ${MAIN_BLUE_COLOR};
  color: ${MAIN_BLUE_COLOR};
  float: right;
  border-radius: 0 !important;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  margin-top: 2px;
  padding: 0;
  margin-right: 6px;
  cursor: pointer;
  ${props => (props.$isDisabled) ? `
      color: grey !important;
      border-bottom: 2px solid grey !important;
    `:``}
  @media (min-width: 318px) and (max-width: 900px) {
    position: absolute;
    left: 20px;
    bottom: 35px;
    max-width: calc(100% - 210px);
    z-index: 2;
    span{
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;
