import { Checkbox } from 'antd';
import styled from 'styled-components';

export const CheckboxListStyled = styled(Checkbox.Group)`
  width: 100%;
  & > label {
    width: calc(50% - 10px);
    position: relative;
    margin-top: 7px;
  }

  input {
    width: 20px !important;
    height: 20px !important;
    border-radius: 2px;
  }

  span {
    width: 20px !important;
    height: 20px !important;
    border-radius: 2px;
  }

  .ant-checkbox-inner{
    border: 3px solid #2E60CA !important;
    border-radius: 2px;
  }

  .ant-checkbox-inner::after {
    left: calc(50% - 4px);
  }

  .ant-checkbox-checked {
    background-color: rgba(0, 0, 0, 0) !important;
    border-color: black !important;
  }
  .ant-checkbox-wrapper > span:last-child {
    position: absolute;
    left: 25px;
    width: calc(100% - 5px) !important;
    height: auto !important;

    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    line-height: 14px;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    & > label {
      width: calc(100% - 10px);
      position: relative;
      margin-top: 7px;
    }
  }
  @media (min-width: 576px) and (max-width: 1200px) {
    & > label {
      width: calc(50% - 10px);
      position: relative;
      margin-top: 7px;
    }
  }
`;
