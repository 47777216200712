import {createSlice, current} from '@reduxjs/toolkit';
import {getRequest, putRequest} from 'core/fetchWrapper';
import {AppThunk, RootState} from '../store';
import {concat} from 'lodash';

export const initialState: any = {
  dragUploadFileId: '',
  crm: null,
  coworkingData: null,
  coworkingSceduler: null,
  unparsedAddress: '',
  positionMap: null,
  services: null,
  coworkingServices: null,
  coworkingTariffs: null,
  tariffServices: null,
  reservationTypes: null,
  seatingConfigData: null,
  coworkingPhotos: null,
  coworkingStep: null,
  userCoworkings: null,
  description: [],
  coworkingAddress: null,
};

export const CoworkingSlice = createSlice({
  name: 'coworking',
  initialState,
  reducers: {
    changeCoworkingDataInCoworkings: (state, action) => {
      state.userCoworkings[action.payload.status] = current(state).userCoworkings[action.payload.status].map((item: any) => {
        if (item.id === action.payload.id) {
          return action.payload;
        }
        return item
      });
    },
    addCoworkingToCoworkings: (state, action) => {
      state.userCoworkings[action.payload.status] = [...[action.payload], ...current(state).userCoworkings[action.payload.status]]
    },
    changeStatusCoworking: (state, action) => {
      if (action.payload.oldStatus === action.payload.newStatus) {
        return
      }
      const coworking = [...current(state).userCoworkings[action.payload.oldStatus].filter((item: any) => item.id == action.payload.id)]
      if (coworking[0]) {
        coworking[0] = {...coworking[0]}
        coworking[0].status = action.payload.newStatus
      }
      state.userCoworkings[action.payload.newStatus] = [...coworking, ...current(state).userCoworkings[action.payload.newStatus]]
      state.userCoworkings[action.payload.oldStatus] = current(state).userCoworkings[action.payload.oldStatus].filter((item: any) => item.id != action.payload.id)
    },
    resetCoworking: () => {
      return initialState
    },
    setUserCoworkings: (state, action) => {
      state.userCoworkings = action.payload;
    },
    setTariffDescriptions: (state, action) => {
      // const allDescriptions = concat(state.description, action.payload)
      // const uniqueDescription = uniqBy(allDescriptions, 'title')
      // state.description = concat(state.description, uniqueDescription)
      state.description = concat(state.description, action.payload)
    },
    setCoworkingStep: (state, action) => {
      state.coworkingStep = action.payload;
    },
    incrementCoworkingStep: (state, action) => {
      if (action.payload === current(state).coworkingStep) {
        state.coworkingStep = current(state).coworkingStep + 1;
      }
    },
    setDragUploadFileId: (state, action) => {
      state.dragUploadFileId = action.payload;
    },
    setPositionMap: (state, action) => {
      state.positionMap = action.payload;
    },
  },
});

export const {
  changeCoworkingDataInCoworkings,
  addCoworkingToCoworkings,
  changeStatusCoworking,
  resetCoworking,
  setUserCoworkings,
  setDragUploadFileId,
  setPositionMap,
  setCoworkingStep,
  incrementCoworkingStep,
  setTariffDescriptions,
} = CoworkingSlice.actions;

export const userCoworkingsSelector = (state: RootState) => state.coworking.userCoworkings;

export const dragUploadFileId = (state: RootState) => state.coworking.dragUploadFileId;

export const servicesSelector = (state: RootState) => state.coworking.services;

export const CRMList = (state: RootState) => state.coworking.crm;

export const unparsedAddressSelector = (state: RootState) => state.coworking.unparsedAddress;

export const positionMapSelector = (state: RootState) => state.coworking.positionMap;

export const coworkingData = (state: RootState) => state.coworking.coworkingData;

export const coworkingAddress = (state: RootState) => state.coworking.coworkingAddress;

export const coworkingSceduler = (state: RootState) => state.coworking.coworkingSceduler;

export const coworkingServicesSelector = (state: RootState) => state.coworking.coworkingServices;

export const coworkingTariffsSelector = (state: RootState) => state.coworking.coworkingTariffs;

export const tariffServicesSelector = (state: RootState) => state.coworking.tariffServices;

export const reservationTypesSelector = (state: RootState) => state.coworking.reservationTypes;

export const seatingConfigDataSelector = (state: RootState) => state.coworking.seatingConfigData;

export const coworkingPhotosSelector = (state: RootState) => state.coworking.coworkingPhotos;

export const coworkingStepSelector = (state: RootState) => state.coworking.coworkingStep;


export const disableCoworking =
  (id: any, oldStatus: string, newStatus: string, title: any, description: any): AppThunk =>
    async (dispatch: any) => {
      try {
        await putRequest(`/smp_api/coworking/${id}/`, {title, description, status: newStatus})
        dispatch(changeStatusCoworking({id, oldStatus, newStatus}))
      } catch (error) {
        console.warn(error, "disableCoworking")
      }
    }

export const getUserCoworkings =
  (): AppThunk =>
    async (dispatch: any) => {
      try {
        const responce = await getRequest(`/smp_api/coworking/`);
        const coworkingsData = {
          draft: responce.filter((item: any) => item.status === 'draft'),
          approve: responce.filter((item: any) => item.status === 'approve'),
          active: responce.filter((item: any) => item.status === 'active'),
          disabled: responce.filter((item: any) => item.status === 'disabled')
        }
        dispatch(setUserCoworkings(coworkingsData))
      } catch (error) {
        console.warn(error, 'getUserCoworkings')
      }
    };

export default CoworkingSlice.reducer;

