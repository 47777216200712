import { Row } from 'antd';
import {FC, memo, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { CheckboxInput } from '../../../components/inputs/checkboxInput';
import { LeftColumn } from '../../../style/colomn/leftColumn';
import { RightColumn } from '../../../style/colomn/rightColumn';
import { CenterColumn } from "../../../style/colomn/centerColumn";

interface IWorkSpace {
  field: any;
  form: any;
  nameParent: any;
  forceUpdate?: any;
  disabled?: boolean;
}

export const WorkSpaceForm: FC<IWorkSpace> = memo((props) => {
  const [t] = useTranslation()
  const [, forceUpdate] = useState({})
  const isTrial = props.form.getFieldValue([props.nameParent, props.field.name, 'available_space', '0', 'is_trial'])

  return (
    <Row gutter={[16, 16]} wrap={true}>
      <LeftColumn xl={8} xs={24}>
        <CheckboxInput onChange={() => {
          forceUpdate({})
        }} name={[props.field.name, 'available_space', '0', 'can_be_sold']} required={false} title={t("can_be_sold")} initialValue={true}/>
      </LeftColumn>
      <CenterColumn xl={8} xs={24}>
        <CheckboxInput disabled={props.disabled} name={[props.field.name, 'available_space', '0', 'is_trial']} required={false}
                       title={t("trial_day")} onChange={props.forceUpdate}/>
      </CenterColumn>
      <RightColumn xl={8} xs={24}>
        <CheckboxInput disabled={isTrial || props.disabled} onChange={() => {
          forceUpdate({})
        }} name={[props.field.name, 'is_fixed']} required={false} title={t("permanent_place")}/>
      </RightColumn>
    </Row>
  );
});
