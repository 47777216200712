import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import {antRuleMaxLengthValidator, antRuleRequiredValidator} from "utils/antdValidators";
import {CustomInputStyled} from './styles';

interface IAreatext {
  name: string | Array<any>;
  required: boolean;
  onChange?: any;
  normalize?: any;
  value?: string;
  initialValue?: any;
}

export function AreatextInput(props: IAreatext) {
  const [t] = useTranslation();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: props.required,
          message: t('rules.required'),
          validator: antRuleRequiredValidator,
        },
        {
          required: props.required,
          message: t('rules.max_length_reached'),
          validator: antRuleMaxLengthValidator,
        },
      ]}
      normalize={props.normalize}
      initialValue={props.initialValue}
    >
      <CustomInputStyled onChange={props.onChange}/>
    </Form.Item>
  );
}
