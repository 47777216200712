import { Button, Upload } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR, MAIN_RED_COLOR } from '../../../style/globalStyle/Color';
import basket from './icons/basket.svg';

export const WrapperImageItem = styled.div<{isError: boolean,isAnimation: boolean}>`
  width: 100%;
  /* background: rgba(0,0,0,0.1); */
  margin: 0;
  margin-bottom: 0;
  position: relative;
  border-top: 1px solid ${MAIN_BORDER_COLOR};
  position: relative;
  z-index: 1;
  height: 1px;
  margin-top: -2px;
  transition-duration: 0.0s;
  transition-timing-function: ease-in-out;
  overflow: hidden;
  ${props=> props.isError?`
    border: 1px solid ${MAIN_RED_COLOR};
    box-shadow: 0px 0px 4px 2px rgba(202, 72, 72, 0.2);
    z-index: 2;
  `:``}
  ${props=> props.isAnimation?`
    height: 200px !important;
    @media (min-width: 318px) and (max-width: 576px) {
      height: 318px !important;
    }
  `:``}
`;

export const ImageStyled = styled.div`
  background-size: cover;
  background-position: center;
  margin: 10px;
  height: 180px;
  width: 280px;
  float: left;
  background-repeat: no-repeat;
  border-radius: 8px;
  @media (min-width: 318px) and (max-width: 576px) {
    height: 160px;
    width: 279px;
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const WrapperAreaTextStyled = styled.div`
  width: calc(100% - 320px);
  height: calc(100% - 20px);
  float: left;
  margin: 10px;
  margin-left: 0;
  textarea,
  .ant-row,
  .ant-col,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content {
    height: 100% !important;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    width: 279px;
    float: none;
    margin-left: auto;
    margin-right: auto;
    height: 70px;
  }
`;

export const WrapperRightMenuStyled = styled.div`
  width: 50px;
  height: 100%;
  float: left;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
`;

export const ItemMenuStyled = styled.div`
  width: 100%;
  height: 33.333%;
  cursor: pointer;
  &:hover {
    svg {
      fill: ${MAIN_BLUE_COLOR};
    }
  }
  .anticon {
    width: 100%;
    height: 100%;
    position: relative;
    svg {
      width: 50%;
      height: 50%;
      overflow: auto;
      margin: auto;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
`;

export const MoreInformationWrapper = styled.div<{isError: boolean}>`
  margin: 0 !important;
  padding: 0 !important;
  width: 100%;
  /* height: 115px; */
  /* border: 1px solid #E6EEF5;
  border-radius: 8px; */
  margin-bottom: 1px !important;
  position: relative;
  z-index: 2;
  transition-duration: 0.0s;
  ${props => props.isError ? `
    border: 1px solid ${MAIN_RED_COLOR};
    box-shadow: 0px 0px 4px 2px rgba(202, 72, 72, 0.2);
  `:``}
  @media (min-width: 318px) and (max-width: 992px) {
    height: auto;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    padding-bottom: 0;
  }
`;

export const UploadAreaWrapper = styled.div`
  border-radius: 8px;
  border: 1px dotted ${MAIN_BORDER_COLOR};
  background-color: ${MAIN_BORDER_COLOR};
  width: calc(100% - 40px);
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    background: ${MAIN_BLUE_COLOR} !important;
    border-color: ${MAIN_BLUE_COLOR} !important;
    color: white !important;
    border-radius: 8px;
  }
  @media (min-width: 318px) and (max-width: 992px) {
    height: auto;
    display: grid;
  }
`;

export const CustomUpload = styled(Upload)`
  .ant-upload {
    width: 100% !important;
  }
`;

export const TextUploadStyled = styled.div`
  padding: 15px 20px 15px 20px;
  min-height: 70px;
  color: #000000;
  font-size: 16px !important;
  font-weight: 600;
  width: calc(100% - 200px);
  line-height: 1.2em;
  letter-spacing: 0.5px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 318px) and (max-width: 992px) {
    width: 100%;
    text-align: center;
  }
`;

export const UploadButtonStyled = styled(Button)`
  float: right;
  display: block;
  width: 150px;
  height: calc(100% - 26px);
  margin: 13px;
  background: transparent;
  border: 2px solid black !important;
  border-radius: 4px;
  font-weight: 600 !important;
  font-size: 16px;
  
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 318px) and (max-width: 992px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const BottomMenuStyled = styled.div`
  width: 460px;
  height: 35px;
  float: right;
  & > div {
    height: 100% !important;
    width: 16.5% !important;
    float: left;
    line-height: 40px;
    font-size: 15px;
  }
  svg {
    fill: black !important;
  }
  div {
    cursor: auto !important;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    display: none;
  }
`;

export const WrapperUploadForm = styled.div`
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  margin-top: 30px;
  position: relative;
  *{
    animation-duration: 0s !important;
  }
  div {
    animation-duration: 0 !important;
    transition-duration: 0 !important;
  }
  .ant-upload-picture-card-wrapper{
    display: block;
  }
  .ant-upload-animate-leave {
    display: none !important;
  }
  .ant-upload-list-picture-card-container {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
    transition-timing-function: ease-in-out;
    transition-duration: 0.0s !important;
    display: block;
  }
  .ant-upload-animate-inline-leave{
    height: 1px !important;
    animation-duration: 2s !important;
    animation-delay: 2s !important;
  }
  .ant-form-item-explain-error{
    display: none;
    margin: 0 !important;
    padding: 0 !important;
    animation-duration: 0s !important;
    *{
      animation-duration: 0s !important
    }
  }
  .ant-upload-animate-inline-leave{
    display: none;
  }
`;

export const WrapperErrorMessage = styled.div`
  position: absolute;
  bottom: -10px;
  right: 0;
  width: 100%;
  text-align: center;
  color: ${MAIN_RED_COLOR};
`

export const WrapperDeleteStyled = styled.div`
  position: absolute;
  right: 32px;
  bottom: 24px;
  font-size: 16px;
  cursor: pointer;
  @media (min-width: 318px) and (max-width: 576px) {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    right: 0;
    bottom: 0;
    display: block;
    width: 90px;
  }
`

export const DeleteIconStyled = styled.div`
  width: 12px;
  height: 15px;
  display: block;
  float: left;
  margin-top: 4px;
  margin-right: 10px;
  background-image: url(${basket});
  background-size: cover;
  background-position: center;
`