import styled from 'styled-components';
import {MAIN_BLUE_COLOR} from '../../../style/globalStyle/Color';

export const WrapperSignUpStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-bottom: 30px;
`;

export const WrapperTextStyled = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  a {
    border-bottom: 1px solid ${MAIN_BLUE_COLOR} !important;
    font-weight: bold !important;
  }
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    border-bottom: 1px solid ${MAIN_BLUE_COLOR} !important;
    font-weight: bold !important;
    line-height: 1.2rem;
    background-color: transparent;
    color: ${MAIN_BLUE_COLOR};
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
  }
`
