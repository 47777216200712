import {RuleObject} from 'antd/lib/form';

export const areaCabinetRule = {

}

export const AreaCabinetValidateAnt = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (value === '' || value === undefined) {
      resolve()
    }

    if (Math.floor(Number(value.trim().replaceAll(' ', ''))).toString().length > 3) {
      reject(localStorage.getItem('i18nextLng') === 'en' ? 'Too much value' : 'Слишком большое значение')
    }

    resolve()
  })
};
