import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {Form, message, notification} from "antd";
import {useTranslation} from "react-i18next";
import {
  ChoiceStyled, MoreInformationButton,
  WrapperChangeStyled, WrapperWeekInputStyled
} from "../../updateCoworking/sceduler/styles";
import {ICreateTimetable, ITimetable} from "../types";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {DownOutlined} from "@ant-design/icons";
import {TimeInput, timeIsFilled} from "components/inputs/timeInput";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ResetButton} from "components/buttons/resetButton";

interface IProps {
  timetable: ITimetable | null,
  receptionTimetable: ITimetable | null,
  firstDayOfWeek: number,
  isActivated: boolean,
  onChange: (timetable: ICreateTimetable) => Promise<void>,
  isLastStep: boolean,
}

interface IDay {
  start: string,
  end: string,
}

interface IForm {
  monday: IDay,
  tuesday: IDay,
  wednesday: IDay,
  thursday: IDay,
  friday: IDay,
  saturday: IDay,
  sunday: IDay,
  'Mon-Fri': IDay,
}

export function Scheduler(props: IProps) {
  const [form] = Form.useForm<IForm>();
  const {t} = useTranslation();
  const [weekdaysIsOpen, setWeekdaysIsOpen] = useState(false)
  const [is24x7, setIs24x7] = useState<boolean | null>(null)
  const onFinish = (values: IForm) => {
    if (
      !timeIsFilled(values?.monday?.start)
      && !timeIsFilled(values?.tuesday?.start)
      && !timeIsFilled(values?.wednesday?.start)
      && !timeIsFilled(values?.thursday?.start)
      && !timeIsFilled(values?.friday?.start)
      && !timeIsFilled(values?.saturday?.start)
      && !timeIsFilled(values?.sunday?.start)
      && !timeIsFilled(values?.["Mon-Fri"]?.start)) {
      message.error(t("write_down_at_least_one_day"));
      return
    }
    let payload: any = {
      ...values,
      is_24x7: is24x7!
    };
    if (!weekdaysIsOpen) {
      payload.monday.start = values["Mon-Fri"].start
      payload.tuesday.start = values["Mon-Fri"].start
      payload.wednesday.start = values["Mon-Fri"].start
      payload.thursday.start = values["Mon-Fri"].start
      payload.monday.end = values["Mon-Fri"].end
      payload.tuesday.end = values["Mon-Fri"].end
      payload.wednesday.end = values["Mon-Fri"].end
      payload.thursday.end = values["Mon-Fri"].end
      if (props.firstDayOfWeek === 0) {
        payload.sunday.start = values["Mon-Fri"].start
        payload.sunday.end = values["Mon-Fri"].end
      } else {
        payload.friday.start = values["Mon-Fri"].start
        payload.friday.end = values["Mon-Fri"].end
      }
    }
    payload.monday.available = timeIsFilled(payload.monday.start)
    payload.tuesday.available = timeIsFilled(payload.tuesday.start)
    payload.wednesday.available = timeIsFilled(payload.wednesday.start)
    payload.thursday.available = timeIsFilled(payload.thursday.start)
    payload.friday.available = timeIsFilled(payload.friday.start)
    payload.saturday.available = timeIsFilled(payload.saturday.start)
    payload.sunday.available = timeIsFilled(payload.sunday.start)
    setIsLoading(true)
    props.onChange(payload).then(() => {
      notification.success({
        message: t('pages.schedule.save_notification')
      })
    }).finally(() => {
      setIsLoading(false)
    })
  };
  const refStartTuesday = useRef();
  const refStartWednesday = useRef();
  const refStartThursday = useRef();
  const refStartFriday = useRef();
  const refStartSaturday = useRef();
  const refStartSunday = useRef();

  const sunday = Form.useWatch<IDay>('sunday', form)
  const monday = Form.useWatch<IDay>('monday', form)
  const tuesday = Form.useWatch<IDay>('tuesday', form)
  const wednesday = Form.useWatch<IDay>('wednesday', form)
  const thursday = Form.useWatch<IDay>('thursday', form)
  const friday = Form.useWatch<IDay>('friday', form)

  const weekdaysButtonIsVisible = useMemo(() => {
    if (!weekdaysIsOpen) {
      return true
    }
    let weekdaysDays = [monday, tuesday, wednesday, thursday]
    if (props.firstDayOfWeek === 0) {
      weekdaysDays.push(sunday)
    } else {
      weekdaysDays.push(friday)
    }

    if (weekdaysDays.some(x => !Boolean(x))) {
      return true
    }

    return !(weekdaysDays.slice(1).some(x => weekdaysDays[0].start !== x.start || weekdaysDays[0].end !== x.end))
  }, [friday, monday, props.firstDayOfWeek, sunday, thursday, tuesday, wednesday, weekdaysIsOpen])

  const onChangeCheckBoxAccess = () => {
    setIs24x7(true)
  };
  const onChangeCheckBoxNoAccess = () => {
    setIs24x7(false)
  };

  const setMonFriToMonday = useCallback(() => {
    const start = form.getFieldValue(['tuesday', 'start']);
    const end = form.getFieldValue(['tuesday', 'end']);
    let values: Partial<IForm> = {
      monday: {
        start: '',
        end: '',
      },
      tuesday: {
        start: '',
        end: '',
      },
      wednesday: {
        start: '',
        end: '',
      },
      thursday: {
        start: '',
        end: '',
      },
      'Mon-Fri': {
        start,
        end
      }
    }
    if (props.firstDayOfWeek === 0) {
      values.sunday = {
        start: '',
        end: '',
      }
    } else {
      values.friday = {
        start: '',
        end: '',
      }
    }
    form.setFieldsValue(values)
  }, [form, props.firstDayOfWeek])

  useEffect(() => {
    if (!props.timetable) {
      return
    }
    let timetable;
    if (props.timetable.is_24x7) {
      if (!props.receptionTimetable) {
        return
      }
      timetable = props.receptionTimetable
    } else {
      timetable = props.timetable
    }

    setIs24x7(timetable.is_24x7)

    form.setFields([
      {name: ['monday', 'start'], value: timetable.monday.start, errors: undefined},
      {name: ['tuesday', 'start'], value: timetable.tuesday.start, errors: undefined},
      {name: ['wednesday', 'start'], value: timetable.wednesday.start, errors: undefined},
      {name: ['thursday', 'start'], value: timetable.thursday.start, errors: undefined},
      {name: ['friday', 'start'], value: timetable.friday.start, errors: undefined},
      {name: ['saturday', 'start'], value: timetable.saturday.start, errors: undefined},
      {name: ['sunday', 'start'], value: timetable.sunday.start, errors: undefined},
      {name: ['monday', 'end'], value: timetable.monday.end, errors: undefined},
      {name: ['tuesday', 'end'], value: timetable.tuesday.end, errors: undefined},
      {name: ['wednesday', 'end'], value: timetable.wednesday.end, errors: undefined},
      {name: ['thursday', 'end'], value: timetable.thursday.end, errors: undefined},
      {name: ['friday', 'end'], value: timetable.friday.end, errors: undefined},
      {name: ['saturday', 'end'], value: timetable.saturday.end, errors: undefined},
      {name: ['sunday', 'end'], value: timetable.sunday.end, errors: undefined},
    ]);
    if (
      timetable.friday.start === timetable.monday.start &&
      timetable.friday.start === timetable.thursday.start &&
      timetable.friday.start === timetable.tuesday.start &&
      timetable.friday.start === timetable.wednesday.start &&
      timetable.friday.end === timetable.monday.end &&
      timetable.friday.end === timetable.thursday.end &&
      timetable.friday.end === timetable.tuesday.end &&
      timetable.friday.end === timetable.wednesday.end
    ) {
      setMonFriToMonday()
      setWeekdaysIsOpen(false)
    } else {
      setWeekdaysIsOpen(true)
    }
  }, [form, props.receptionTimetable, props.timetable, setMonFriToMonday]);

  const setWeekdayFieldsToMonFri = () => {
    const start = form.getFieldValue(['Mon-Fri', 'start']);
    const end = form.getFieldValue(['Mon-Fri', 'end']);
    let values: Partial<IForm> = {
      monday: {
        start,
        end,
      },
      tuesday: {
        start,
        end,
      },
      wednesday: {
        start,
        end,
      },
      thursday: {
        start,
        end,
      },
      'Mon-Fri': {
        start: '',
        end: '',
      }
    }
    if (props.firstDayOfWeek === 0) {
      values.sunday = {
        start,
        end,
      }
    } else {
      values.friday = {
        start,
        end,
      }
    }
    form.setFieldsValue(values)
  };

  const toggleWeekdays = () => {
    if (!weekdaysIsOpen) {
      setWeekdayFieldsToMonFri()
    } else {
      setMonFriToMonday()
    }
    form.validateFields()
    setWeekdaysIsOpen(prev => !prev)
  }

  const initialValues: IForm = {
    monday: {
      start: "",
      end: "",
    },
    tuesday: {
      start: "",
      end: "",
    },
    wednesday: {
      start: "",
      end: "",
    },
    thursday: {
      start: "",
      end: "",
    },
    friday: {
      start: "",
      end: "",
    },
    saturday: {
      start: "",
      end: "",
    },
    sunday: {
      start: "",
      end: "",
    },
    "Mon-Fri": {
      start: "",
      end: "",
    },
  }

  const [isLoading, setIsLoading] = useState(false)

  return (
    <WrapperContentStyled className="content">
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={initialValues} style={{cursor: 'default'}}
      >
        <PromptOnUnsavedChanges/>
        <MainCenterTitleStyle style={{marginTop: 0}}>{t('schedule')}</MainCenterTitleStyle>
        <ResetButton
          onClick={() => {
            form.resetFields();
          }}
        >
          {t('reset')}
        </ResetButton>
        <div style={{clear: 'both', height: 18}}/>
        <>
          <WrapperChangeStyled>
            <ChoiceStyled isActive={is24x7 === true} onClick={onChangeCheckBoxAccess}>
              {t('access')}
            </ChoiceStyled>
            <ChoiceStyled isActive={is24x7 === false} onClick={onChangeCheckBoxNoAccess}>
              {t('no_access')}
            </ChoiceStyled>
          </WrapperChangeStyled>
        </>
        {is24x7 !== null && (
          <div>
            <MainCenterTitleStyle style={{fontSize: 19, marginTop: 10}}>
              {!is24x7 ? t('coworking_working_hours') : t('reception_opening_hours')}
            </MainCenterTitleStyle>
            <div style={{clear: 'both', height: 0}}/>
            <InputTitleStyled style={{position: 'relative'}}>
              {weekdaysButtonIsVisible && <MoreInformationButton
                style={{transform: weekdaysIsOpen ? 'rotate(0deg)' : ''}}
                onClick={toggleWeekdays}
              >
                <DownOutlined/>
              </MoreInformationButton>}
            </InputTitleStyled>
            <div style={{marginLeft: 38, overflow: 'hidden', marginTop: -5}}>
              <WrapperWeekInputStyled isView={weekdaysIsOpen}>
                <TimeInput
                  required={false}
                  title={t('mon_fri')}
                  name={'Mon-Fri'}
                  nextRef={refStartSaturday}
                />

                {props.firstDayOfWeek === 0 && (
                  <TimeInput
                    required={false}
                    title={t('sunday')}
                    name={'sunday'}
                    startRef={refStartSunday}
                  />
                )}
                <TimeInput
                  required={false}
                  title={t('monday')}
                  name={'monday'}
                  nextRef={refStartTuesday}
                />
                <TimeInput
                  required={false}
                  title={t('tuesday')}
                  name={'tuesday'}
                  nextRef={refStartWednesday}
                  startRef={refStartTuesday}
                />
                <TimeInput
                  required={false}
                  title={t('wednesday')}
                  name={'wednesday'}
                  nextRef={refStartThursday}
                  startRef={refStartWednesday}
                />
                <TimeInput
                  required={false}
                  title={t('thursday')}
                  name={'thursday'}
                  nextRef={refStartFriday}
                  startRef={refStartThursday}
                />
                {props.firstDayOfWeek === 1 && (
                  <TimeInput
                    required={false}
                    title={t('friday')}
                    name={'friday'}
                    nextRef={refStartSaturday}
                    startRef={refStartFriday}
                  />
                )}
              </WrapperWeekInputStyled>
            </div>
            {props.firstDayOfWeek === 0 && (
              <TimeInput
                required={false}
                title={t('friday')}
                name={'friday'}
                nextRef={refStartSaturday}
                startRef={refStartFriday}
              />
            )}
            <TimeInput
              required={false}
              title={t('saturday')}
              name={'saturday'}
              nextRef={refStartSunday}
              startRef={refStartSaturday}
            />
            {props.firstDayOfWeek === 1 && (
              <TimeInput
                required={false}
                title={t('sunday')}
                name={'sunday'}
                startRef={refStartSunday}
              />
            )}
          </div>
        )}

        <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
      </Form>
    </WrapperContentStyled>
  );
}
