import { Input, InputNumber } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../../style/globalStyle/Color';

export const CustomInputStyled = styled(Input)`
  height: 40px;
  font-size: 16px;
  &::placeholder {
    text-align: center;
    font-size: 18px;
  }
`;
export const CustomInputNumberStyled = styled(InputNumber)`
  width: 100%;
  height: 40px;
  font-size: 16px;
  &::placeholder {
    text-align: center;
    font-size: 18px;
  }
`;

export const ChoiceStyled = styled.div`
  position: absolute;
  left: 0;
  top: -12px;
  width: 100%;
  height: auto;
  padding-left: 4px;
  font-size: 12px;
  & div {
    height: 20px;
    line-height: 24px !important;
    color: black;
  }
`

export const WrapperPlaceholderStyled = styled.div`
  font-size: 16px;
  line-height: 42px;
  padding-left: 12px;
  letter-spacing: 0px;
  color: ${MAIN_BLUE_COLOR} !important;
  position: absolute !important;
  top: 0;
  right: 10px;
  text-align: right;
  div{
    display: inline-block;
    margin-left: 5px;
    color: black;
  }
`;


export const WrapperPriceInput = styled.div`
  .ant-form-item-label {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .ant-form-item-required {
    width: 100%;
    height: 100%;
  }
  label::before,
  label::after {
    content: none !important;
    display: none !important;
  }
  label{
    height: 40px;
    width: 50px;
    z-index: 2;
    position: absolute;
    right: 0;
    top: 0;
  }
`;
