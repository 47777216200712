import {Form} from 'antd';
import {useState} from 'react';
import {CustomWrapperMaskStyled} from './styles';
import {useTranslation} from 'react-i18next';
import ReactPhoneInput from 'react-phone-input-2'

interface IPhone {
  name: string | Array<string>;
  required: boolean;
}

export function PhoneInput(props: IPhone) {
  const [t] = useTranslation();
  const [expectedPhoneLength, setExpectedPhoneLength] = useState<number | null>(null)
  return (
    <CustomWrapperMaskStyled>
      <Form.Item
        name={props.name}
        rules={[
          {
            required: props.required,
            message: t('required_field'),
            validator: (rule, value) => {
              return new Promise<void>((resolve, reject) => {
                if (!expectedPhoneLength) {
                  resolve()
                }
                // WEB-2194 - any phone longer than 11 digits is valid
                if (value.length >= 11) {
                  resolve()
                }
                if (value.length !== expectedPhoneLength) {
                  reject()
                }
                resolve()
              })
            },
          },
        ]}
        normalize={value => (`+${value}`)}
      >
        <ReactPhoneInput
          country={'us'}
          containerClass='phone-input'
          onChange={(value, data) => {
            if (!("format" in data)) {
              setExpectedPhoneLength(null)
              return
            }
            const countryPhoneSymbols = data.format.match(/\./g)
            if (!countryPhoneSymbols) {
              setExpectedPhoneLength(null)
              return
            }
            setExpectedPhoneLength(countryPhoneSymbols.length)
          }}
        />
      </Form.Item>
    </CustomWrapperMaskStyled>
  );
}
