import styled from "styled-components";

export const ContainerStyled = styled.div`
    display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 16px;
  margin-bottom: 24px;
    margin-top: 32px;

  @media (min-width: 1320px) {
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
    margin-bottom: 48px;
    gap: 0;
      margin-top: 48px;
  }
`

export const ContainerErrorStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (min-width: 1320px) {
    align-items: flex-start;
    width: 565px;
    flex: 1 0 565px;
  }
`

export const NumberStyled = styled.div`
  font-size: 80px;
  font-weight: 900;
  line-height: 104px;
  text-align: left;
  color: #2E60CA;
  @media (min-width: 1320px) {
    font-size: 120px;
    line-height: 156px;
  }
`

export const TitleStyled = styled.div`
  font-size: 26px;
  font-weight: 700;
  line-height: 34px;
  padding-bottom: 24px;
  text-align: center;
  @media (min-width: 1320px) {
    font-size: 40px;
    line-height: 52px;
    padding-bottom: 48px;
    text-align: left;
  }
`

export const ButtonsStyled = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 8px;
`

export const DescriptionStyled = styled.p`
  padding-bottom: 16px;
  max-width: 343px;
  text-align: center;
  @media (min-width: 1320px) {
    padding-bottom: 24px;
    text-align: left;
    max-width: 519px;
  }
`

export const ImageStyled = styled.div`
  @media (min-width: 1320px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    flex: 0 1 737px;
    right: 20px;
    position: relative;
  }
`

export const ImageSizeStyled = styled.svg`
  @media (min-width: 1320px) {
    width: 737px;
    height: 583px;
  }
`
