import {FC, useMemo} from "react";
import {symbolForCurrency} from "utils/price";
import {
  TariffAvatarStyled,
  WrapperMorePhotoButtun,
  PriceTariffStyled,
  MoreInformationTariffStyled,
  ReservationTariffStyled,
  WrapperTariffStyled,
  TitleTariffStyled
} from "./styles";
import notAvalible from './icons/notAvailable.png';
import {useTranslation} from "react-i18next";

interface ITariff {
  item: any;
  reservationType: any;
}


export const Tariff: FC<ITariff> = (props) => {
  const [t] = useTranslation()
  const price = useMemo(() => {
    const price_per_unit = props.item?.available_space?.[0]?.price_per_unit || '???'
    const currency = symbolForCurrency(props.item?.available_space?.[0]?.price_per_unit_currency)
    const reservation_unit = props.reservationType.label
    return [price_per_unit, currency, '/', reservation_unit].join('')
  }, [props.item?.available_space, props.reservationType])
  return (
    <WrapperTariffStyled>
      <TariffAvatarStyled
        style={{
          backgroundImage: `url(${props.item?.images?.images?.fileList?.[0]?.thumbUrl || notAvalible})`,
        }}
      >{props.item?.images?.images?.fileList?.length !== 0 &&
        <WrapperMorePhotoButtun>{props.item?.images?.images?.fileList?.length} {t("photo")}</WrapperMorePhotoButtun>}</TariffAvatarStyled>
      <TitleTariffStyled>{props.item.title.en}</TitleTariffStyled>
      <PriceTariffStyled>{price}</PriceTariffStyled>
      <MoreInformationTariffStyled>{t("find_out_about_this_tariff")}</MoreInformationTariffStyled>
      <ReservationTariffStyled>{t("book_now")}</ReservationTariffStyled>
    </WrapperTariffStyled>
  )
}
