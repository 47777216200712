export const getAddress = async (address: any) => {
  const ruRespunse = await fetch(`https://geocode-maps.yandex.ru/1.x/?geocode=${address}&lang=ru_RU&apikey=${process.env.REACT_APP_YANDEX_API_KEY}&format=json&results=1`);
  const ruData = await ruRespunse.json();
  const enRespunse = await fetch(`https://geocode-maps.yandex.ru/1.x/?geocode=${address}&lang=en_US&apikey=${process.env.REACT_APP_YANDEX_API_KEY}&format=json&results=1`);
  const enData = await enRespunse.json();
  enData.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.push({
    kind: 'unparsed_address',
    name: enData.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.text,
  });

  ruData.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components.push({
    kind: 'unparsed_address',
    name: ruData.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.text,
  });
  return { ru: ruData, en: enData };
};
