import Icon, {PlusOutlined} from '@ant-design/icons';
import {FormInstance, Form, Row, FormListFieldData, message} from 'antd';
import ErrorBoundary from 'antd/lib/alert/ErrorBoundary';
import {RuleObject} from 'antd/lib/form';
import {FormListOperation} from "antd/lib/form/FormList";
import {createGenericModal} from "components/modals/generic";
import {onlyOneNumber} from "components/validate/onlyNumber";
import {I18NLangString, IReservationUnit, ISeatingConfig} from "pages/coworking/types";
import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import coworking from "store/slices/coworking";
import {descriptionNewInText} from "utils/descriptionTariffUtils";
import {getLocalOrEn} from "utils/lang";
import {CascaderInput} from 'components/inputs/cascaderInput';
import {ConvertFromDisplayPriceStringToNumber, PriceInput} from 'components/inputs/priceInput';
import {TextInput} from 'components/inputs/textInput';
import {IUpload, UploadForm} from 'components/inputs/uploadImageInput';
import {createDeleteModal} from 'components/modals/deleteItem';
import {Spiner} from 'components/Spiner';
import {WrapperTimeCascades} from 'components/tariffPlan/styles';
import {LeftColumn} from 'style/colomn/leftColumn';
import {RightColumn} from 'style/colomn/rightColumn';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {
  WrapperTarifBlock,
  WrapperTarifTitle,
  WrapperAddTarifButton,
  WrapperContentTarif,
  PreViewTarif,
  WrapperPreviewImageTarif,
  RightButtonStyled,
  LeftButtonStyled,
  WrapperItemStyled,
  TitleTariffStyled,
  NumberTariffStyled,
  WrapperMobileScrollStyled,
  WrapperIconsStyled,
} from 'pages/updateCoworking/style';
import notAvalible from './icons/notAvailable.png';
import cloneDeep from 'lodash/cloneDeep';

import {ReactComponent as basket} from './icons/basket.svg';
import {ReactComponent as arrow} from './icons/arrow.svg';
import {ReactComponent as exit} from './icons/exit.svg';
import {ReactComponent as arrowMobile} from './icons/arrowMobile.svg';
import {ReactComponent as copy} from './icons/copy.svg';
import {DescriptionTariffInput} from 'components/inputs/descriptionTariff';

export interface INewTarif {
  // Cowork Space
  id?: number,
  title: I18NLangString,
  description: I18NLangString,
  space_type_id: number,
  area: number | null,
  capacity: number,
  is_fixed?: boolean,

  // Available Cowork Space
  available_space_id?: number,
  price_per_unit: string,
  reservation_unit: number,
  cancel_booking_timeout: number,
  is_trial: boolean,
  seating_config: number,
  can_be_sold?: boolean,
}


interface IItemList {
  form: FormInstance;
  coworkingName: string;
  title: string;
  name: string;
  ItemForm: any;
  currency: string;
  seatingConfigs: ISeatingConfig[],
  reservationUnits: IReservationUnit[],
  onTarifCreate: (tarif: INewTarif, images: IUpload) => Promise<{ coworkSpaceResponse: any, availableSpaceResponse: any }>,
  onTarifDelete: (space_id: number) => Promise<void>,
  onTarifPhotoDelete: (space_id: number, file_id: number) => Promise<void>,
}

export const ItemList = (props: IItemList) => {
  return (
    <Form.List name={props.name}>
      {(fields, {add, remove}) => (
        <div style={{marginTop: 16}}>
          <WrapperTarifBlock>
            <WrapperTarifTitle>{props.title}</WrapperTarifTitle>
            <WrapperAddTarifButton onClick={() => add(Object.assign({}, {isView: true}))}>
              <PlusOutlined/>
            </WrapperAddTarifButton>
          </WrapperTarifBlock>
          {fields.reverse().map((field) => (
            <Item field={field} add={add} remove={remove} {...props} />
          ))}
        </div>
      )}
    </Form.List>
  );
};

interface IItemProps extends IItemList {
  field: FormListFieldData,
  add: FormListOperation['add'],
  remove: FormListOperation['remove']
}

const Item = ({field, add, remove, ...props}: IItemProps) => {
  const [t, i18n] = useTranslation()
  const reservationTypes = useMemo(() => {
    return props.reservationUnits.map(unit => ({
      label: getLocalOrEn(unit.type),
      value: unit.id
    }))
  }, [props.reservationUnits])
  const item = Form.useWatch([props.name, field.name], props.form)
  const isView = Form.useWatch([props.name, field.name, 'isView'], props.form)
  const status = Form.useWatch([props.name, field.name, 'status'], props.form)
  const isTrial = Form.useWatch([props.name, field.name, 'available_space', '0', 'is_trial'], props.form)
  const reset = () => {
    props.form.resetFields([
      [props.name, field.name, 'title', i18n.language],
      [props.name, field.name, 'available_space', '0', 'reservation_unit'],
      [props.name, field.name, 'available_space', '0', 'price_per_unit'],
      [props.name, field.name, 'available_space', '0', 'cancel_booking_timeout'],

      [props.name, field.name, 'available_space', '0', 'is_trial'],
      [props.name, field.name, 'available_space', '0', 'id'],
      [props.name, field.name, 'available_space', '0', 'can_be_sold'],
      [props.name, field.name, 'description', i18n.language],
      [props.name, field.name, 'tarifServices'],
      [props.name, field.name, 'payableTarifServices'],
      [props.name, field.name, 'images'],
      [props.name, field.name, 'area'],
      [props.name, field.name, 'capacity'],
      [props.name, field.name, 'is_fixed'],
      [props.name, field.name, 'status'],
      [props.name, field.name, 'id'],
    ]);
  }

  useEffect(() => {
    isTrial &&
    props.form.setFields(
      [{
        name: [props.name, field.name, 'available_space', '0', 'reservation_unit'],
        value: [2]
      }, {name: [props.name, field.name, 'available_space', '0', 'price_per_unit'], value: '0'}]
    )
  }, [field.name, isTrial, props.form, props.name])

  const validatorMock = async (rule: RuleObject, value: string, callback: any) => {
    callback();
  };

  const createTariffWrapper = (tariff: any, space_type_id: number) => {
    if (tariff.status === 'created') {
      const title = getLocalOrEn<string>(tariff.title)
      const description = getLocalOrEn<string[]>(tariff.description) || []
      return props.onTarifCreate({
        title: {en: title, ru: title},
        description: {
          ru: descriptionNewInText(t('the_tariff_includes', {lng: 'ru'}), description),
          en: descriptionNewInText(t('the_tariff_includes', {lng: 'en'}), description),
        },
        space_type_id: space_type_id,
        area: Number(tariff.area),
        capacity: Number(onlyOneNumber(tariff.capacity)),
        price_per_unit: ConvertFromDisplayPriceStringToNumber(tariff.available_space?.[0]?.price_per_unit).toString() || '0.00',
        reservation_unit: tariff.available_space?.[0]?.reservation_unit?.[0],
        cancel_booking_timeout: tariff.available_space?.[0]?.cancel_booking_timeout?.[0],
        is_trial: tariff.available_space?.[0]?.is_trial,
        seating_config: tariff.available_space?.[0]?.seating_config?.[0],
      }, tariff.images)
    } else {
      let description = {ru: "", en: ""}
      if (tariff.description.ru) {
        description.ru = descriptionNewInText(t('the_tariff_includes', {lng: 'ru'}), tariff.description.ru)
      }
      if (tariff.description.en) {
        description.en = descriptionNewInText(t('the_tariff_includes', {lng: 'en'}), tariff.description.en)
      }
      return props.onTarifCreate({
        title: tariff.title,
        description,
        space_type_id: space_type_id,
        is_fixed: tariff.is_fixed,
        area: Number(tariff.area),
        capacity: Number(onlyOneNumber(tariff.capacity)),
        id: tariff.id,
        price_per_unit: ConvertFromDisplayPriceStringToNumber(tariff.available_space?.[0]?.price_per_unit).toString() || '0.00',
        reservation_unit: tariff.available_space?.[0]?.reservation_unit?.[0],
        available_space_id: tariff.available_space?.[0]?.id,
        cancel_booking_timeout: tariff.available_space?.[0]?.cancel_booking_timeout?.[0],

        is_trial: tariff.available_space?.[0]?.is_trial,
        can_be_sold: tariff.available_space?.[0]?.can_be_sold,
        seating_config: tariff.available_space?.[0]?.seating_config?.[0],
      }, tariff.images)
    }
  }

  const onSaveTariff = async () => {
    try {
      const images = props.form.getFieldValue([props.name, field.name, 'images', 'images', 'fileList'])
      await props.form.validateFields([
        [props.name, field.name, 'title', i18n.language],
        [props.name, field.name, 'available_space', '0', 'reservation_unit'],
        [props.name, field.name, 'available_space', '0', 'price_per_unit'],
        [props.name, field.name, 'available_space', '0', 'cancel_booking_timeout'],
        [props.name, field.name, 'available_space', '0', 'can_be_sold'],

        [props.name, field.name, 'available_space', '0', 'is_trial'],
        [props.name, field.name, 'available_space', '0', 'id'],
        [props.name, field.name, 'description', i18n.language],
        [props.name, field.name, 'tarifServices'],
        [props.name, field.name, 'payableTarifServices'],
        [props.name, field.name, 'images', 'images'],
        [props.name, field.name, 'images', 'isError'],
        [props.name, field.name, 'area'],
        [props.name, field.name, 'capacity'],
        [props.name, field.name, 'is_fixed'],
        [props.name, field.name, 'status'],
        [props.name, field.name, 'id'],
        ...images.map((x: any) => ([props.name, field.name, 'images', x.uid, 'en']))
      ])
    } catch {
      return
    }
    const oldStatus = props.form.getFieldValue([props.name, field.name, 'status'])
    createTariffWrapper(props.form.getFieldValue([props.name, field.name]), Number.parseInt(props.name))
      .then((responses) => {
        props.form.setFields([
          {name: [props.name, field.name, 'status'], value: 'saving'},
          {name: [props.name, field.name, 'id'], value: responses.coworkSpaceResponse.id}
        ]);
        message.success(localStorage.getItem('i18nextLng') === 'ru' ?
          `Тариф "${responses.coworkSpaceResponse.title.en}" успешно создан.` :
          `Tariff "${responses.coworkSpaceResponse.title.en}"" successfully created`)
      })
      .catch(() => {
        reset();
        props.form.setFields([{name: [props.name, field.name, 'status'], value: oldStatus}])
        const title = props.form.getFieldValue([props.name, field.name, 'title', i18n.language])
        message.error(localStorage.getItem('i18nextLng') === 'ru' ?
          `Произошла ошибка при сохранении тарифа "${title}". Повторите позже.` :
          `Error when saving tariff "${title}". Please try again later.`)
      })
    props.form.setFields([{name: [props.name, field.name, 'status'], value: 'loading'}])
  }
  const cloneItem = (e: any) => {
    e.stopPropagation()
    if (item.id !== undefined) {
      const clone = cloneDeep(item)
      clone.status = 'created'
      clone.id = undefined
      clone.isView = true
      clone.is_been_booked_at_least_once = false
      clone.images = {
        images: {
          file: {},
          fileList: []
        }
      }
      add(clone)
    }
  }

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const body = document.getElementsByTagName('body')[0];
      if (isView) {
        const scrollY = window.scrollY;
        window.scrollTo(0, 0);
        setTimeout(() => {
          body.style.position = 'fixed';
          body.style.top = `-${scrollY}px`;
        }, 20)
      } else {
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }, [isView])
  const tariff = t("tariff")

  const isNotEditable = useMemo(() => {
    return props.form.getFieldValue([props.name, field.name, 'is_been_booked_at_least_once'])
  }, [field.name, props.form, props.name])

  const onTariffDeleteButtonClicked = () => {
    if (isNotEditable) {
      createGenericModal(t('cannot_delete_tariff_because_was_booked_previously'));
    } else {
      const onSuccess = () => {
        if (props.form.getFieldValue([props.name, field.name, 'id'])) {
          props.onTarifDelete(props.form.getFieldValue([props.name, field.name, 'id'])).then(() => {
            message.success(localStorage.getItem('i18nextLng') === 'ru' ?
              `Тариф успешно удален.` :
              `The tariff has been successfully deleted.`)
            remove(field.name);
          })
        } else {
          remove(field.name);
        }
        props.form.validateFields([
          [props.name, field.name, 'title', i18n.language],
          [props.name, field.name, 'available_space', '0', 'reservation_unit'],
          [props.name, field.name, 'available_space', '0', 'price_per_unit'],
          [props.name, field.name, 'available_space', '0', 'cancel_booking_timeout'],
          [props.name, field.name, 'available_space', '0', 'can_be_sold'],

          [props.name, field.name, 'available_space', '0', 'is_trial'],
          [props.name, field.name, 'available_space', '0', 'id'],
          [props.name, field.name, 'description', i18n.language],
          [props.name, field.name, 'tarifServices'],
          [props.name, field.name, 'payableTarifServices'],
          [props.name, field.name, 'images', 'images'],
          [props.name, field.name, 'images', 'isError'],
          [props.name, field.name, 'area'],
          [props.name, field.name, 'capacity'],
          [props.name, field.name, 'is_fixed'],
          [props.name, field.name, 'status'],
          [props.name, field.name, 'id'],
        ])
        const images = props.form.getFieldValue([props.name, field.name, 'images', 'images', 'fileList'])
        if (images) {
          props.form.validateFields(
            ...images.map((x: any) => ([props.name, field.name, 'images', x.uid, i18n.language]))
          )
        }
      };
      createDeleteModal(onSuccess);
    }
  }

  return (
    <WrapperItemStyled>
      <WrapperContentTarif isView={isView}>
        <WrapperMobileScrollStyled status={status}>
          {status === 'loading' &&
            <Spiner/>
          }

          <WrapperIconsStyled isPreview>
            <Icon component={copy} onClick={cloneItem}
                  style={{fontSize: 20, visibility: item?.id !== undefined ? 'visible' : 'hidden'}}/>

            <Icon component={basket} style={{fontSize: 18}}
                  onClick={onTariffDeleteButtonClicked}
            />

            <Icon component={arrow} style={{fontSize: 16}} className="desctop"
                  onClick={() => {
                    props.form.setFields([{name: [props.name, field.name, 'isView'], value: false}]);
                    window.tariffInformation = props.form.getFieldsValue()
                  }}
            />

            <Icon component={exit} style={{fontSize: 18}} className="mobile"
                  onClick={() => {
                    props.form.setFields([{name: [props.name, field.name, 'isView'], value: false}]);
                    window.tariffInformation = props.form.getFieldsValue()
                  }}
            />

          </WrapperIconsStyled>

          <NumberTariffStyled>{tariff} №{field.name + 1}</NumberTariffStyled>

          <Form.Item name={[field.name, 'id']} noStyle={true}></Form.Item>
          <Form.Item
            name={[field.name, 'status']}
            rules={[{validator: validatorMock, message: 'Please select option!', required: true}]}
            initialValue={props.form.getFieldValue([props.name, field.name, 'id']) ? 'saving' : 'created'}
            noStyle={true}
          />

          <Form.Item
            name={[field.name, 'isView']}
            normalize={(e: any) => {
              return e;
            }}
            initialValue={false}
            noStyle={true}
          />

          <InputTitleStyled>{t("name")}</InputTitleStyled>
          <TextInput disabled={isNotEditable} name={[field.name, 'title', i18n.language]} required={true}/>

          <props.ItemForm disabled={isNotEditable} field={field} form={props.form} nameParent={props.name}
                          seatingConfigs={props.seatingConfigs}/>

          <InputTitleStyled>{t("tariff_price")}</InputTitleStyled>

          <Row>
            <LeftColumn span={12}>
              <WrapperTimeCascades>
                <CascaderInput initialValue={[2]} name={[field.name, 'available_space', '0', 'reservation_unit']}
                               required={false} data={reservationTypes} isDisabled={isTrial || isNotEditable}
                               onChange={() => window.tariffInformation = props.form.getFieldsValue()}/>
              </WrapperTimeCascades>
            </LeftColumn>
            <RightColumn span={12}>
              <PriceInput
                name={[field.name, 'available_space', '0', 'price_per_unit']}
                required={!isTrial}
                isDisabled={isTrial || isNotEditable}
                currency={props.currency}
              />
            </RightColumn>
          </Row>

          <DescriptionTariffInput
            disabled={isNotEditable}
            form={props.form}
            nameParent={props.name}
            name={[field.name, 'description', i18n.language]}
            required={true}
          />

          <ErrorBoundary>
            <UploadForm
              disabledLength={3}
              maxCount={3}
              type={'images'}
              required={true}
              onDeleteItem={(fileId: any) => {
                return props.onTarifPhotoDelete(props.form.getFieldValue([props.name, field.name, 'id']), fileId).then(() => {
                  message.success(localStorage.getItem('i18nextLng') === 'ru' ?
                    `Изображение успешно удалено.` :
                    `The image was successfully deleted.`)
                })
              }}
              form={props.form}
              types={['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']}
              nameParent={props.name}
              name={[field.name, 'images']}
              disabled={isNotEditable}
              defaultDescriptionName={(index) => `${props.coworkingName} / photo / ${props.title} / ${index}`}
            />
          </ErrorBoundary>

          {status !== 'saving' && (
            <>
              <LeftButtonStyled onClick={reset}>{t("reset")}</LeftButtonStyled>
              <RightButtonStyled
                onClick={onSaveTariff}
              >
                {status !== 'updating' ? t('save') : t('update')}
              </RightButtonStyled>
            </>
          )}
        </WrapperMobileScrollStyled>
      </WrapperContentTarif>

      <PreViewTarif
        isView={isView}
        status={status}
        onClick={() => {
          props.form.setFields([{name: [props.name, field.name, 'isView'], value: true}]);
        }}
      >
        {status === 'loading' &&
          <Spiner/>
        }
        <WrapperPreviewImageTarif
          style={{
            backgroundImage: `url(${props.form.getFieldValue(props.name)[field.name]?.images?.images?.fileList?.[0]?.thumbUrl || notAvalible})`,
          }}
        />
        <TitleTariffStyled>{props.form.getFieldValue([props.name, field.name, 'title', i18n.language])}</TitleTariffStyled>
        <NumberTariffStyled>{tariff} №{field.name + 1}</NumberTariffStyled>
        <WrapperIconsStyled>
          <Icon component={copy} onClick={cloneItem}
                style={{fontSize: 20, visibility: item?.id !== undefined ? 'visible' : 'hidden'}}/>

          <Icon component={basket} style={{fontSize: 18}}
                onClick={onTariffDeleteButtonClicked}
          />

          <Icon component={arrow} style={{fontSize: 16, transform: 'rotate(180deg)'}} className="desctop"
                onClick={() => {
                  props.form.setFields([{name: [props.name, field.name, 'isView'], value: false}]);
                  window.tariffInformation = props.form.getFieldsValue()
                }}
          />
          <Icon component={arrowMobile} style={{fontSize: 18}} className="mobile"
                onClick={() => {
                  props.form.setFields([{name: [props.name, field.name, 'isView'], value: false}]);
                  window.tariffInformation = props.form.getFieldsValue()
                }}
          />
        </WrapperIconsStyled>
      </PreViewTarif>
    </WrapperItemStyled>
  );
};
