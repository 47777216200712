import {Error500} from "components/ErrorBoundary/Error500";
import React from "react";

interface IState {
  hasError: boolean
}

type IProps = any

export class ErrorBoundary extends React.Component<IProps, IState> {
  constructor(props: any) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <Error500/>;
    }
    return this.props.children;
  }
}
