import {ISeatingConfig} from "pages/coworking/types";
import {FC, memo, useMemo, useState} from 'react';
import {SizeInput} from '../../../components/inputs/sizeInput';
import {InputTitleStyled} from '../../../style/titles/inputTitleStyle';
import {NumberInput} from '../../../components/inputs/numberInput';
import {AreaCabinetValidateAnt} from '../../../components/validate/areaCabinetValidate';
import {Row} from 'antd';
import {CascaderInput} from '../../../components/inputs/cascaderInput';
import {WrapperTimeCascades} from '../../../components/tariffPlan/styles';
import {useTranslation} from 'react-i18next';
import {LeftColumn} from '../../../style/colomn/leftColumn';
import {RightColumn} from '../../../style/colomn/rightColumn';
import {CheckboxInput} from "../../../components/inputs/checkboxInput";

interface IHall {
  field: any;
  remove: any;
  form: any;
  nameParent: string;
  disabled?: boolean;
  seatingConfigs: ISeatingConfig[],
}

export const HallForm: FC<IHall> = memo((props) => {
  const [t] = useTranslation()
  const [, forceUpdate] = useState({});
  const seatingConfigsTransformed = useMemo(() => {
    if (!props.seatingConfigs) {
      return []
    }
    return props.seatingConfigs.map(c => ({
      label: c.name,
      value: c.id
    }))
  }, [props.seatingConfigs])
  return (
    <>
      <InputTitleStyled>{t("hall_area")}</InputTitleStyled>
      <SizeInput disabled={props.disabled} name={[props.field.name, 'area']}
                 readName={[props.nameParent, props.field.name, 'area']} required={false}
                 validate={AreaCabinetValidateAnt}
                 initialValue={props.form.getFieldValue([props.nameParent, props.field.name, 'area'])}/>
      <LeftColumn xl={8} xs={24}>
        <CheckboxInput onChange={() => {
          forceUpdate({})
        }} name={[props.field.name, 'available_space', '0', 'can_be_sold']} required={false} title={t("can_be_sold")}
                       initialValue={true}/>
      </LeftColumn>
      <InputTitleStyled>{t("number_of_seats")}</InputTitleStyled>
      <Row>
        <LeftColumn span={16}>
          <WrapperTimeCascades>
            <CascaderInput
              isDisabled={props.disabled}
              name={[props.field.name, 'available_space', '0', 'seating_config']}
              required={false}
              data={seatingConfigsTransformed}/>
          </WrapperTimeCascades>
        </LeftColumn>
        <RightColumn span={8}>
          <NumberInput disabled={props.disabled} name={[props.field.name, 'capacity']} required={false}/>
        </RightColumn>
      </Row>
    </>
  );
});
