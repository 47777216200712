import { RuleObject } from "rc-field-form/lib/interface";

export const validURL = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';
  
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  if(!pattern.test(value)){
    error = true
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Enter the correct site url':'Введите корректный url сайта.'
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
}