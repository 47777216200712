import {WrapperPromptsContainer} from "components/prompts/styles";
import {memo, FC, useEffect, useState} from 'react';
import {DndProvider} from 'react-dnd';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {WrapperContentHomePage} from './styles';
import {UpdateUser} from './updateUser';
import {TouchBackend} from 'react-dnd-touch-backend';
import { Prompts } from "pages/coworking/Prompts/Prompts";

export const HomePage: FC = memo(() => {
  const [, forceUpdate] = useState({});
  useEffect(() => {
    forceUpdate({});
  }, []);

  let match = useRouteMatch();
  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
      <WrapperPromptsContainer>
        <WrapperContentHomePage>
          <Switch>
            <Route path={`${match.path}/UpdateUser`} component={UpdateUser}/>
          </Switch>
        </WrapperContentHomePage>
        <Prompts style={{top: 40}} step={null}/>
      </WrapperPromptsContainer>
    </DndProvider>
  );
});
