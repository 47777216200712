import {Form} from 'antd';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link} from 'react-router-dom';
import {EmailInput} from 'components/inputs/emailInput';
import {passwordRecovery} from 'store/slices/auth';
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {MainCenterTitleStyle} from 'style/titles/mainCenterTitleStyle';
import {DynamicButtonStyled, WrapperResetPasswordStyled, WrapperTextStyled} from './styles';

export const ResetPassword: FC = memo(() => {
  const [t] = useTranslation()
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch(passwordRecovery(values))
  };
  return (
    <WrapperResetPasswordStyled>
      <WrapperContentStyled className="content" style={{maxWidth: 410, marginLeft: 'auto', marginRight: 'auto'}}>
        <Form form={form} onFinish={onFinish} onChange={() => form.setFields([{name: "field-to-update", errors: []}])}>

          <MainCenterTitleStyle>{t("forgot_your_password")}</MainCenterTitleStyle>
          <div style={{clear: 'both', height: 13}}/>

          <InputTitleStyled>
            {t("enter_your_email_associated_with_your_account_and_we_will_send_you_instructions_on_how_to_reset_your_password")}
          </InputTitleStyled>
          <EmailInput placeholder={'Email'} name={'email'} required={true}/>

          <DynamicButtonStyled text={t("send_instruction")}/>
          <WrapperTextStyled style={{textAlign: 'center'}}>{t('perhaps_you_want_to')} <Link to="/">{t('log_in')}</Link>.</WrapperTextStyled>
        </Form>
      </WrapperContentStyled>
    </WrapperResetPasswordStyled>
  );
});
