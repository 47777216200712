import {Col, Form, Row} from "antd";
import {CheckboxInput} from "components/inputs/checkboxInput";
import {TextInput} from "components/inputs/textInput";
import {YandexAutoCompileInput} from "components/inputs/yandexAutoCompile";
import {I18NLangString} from "pages/coworking/types";
import {parseAddressComponents} from "pages/updateCoworking/contactsl.tsx/parseAddressComponents";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {MAIN_RED_COLOR} from "style/globalStyle/Color";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {getLocalOrEn} from "utils/lang";
import {get_subway_name_for_language_by_coordinates} from "utils/yandex";
import {IResultDetails, Service} from ".";
import {
  ButtonConfirmStyled,
  ButtonExitStyled, GoogleChoiceStyled, IconMetroStyled, TitleMetroStyled, WrapperChangeMapStyled,
  WrapperMapStyled, WrapperMetroStyled,
  WrapperPositionMapStyled, YandexChoiceStyled,
} from './styled';

declare global {
  interface Window {
    ymaps: any;
    map_id: any;
    placemark: any;

    contacts: any;
    generalInformation: any;
    docs: any;
    gallery: any;
    sceduler: any;
    services: any;
    tariffInformation: any;
  }
}

export function convertCoordinatesToName(latitude: number | string, longitude: number | string) {
  return Number(latitude).toFixed(6) + Number(longitude).toFixed(6)
}

interface IYandexComponent {
  kind: string,
  name: string
}

export interface ISubway {
  distance: number;
  time: number;
  latitude: string;
  longitude: string;
  line: I18NLangString;
  name: I18NLangString;
}

interface ISubwayWithRoutes extends ISubway {
  routes: any[]
}

interface IProps {
  unparsedAddress: string,
  floorsTotal: number,
  floor: string,
  selectedMetros: ISubway[],
  onRequestClose: () => void,
  onChange: (location: IResultDetails) => void,
  onServiceChange: (service: Service) => void,
}

interface IForm {
  suggest: string,
  line: string,
  state: string,
  city: string,
  zip: string,
  floor: string,
  floors_total?: number,
  latitude?: number,
  longitude?: number,

  [key: string]: boolean | number | string | undefined,
}

function parseLocalAddressComponents(address_components: IYandexComponent[]) {
  let newAddress = ''
  let newCity = ''
  let newState = ''
  let newZip = ''
  for (let i of address_components) {
    if (i.kind.includes('house')) {
      newAddress += `${localStorage.getItem('i18nextLng') === 'ru' ? ' д. ' : ' house '}` + i.name;
      continue;
    }

    if (i.kind.includes('street')) {
      newAddress += ' ' + i.name;
      continue;
    }

    if (i.kind.includes('district')) {
      newAddress += ' ' + i.name;
      continue;
    }

    if (i.kind.includes('locality')) {
      newCity += ' ' + i.name;
      continue;
    }

    if (i.kind.includes('province')) {
      newState += ' ' + i.name;
      continue;
    }

    if (i.kind.includes('postal_code_suffix')) {
      newZip += ' ' + i.name;
      continue;
    }

    if (i.kind.includes('house')) {
      newZip += ' ' + i.name;
      continue;
    }
  }
  return {
    address: newAddress,
    city: newCity,
    state: newState,
    zip: newZip
  }
}

export function YandexLocation({
                                 unparsedAddress,
                                 onChange,
                                 onRequestClose,
                                 floorsTotal,
                                 floor,
                                 selectedMetros,
                                 onServiceChange
                               }: IProps) {
  const {t} = useTranslation();
  const [isViewMap, setIsViewMap] = useState(false);
  const [form] = Form.useForm<IForm>()
  const [listMetro, setListMetro] = useState<ISubwayWithRoutes[]>([])
  const [savedAddress, setSavedAddress] = useState<{
    enComponents: IYandexComponent[],
    ruComponents: IYandexComponent[],
    zip: string
  } | null>(null)

  const onFinish = (values: IForm) => {
    if (!savedAddress) {
      throw new Error('No address saved!')
    }
    const details = parseAddressComponents(savedAddress.enComponents, savedAddress.ruComponents)
    const listMetroCheck: ISubway[] = [];
    if (listMetro) {
      for (let i of listMetro) {
        if (values[convertCoordinatesToName(i.latitude, i.longitude)]) {
          const {routes, ...copy} = i;
          listMetroCheck.push({
            ...copy,
            latitude: Number(copy.latitude).toFixed(6),
            longitude: Number(copy.longitude).toFixed(6),
          });
        }
      }
    }

    onChange({
      ...details,
      floor: values.floor,
      floors_total: values.floors_total!,
      latitude: values.latitude!,
      longitude: values.longitude!,
      listMetro: listMetroCheck,
    })
    onRequestClose();
  }

  const createMap = (state: any, caption: any) => {
    try {
      if (!((document?.getElementById('map')?.innerHTML?.length || 0) > 0)) {
        setIsViewMap(true);
        window.map_id = new window.ymaps.Map('map', state);
        // dispatch(setPositionMap(state.center));
        window.placemark = new window.ymaps.Placemark(
          window.map_id.getCenter(),
          {
            iconCaption: caption,
            balloonContent: caption,
          },
          {
            preset: 'islands#redDotIconWithCaption',
          }
        );
        window.map_id.geoObjects.add(window.placemark);
        setCurrentMetroList(state.center);
      } else {
        window.map_id.setCenter(state.center, state.zoom);
        window.placemark.geometry.setCoordinates(state.center);
        window.placemark.properties.set({
          iconCaption: caption,
          balloonContent: caption,
        });
        setCurrentMetroList(state.center);
      }
    } catch {
      console.warn('Not created map');
    }
  }

  const viewObjectOnYandexMap = (obj: any) => {
    const bounds = obj.properties.get('boundedBy');
    const mapState = window.ymaps.util.bounds.getCenterAndZoom(bounds, [1000, 1000]);
    const shortAddress = [obj.getThoroughfare(), obj.getPremiseNumber(), obj.getPremise()].join(' ');
    mapState.controls = [];
    mapState.center = obj.geometry._coordinates;
    createMap(mapState, shortAddress);
  }

  const setCurrentMetroList = async (center: any) => {
    let listUnparsedMetro = window.ymaps.geoQuery(window.ymaps.geocode(center, {
      kind: 'metro',
      results: 3,
      lang: 'en_US'
    }));
    const coords = center;
    for (let i of listMetro) {
      window.map_id.geoObjects.remove(i.routes);
    }
    let metroPromises: Promise<any>[] = []
    listUnparsedMetro.each((item: any) => {
      metroPromises.push(new Promise(async (resolve, reject) => {
        const metro: any = {};
        metro.latitude = item.geometry._coordinates[0] + '';
        metro.longitude = item.geometry._coordinates[1] + '';
        metro.name = {}
        const addressComponents: { kind: string, name: string }[] = item.properties.get('metaDataProperty.GeocoderMetaData.Address.Components');
        const line = addressComponents.find(k => k.kind === 'route')
        if (line) {
          metro.line = {[localStorage.getItem("i18nextLng") || 'en']: line.name}
        }
        const name = addressComponents.find(k => k.kind === 'metro')
        if (!name) {
          return;
        }
        metro.name[localStorage.getItem("i18nextLng") || 'en'] = name.name
        const coordinates: [Number, Number] = [item.geometry.getCoordinates()[1], item.geometry.getCoordinates()[0]]
        try {
          if (localStorage.getItem("i18nextLng") === 'ru') {
            metro.name['en'] = await get_subway_name_for_language_by_coordinates(coordinates, 'en_US')
          } else {
            metro.name['ru'] = await get_subway_name_for_language_by_coordinates(coordinates, 'ru_RU')
          }
        } catch (e) {
          console.error('Could not find subway name for different language')
        }

        let multiRoute = new window.ymaps.multiRouter.MultiRoute(
          {
            referencePoints: [item.geometry._coordinates, coords],
            params: {
              routingMode: 'pedestrian',
            },
          },
          {
            routeStrokeWidth: 3,
            routeStrokeColor: 'rgba(0,0,0,0)',
            routeActiveStrokeColor: '#414150',
            routeActivePedestrianSegmentStrokeStyle: 'solid',

            wayPointFinishIconLayout: null,

            wayPointStartIconLayout: null,

            viaPointIconFillColor: '#000088',
            viaPointActiveIconFillColor: '#E63E92',
          }
        );

        multiRoute.events.once('update', () => {
          let routes = multiRoute.getActiveRoute();
          metro.distance = routes.properties._data.distance.value;
          metro.time = routes.properties._data.duration.value;
          metro.routes = multiRoute;
          setListMetro(prev => {
            const index = prev.findIndex(r => r.longitude === metro.longitude && r.latitude === metro.latitude)
            const copy = [...prev]
            copy[index] = metro
            return copy
          });
          if (form.getFieldValue(convertCoordinatesToName(metro.latitude, metro.longitude))) {
            metro.routes.options.set('routeActiveStrokeColor', MAIN_RED_COLOR);
            metro.routes.options.set('routeActiveZIndex', 10);
          }
          window.map_id.container.fitToViewport();
        });

        window.map_id.geoObjects.add(multiRoute);
        resolve(metro)
      }))
    }).then(() => {
      Promise.all(metroPromises).then(metros => {
        setListMetro(metros)
      })
    });
  };

  const addNewAddress = async (item: any, ruItem: any, enItem: any) => {
    const address: string = item.properties.get('metaDataProperty.GeocoderMetaData.Address').formatted;
    setListMetro([]);
    const address_components = item.properties.get('metaDataProperty.GeocoderMetaData.Address.Components');
    const zip = item.properties.get('metaDataProperty.GeocoderMetaData.Address.postal_code') || '';
    const parsed = parseLocalAddressComponents(address_components)

    const coordinates = ruItem.response.GeoObjectCollection.featureMember[0]?.GeoObject.Point.pos.split(' ')

    form.setFieldsValue({
      suggest: address,
      line: parsed.address,
      state: parsed.state,
      city: parsed.city,
      zip: zip + ' ' + parsed.zip,
      latitude: coordinates[1],
      longitude: coordinates[0],
    })

    setSavedAddress({
      enComponents: enItem.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components,
      ruComponents: ruItem.response.GeoObjectCollection.featureMember[0]?.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components,
      zip: zip
    })

    if (item.properties.get('metaDataProperty.GeocoderMetaData.precision') === 'exact') {
      viewObjectOnYandexMap(item);
    }
  }

  const initialValues: IForm = {
    suggest: unparsedAddress,
    line: "",
    state: "",
    city: "",
    zip: "",
    floors_total: floorsTotal,
    floor: floor,
  }
  if (selectedMetros) {
    for (let metro of selectedMetros) {
      initialValues[convertCoordinatesToName(metro.latitude, metro.longitude)] = true
    }
  }

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <Row style={{position: 'relative'}}>
        <Col span={24} xl={{span: isViewMap ? 12 : 24}}>
          <MainCenterTitleStyle style={{marginTop: 0}}>{t("add_address")}</MainCenterTitleStyle>

          <Form.Item name='longitude' hidden></Form.Item>
          <Form.Item name='latitude' hidden></Form.Item>

          <div style={{clear: 'both', height: 25}}/>
          <Row>
            <WrapperChangeMapStyled>
              <YandexChoiceStyled isActive={true} onClick={() => onServiceChange(Service.Yandex)}>
                Yandex
              </YandexChoiceStyled>
              <GoogleChoiceStyled isActive={false} onClick={() => onServiceChange(Service.Google)}>
                Google
              </GoogleChoiceStyled>
            </WrapperChangeMapStyled>

            <InputTitleStyled style={{marginLeft: 0}}>{t("find_on_the_map")}</InputTitleStyled>
            <YandexAutoCompileInput
              name="suggest"
              onSelect={addNewAddress}
              required={true}
            />

            <Col span={24} sm={{span: 24}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('address')}</InputTitleStyled>
              <TextInput
                disabled
                name='line'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 24}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('state')}</InputTitleStyled>
              <TextInput
                disabled
                name='state'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('city')}</InputTitleStyled>
              <TextInput
                disabled
                name={'city'}
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5, marginBottom: 20}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('ZIP')}</InputTitleStyled>
              <TextInput
                disabled
                name='zip'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('floor')}</InputTitleStyled>
              <TextInput
                name='floor'
                required={true}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5, marginBottom: 20}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('floors_total')}</InputTitleStyled>
              <TextInput
                name='floors_total'
                required={true}
              />
            </Col>
            <WrapperPositionMapStyled style={{display: isViewMap ? '' : 'none'}}>
              <WrapperMapStyled id="map"></WrapperMapStyled>
            </WrapperPositionMapStyled>
            {listMetro.map((item, index) => (
              <WrapperMetroStyled style={{paddingLeft: 0}} key={index}>
                <IconMetroStyled/>
                <Row>
                  <Col span={22} sm={{span: 17}}>
                    <TitleMetroStyled>
                      {t('name')}: {getLocalOrEn(item.name)}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('name_line')}: {getLocalOrEn(item.line)}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('distance')}: {item.distance} {t('meters')}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('walk')}: {(item.time / 60) | 0} {t('minutes')}
                    </TitleMetroStyled>
                  </Col>
                  <Col span={2} sm={{span: 7}} style={{marginTop: 10}}>
                    <CheckboxInput
                      onChange={(e: any) => {
                        // onSelectMetro(e, item);
                      }}
                      name={convertCoordinatesToName(item.latitude, item.longitude)}
                      required={false}
                      title={t('views')}
                    />
                  </Col>
                </Row>
              </WrapperMetroStyled>
            ))}
          </Row>
          <ButtonConfirmStyled
            type="primary"
            htmlType="submit"
          >
            {t('confirm')}
          </ButtonConfirmStyled>
        </Col>
        <Col span={0} xl={{span: isViewMap ? 12 : 0}} style={{zIndex: -1}}></Col>
        <ButtonExitStyled
          onClick={() => {
            onRequestClose();
          }}
        />
      </Row>
    </Form>
  )
}
