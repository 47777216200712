import {FC, memo} from 'react';
import {
  FooterStyled,
  LinkStyled,
  SocialStyled,
  WrapperFooterStyled,
  WrapperLinksStyled,
  WrapperMobileStyled,
  WrapperSocialStyled
} from './style';
import facebook from './icons/facebook.svg';
import google from './icons/google.svg';
import {useTranslation} from 'react-i18next';
import {InstagramFilled} from '@ant-design/icons';
import {MAIN_BLUE_COLOR} from '../../style/globalStyle/Color';

export const Footer: FC = memo(() => {
  const [t] = useTranslation()
  let url_prefix = 'prd'
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'develop':
      url_prefix = 'dev'
      break;
    case 'stage':
      url_prefix = 'stage'
      break;
  }
  const userLang = window.navigator.language.substring(0, 2).toUpperCase()
  const siteLang = localStorage.getItem("i18nextLng")?.toUpperCase() || userLang

  return (
    <WrapperFooterStyled>
      <FooterStyled>
        <WrapperLinksStyled>
          <LinkStyled>©2022 ICOWORK</LinkStyled>
          <LinkStyled
            target="_blank"
            href={`https://s3.eu-central-1.amazonaws.com/${url_prefix}.icowork-media/about_us/privacy_policy_${userLang}-${siteLang}.pdf`}
          >{t("Privacy_policy")}</LinkStyled>
          <LinkStyled
            target="_blank"
            href={`https://s3.eu-central-1.amazonaws.com/${url_prefix}.icowork-media/about_us/about_us_${userLang}-${siteLang}.pdf`}
          >{t("Terms_of_use")}</LinkStyled>
        </WrapperLinksStyled>

        <WrapperSocialStyled>
          <SocialStyled style={{backgroundImage: `url(${facebook})`}} href="https://www.facebook.com/Icowork.co/"
                        target="blank"/>
          <SocialStyled style={{backgroundImage: `url(${google})`}} href="mailto:admin@icowork.co"/>
          <SocialStyled href="https://instagram.com/icowork.co?utm_medium=copy_link" target="blank">
            <InstagramFilled style={{color: MAIN_BLUE_COLOR, fontSize: 29}}/>
          </SocialStyled>
        </WrapperSocialStyled>
        <WrapperMobileStyled href="tel:+74951498999">+ 7 (495) 149-89-99</WrapperMobileStyled>

      </FooterStyled>
    </WrapperFooterStyled>
  )
});
