import {Form} from "antd";
import React from "react";
import {ResetButtonStyled} from "./styled";

interface IResetButton {
  touchedFields?: Array<string>;
  excludeTouchedFields?: Array<string>;
  isDisabled?: boolean;
  style?: any;
  onClick?: any;
  children?: React.ReactNode
}

export function ResetButton(props: IResetButton) {
  return (
    <Form.Item noStyle shouldUpdate>
      {(form) => (
        <ResetButtonStyled
          style={props.style}
          onClick={props.onClick}
          $isDisabled={!form.isFieldsTouched(false)}
        >
          {props.children}
        </ResetButtonStyled>
      )}
    </Form.Item>
  );
}