import Icon from '@ant-design/icons';
import {FC, memo, useEffect, useState} from 'react';
import {
  AddressCoworkingStyled,
  AvatarCoworkingStyled,
  DisabledButtonStyled,
  EmailCoworkingStyled,
  PhoneCoworkingStyled,
  StatusCoworkingStyled,
  TitleCoworkingStyled,
  UpdateButtonStyled,
  WrapperAnimation,
  WrapperCoworkingStyled
} from './styles';
import {ReactComponent as cloud} from './icons/cloud.svg';
import {ReactComponent as update} from './icons/update.svg';
import {MAIN_BLUE_COLOR, MAIN_RED_COLOR} from 'style/globalStyle/Color';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {disableCoworking} from 'store/slices/coworking';

interface ICoworking {
  item: any;
}

export const Coworking: FC<ICoworking> = memo(({item}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const [isAnimated, setIsAnimated] = useState(false);
  const disableButtonCoworking = () => {
    dispatch(disableCoworking(item.id, item.status, 'disabled', item.title, item.description))
  }
  useEffect(() => {
    setIsAnimated(true);
  }, []);
  const getStatusName = (status: string) => {
    if (status === 'draft') {
      return t("coworking_is_being_created")
    }
    if (status === 'approve') {
      return t("coworking_is_being_moderated")
    }
    if (status === 'active') {
      return t("coworking_published")
    }
    if (status === 'disabled') {
      return t("coworking_unpublished")
    }
  }

  const getStatusColor = (status: string) => {
    if (status === 'draft') {
      return 'green'
    }
    if (status === 'approve') {
      return MAIN_RED_COLOR
    }
    if (status === 'active') {
      return MAIN_BLUE_COLOR
    }
    if (status === 'disabled') {
      return 'grey'
    }
  }

  return (
    <WrapperAnimation>
      <WrapperCoworkingStyled className={isAnimated ? '' : 'not-active'}>
        <AvatarCoworkingStyled style={{backgroundImage: `url(${item?.storage?.[0]?.file})`}}/>
        <TitleCoworkingStyled>{item?.title?.en || t("no_data")}</TitleCoworkingStyled>
        <StatusCoworkingStyled
          style={{color: getStatusColor(item.status)}}>{getStatusName(item.status)}</StatusCoworkingStyled>
        <AddressCoworkingStyled>{item?.address?.[0]?.unparsed || t("no_data")}</AddressCoworkingStyled>
        <EmailCoworkingStyled>{item?.coworking_email || t("no_data")}</EmailCoworkingStyled>
        <PhoneCoworkingStyled>{item?.phone_number?.en || t("no_data")}</PhoneCoworkingStyled>

        <DisabledButtonStyled
          onClick={disableButtonCoworking}
          $isActive={item?.status === 'active'}
          disabled={item?.status !== 'active'}>
          <Icon component={cloud}/>{t("disable")}
        </DisabledButtonStyled>

        <a href={'/coworking/' + item?.id}>
          <UpdateButtonStyled>
            <Icon component={update}/> {t("update")}
          </UpdateButtonStyled>
        </a>
      </WrapperCoworkingStyled>
    </WrapperAnimation>
  );
});
