import {I18NLangString} from "pages/coworking/types";
import {getLocalOrEn} from "utils/lang";

type IYandexAddressComponent = {
  kind: string,
  name: string
}

const parseData = (data: IYandexAddressComponent[]) => {
  const findInData = (key: string) => data.find(x => x.kind.includes(key))?.name

  const result: {
    country: string,
    city: string,
    street_line_1: string,
    street_line_2: string,
    state: string,
    building_name: string,
    unparsed: string,
    zip_code: string
  } = {
    country: '',
    city: '',
    street_line_1: '',
    street_line_2: '',
    state: '',
    building_name: '',
    unparsed: '',
    zip_code: ""
  };

  let cityStateComponents = [
    findInData('locality'),
    findInData('province'),
    findInData('area'),
    findInData('district'),
  ].filter(Boolean)

  result.street_line_1 = [
    findInData('street'),
    findInData('house'),
  ].filter(Boolean).join(' ')

  result.country = findInData('country') || ''
  result.state = cityStateComponents[1] || ''
  result.city = cityStateComponents[0] || ''
  result.unparsed = findInData('unparsed_address') || ''

  return result
}

export const parseAddressComponents = (enData: any, ruData: any) => {
  let result: {
    country: I18NLangString,
    city: I18NLangString,
    street_line_1: I18NLangString,
    street_line_2: I18NLangString,
    state: I18NLangString,
    building_name: I18NLangString,
    unparsed: string,
    zip_code: string
  };

  let {unparsed: enUnparsed, ...enParsedData} = parseData(enData)
  let {unparsed: ruUnparsed, ...ruParsedData} = parseData(ruData)

  result = {
    country: {
      ru: ruParsedData.country,
      en: enParsedData.country,
    },
    city: {
      ru: ruParsedData.city,
      en: enParsedData.city,
    },
    building_name: {
      ru: ruParsedData.building_name,
      en: enParsedData.building_name,
    },
    state: {
      ru: ruParsedData.state,
      en: enParsedData.state,
    },
    street_line_1: {
      ru: ruParsedData.street_line_1,
      en: enParsedData.street_line_1,
    },
    street_line_2: {
      ru: ruParsedData.street_line_2,
      en: enParsedData.street_line_2,
    },
    zip_code: getLocalOrEn({ru: ruParsedData.zip_code, en: enParsedData.zip_code}) || "",
    unparsed: getLocalOrEn({ru: ruUnparsed, en: enUnparsed}) || ""
  }
  return result;
};

interface IGoogleData {
  long_name: string
  short_name: string
  types: string[]
}

const parseGoogleData = (data: IGoogleData[]) => {
  const findInData = (key: string) => data.find(x => x.types.includes(key))?.long_name

  const result: {
    country: string,
    city: string,
    street_line_1: string,
    street_line_2: string,
    state: string,
    building_name: string,
    unparsed: string,
    zip_code: string
  } = {
    country: '',
    city: '',
    street_line_1: '',
    street_line_2: '',
    state: '',
    building_name: '',
    unparsed: '',
    zip_code: ""
  };

  let cityStateComponents = [
    findInData('locality'),
    findInData('administrative_area_level_1'),
    findInData('administrative_area_level_2'),
    findInData('administrative_area_level_3'),
    findInData('administrative_area_level_4'),
  ].filter(Boolean)

  result.street_line_1 = [
    findInData('route'),
    findInData('street_number'),
  ].filter(Boolean).join(' ')
  result.country = findInData('country') || ''
  result.state = cityStateComponents[1] || ''
  result.city = cityStateComponents[0] || ''
  result.unparsed = findInData('unparsed_address') || ''
  return result
}

export const parseGoogleAddressComponents = (enData: any, ruData: any) => {
  let result: {
    country: I18NLangString,
    city: I18NLangString,
    street_line_1: I18NLangString,
    street_line_2: I18NLangString,
    state: I18NLangString,
    building_name: I18NLangString,
    unparsed: string,
    zip_code: string
  };

  let {unparsed: enUnparsed, ...enParsedData} = parseGoogleData(enData)
  let {unparsed: ruUnparsed, ...ruParsedData} = parseGoogleData(ruData)

  result = {
    country: {
      ru: ruParsedData.country,
      en: enParsedData.country,
    },
    city: {
      ru: ruParsedData.city,
      en: enParsedData.city,
    },
    building_name: {
      ru: ruParsedData.building_name,
      en: enParsedData.building_name,
    },
    state: {
      ru: ruParsedData.state,
      en: enParsedData.state,
    },
    street_line_1: {
      ru: ruParsedData.street_line_1,
      en: enParsedData.street_line_1,
    },
    street_line_2: {
      ru: ruParsedData.street_line_2,
      en: enParsedData.street_line_2,
    },
    zip_code: getLocalOrEn({ru: ruParsedData.zip_code, en: enParsedData.zip_code}) || "",
    unparsed: getLocalOrEn({ru: ruUnparsed, en: enUnparsed}) || ""
  }
  return result;
};
