import { Input } from 'antd';
import styled from 'styled-components';
import { MAIN_BORDER_COLOR } from '../../../style/globalStyle/Color';
const { TextArea } = Input;

export const CustomInputStyled = styled(TextArea)`
  height: 120px !important;
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 4px;
`;
