import {FC, useEffect} from 'react';
import {MAIN_RED_COLOR} from 'style/globalStyle/Color';
import {WrapperMapStyled} from './styles';

interface IYandexMap {
  data: any;
  metro: Array<any>;
}

export const YandexMap: FC<IYandexMap> = (props) => {
  useEffect(() => {
    if (window.hasOwnProperty('ymaps')) {
      try {
        const map = new window.ymaps.Map('map', {
          center: [props.data.latitude, props.data.longitude],
          zoom: 13
        });
        const placemark = new window.ymaps.Placemark(
          map.getCenter(),
          {
            preset: 'islands#redDotIconWithCaption',
          }
        );
        map.geoObjects.add(placemark);

        for (let i of props.metro) {
          let multiRoute = new window.ymaps.multiRouter.MultiRoute(
            {
              referencePoints: [[i.latitude, i.longitude], [props.data.latitude, props.data.longitude]],
              params: {
                routingMode: 'pedestrian',
              },
            },
            {
              routeStrokeWidth: 3,
              routeStrokeColor: 'rgba(0,0,0,0)',
              routeActiveStrokeColor: MAIN_RED_COLOR,
              routeActivePedestrianSegmentStrokeStyle: 'solid',

              wayPointFinishIconLayout: null,

              wayPointStartIconLayout: null,

              viaPointIconFillColor: '#000088',
              viaPointActiveIconFillColor: '#E63E92',
            }
          );
          map.geoObjects.add(multiRoute);
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [props.data.latitude, props.data.longitude, props.metro])

  return <WrapperMapStyled id="map"/>;
};
