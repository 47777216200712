import { Form } from 'antd';
import { FC, memo } from 'react';
import { CustomCheckBoxStyled } from './styles';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface ICheckbox {
  name: string | Array<any>;
  required: boolean;
  title: React.ReactNode;
  onChange?: any;
  disabled?: boolean;
  initialValue?: boolean;
}

export const CheckboxInput: FC<ICheckbox> = memo((props) => {
  const [t] = useTranslation();
  return (
    <Form.Item
      name={props.name}
      style={{ marginTop: 20 }}
      valuePropName={'checked'}
      rules={[
        {
          required: true,
          transform: (value) => value || !props.required || undefined, // Those two lines
          type: 'boolean', // Do the magic
          message: t('please_agree_the_terms_and_conditions'),
        },
      ]}
      initialValue={props.initialValue}
    >
      <CustomCheckBoxStyled disabled={props.disabled} onChange={props.onChange}>{props.title}</CustomCheckBoxStyled>
    </Form.Item>
  );
});
