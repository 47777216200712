import { Button } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../style/globalStyle/Color';
import { CascaderInput } from '../inputs/cascaderInput';

export const TitlePriceStyled = styled.div`
  margin-top: 10px;
  font-size: 17px;
`;

export const WrapperCascaderStyled = styled.div`
  width: 90px;
  display: inline-block;
  height: 22px;
  margin-left: 5px;
  margin-right: 5px;
  .ant-col .ant-form-item-control-input input {
    height: 30px !important;
  }
`;

export const WrapperTextStyled = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const WrapperCascaderInput = styled(CascaderInput)`
  width: 60px;
`;

export const WrapperTimeCascades = styled.div`
  .ant-form-item-explain-error {
    position: absolute;
  }
`;

export const ButtonConfirmStyled = styled(Button)`
  width: calc(100% - 10px);
  height: 50px;
  background: ${MAIN_BLUE_COLOR} !important;
  border-color: ${MAIN_BLUE_COLOR} !important;
  float: right;
  color: white !important;
`;
