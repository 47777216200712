import {createSlice} from '@reduxjs/toolkit';

export enum ICountry {
  Russia = 'Russia'
}

interface IProfileState {
  country: ICountry
}

export const initialState: IProfileState = {
  country: ICountry.Russia,
};

export const ProfileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
});

export default ProfileSlice.reducer;
