import {Form, message, notification, Row} from "antd";
import {ResetButton} from "components/buttons/resetButton";
import {NumberInput} from "components/inputs/numberInput";
import {ConvertFromDisplayPriceStringToNumber} from "components/inputs/priceInput";
import {SizeInput} from "components/inputs/sizeInput";
import {IUpload} from "components/inputs/uploadImageInput";
import {formatSize} from "components/validate/formatSize";
import {validateNotZeroNumberAnt} from "components/validate/numberValidate";
import {NumberOrNull, onlyNumber, onlyOneNumber} from "components/validate/onlyNumber";
import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {INewTarif, ItemList} from "pages/coworking/Tariffs/ItemList";
import {ICoworking, IReservationUnit, ISeatingConfig, ISpaceConverted} from "pages/coworking/types";
import {CabinetForm} from "pages/updateCoworking/tarifInformation/cabinetForm";
import {HallForm} from "pages/updateCoworking/tarifInformation/hallForm";
import {MeetingRoomForm} from "pages/updateCoworking/tarifInformation/meetingRoomForm";
import {WorkSpaceForm} from "pages/updateCoworking/tarifInformation/workSpacesForm";
import {ValidateErrorEntity} from "rc-field-form/lib/interface";
import React, {useEffect, useMemo, useState} from "react";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {useTranslation} from "react-i18next";
import {LeftColumn} from "style/colomn/leftColumn";
import {RightColumn} from "style/colomn/rightColumn";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {descriptionNewInText} from "utils/descriptionTariffUtils";
import {getLocalOrEn} from "utils/lang";

export interface ICoworkingForm {
  workplace_count_in_office: number,
  fixed_workplace_count: number,
  nonfixed_workplace_count: number,
  area: number,
}

interface IProps {
  coworking: ICoworking,
  tariffs: Record<string, ISpaceConverted[]>
  reservationUnits: IReservationUnit[],
  currency: string,
  seatingConfigs: ISeatingConfig[],
  isActivated: boolean,
  onTarifCreate: (tarif: INewTarif, images: IUpload) => Promise<{ coworkSpaceResponse: any, availableSpaceResponse: any }>,
  onTarifDelete: (space_id: number) => Promise<void>,
  onTarifPhotoDelete: (space_id: number, file_id: number) => Promise<void>,
  onChange: (coworking: ICoworkingForm) => Promise<void>,
  onNextPageRequest: () => void,
  isLastStep: boolean,
}

export function Tariffs(props: IProps) {
  const [form] = Form.useForm();
  const {t} = useTranslation();

  const onFinish = async (values: any) => {
    if (!checkTariffs()) {
      return
    }

    setShouldPrompt(false)
    setIsLoading(true)
    return new Promise<void>((resolve, reject) => {
      let tarifPromises = []

      for (let k = 1; k < 5; k++) {
        if (values[k.toString()]) {
          for (let i in values[k.toString()]) {
            if (values[k.toString()][i].status !== 'saving') {
              const tariff = values[k.toString()][i]
              if (tariff.status === 'created') {
                const title = getLocalOrEn<string>(tariff.title)
                const description = getLocalOrEn<string[]>(tariff.description) || []
                tarifPromises.push(props.onTarifCreate({
                  title: {en: title, ru: title},
                  description: {
                    ru: descriptionNewInText(t('the_tariff_includes', {lng: 'ru'}), description),
                    en: descriptionNewInText(t('the_tariff_includes', {lng: 'en'}), description),
                  },
                  space_type_id: k,
                  area: NumberOrNull(tariff.area),
                  capacity: Number(onlyOneNumber(tariff.capacity)),
                  price_per_unit: ConvertFromDisplayPriceStringToNumber(tariff.available_space?.[0]?.price_per_unit).toString() || '0.00',
                  reservation_unit: tariff.available_space?.[0]?.reservation_unit?.[0],
                  cancel_booking_timeout: tariff.available_space?.[0]?.cancel_booking_timeout?.[0],
                  is_trial: tariff.available_space?.[0]?.is_trial,
                  seating_config: tariff.available_space?.[0]?.seating_config?.[0],
                }, tariff.images))
              } else {
                let description = {ru: "", en: ""}
                if (tariff.description.ru) {
                  description.ru = descriptionNewInText(t('the_tariff_includes', {lng: 'ru'}), tariff.description.ru)
                }
                if (tariff.description.en) {
                  description.en = descriptionNewInText(t('the_tariff_includes', {lng: 'en'}), tariff.description.en)
                }
                tarifPromises.push(props.onTarifCreate({
                  title: tariff.title,
                  description,
                  space_type_id: k,
                  is_fixed: tariff.is_fixed,
                  area: onlyNumber(tariff.area + '' || ''),
                  capacity: Number(onlyOneNumber(tariff.capacity)),
                  id: tariff.id,
                  price_per_unit: ConvertFromDisplayPriceStringToNumber(tariff.available_space?.[0]?.price_per_unit).toString() || '0.00',
                  reservation_unit: tariff.available_space?.[0]?.reservation_unit?.[0],
                  available_space_id: tariff.available_space?.[0]?.id,
                  cancel_booking_timeout: tariff.available_space?.[0]?.cancel_booking_timeout?.[0],

                  is_trial: tariff.available_space?.[0]?.is_trial,
                  can_be_sold: tariff.available_space?.[0]?.can_be_sold,
                  seating_config: tariff.available_space?.[0]?.seating_config?.[0],
                }, tariff.images))
              }
              form.setFields([{name: [k.toString(), i, 'status'], value: 'loading'}])
            }
          }
        }
      }
      Promise.all(tarifPromises).then(() => {
        props.onChange({
          workplace_count_in_office: onlyNumber(values.workplace_count_in_office),
          fixed_workplace_count: onlyNumber(values.fixed_workplace_count),
          nonfixed_workplace_count: onlyNumber(values.nonfixed_workplace_count),
          area: onlyNumber(values.area),
        }).then(() => {
          notification.success({
            message: t('pages.tariffs.save_notification')
          })
        }).then(() => {
          if (!props.isActivated) {
            props.onNextPageRequest()
          }
          setShouldPrompt(true)
          resolve()
        })
      }).catch(() => {
        notification.error({
          message: t('pages.tariffs.tariff_save_error')
        })
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const onFinishError = (e: ValidateErrorEntity) => {
    for (let i of e.errorFields) {
      if (i.name.length > 1) {
        if (form.getFieldValue([i.name[0], i.name[1], 'isView']) === false) {
          form.setFields([{name: [i.name[0], i.name[1], 'isView'], value: true}])
        }
      }
    }
  }

  const onChangeForm = (i: any) => {
    try {
      if (i[0].name.length > 2) {
        if (form.getFieldValue([i[0].name[0], i[0].name[1], 'status']) === 'saving') {
          form.setFields([{name: [i[0].name[0], i[0].name[1], 'status'], value: 'updating'}])
          form.validateFields([[i[0].name[0], i[0].name[1], 'status']])
        }
      }
    } catch (error) {
      console.warn(`error onChangeForm`, error)
    }
  }

  const checkTariffs = () => {
    const tariffs = form.getFieldsValue([1, 2, 3, 4])
    if (!(tariffs?.['1']?.length || tariffs?.['2']?.length || tariffs?.['3']?.length || tariffs?.['4']?.length)) {
      message.error(t("create_at_least_one_tariff"));
      return false
    }
    return true
  }

  const [shouldPrompt, setShouldPrompt] = useState(!props.isLastStep)
  const [isLoading, setIsLoading] = useState(false)

  const initialValues = useMemo(() => {
    return {
      fixed_workplace_count: props.coworking.fixed_workplace_count ? formatSize(props.coworking.fixed_workplace_count) : '0',
      nonfixed_workplace_count: props.coworking.nonfixed_workplace_count ? formatSize(props.coworking.nonfixed_workplace_count) : '0',
      workplace_count_in_office: props.coworking.workplace_count_in_office ? formatSize(props.coworking.workplace_count_in_office) : '0',
      area: formatSize(+props.coworking.area),
      ...props.tariffs
    }
  }, [props.coworking.area, props.coworking.fixed_workplace_count, props.coworking.nonfixed_workplace_count, props.coworking.workplace_count_in_office, props.tariffs])
  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
      <WrapperContentStyled className="content">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishError}
          onFieldsChange={onChangeForm}
          scrollToFirstError={{block: "center"}}
          initialValues={initialValues}
        >
          <PromptOnUnsavedChanges
            shouldPrompt={shouldPrompt}
            onFinish={onFinish}
          />
          <MainCenterTitleStyle style={{marginTop: 0}}>{t("tariffs")}</MainCenterTitleStyle>
          <ResetButton onClick={() => {
            form.resetFields();
          }}>{t('reset')}</ResetButton>
          <div style={{clear: 'both', height: 26}}/>

          <Row>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t("coworking_area")}</InputTitleStyled>
              <SizeInput
                name={'area'}
                required={true}
                initialValue={form.getFieldValue('area')}
                validate={validateNotZeroNumberAnt}
              />
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t("number_of_places_in_the_open_space_assigned")}</InputTitleStyled>
              <NumberInput name={'fixed_workplace_count'} required={false}/>
            </RightColumn>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t("number_of_seats_on_club_cards")}</InputTitleStyled>
              <NumberInput name={'nonfixed_workplace_count'} required={false}/>
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t("number_of_workplaces_in_offices")}</InputTitleStyled>
              <NumberInput name={'workplace_count_in_office'} required={false}/>
            </RightColumn>
          </Row>

          <ItemList
            form={form}
            name='3'
            title={t("workplaces")}
            ItemForm={WorkSpaceForm}
            currency={props.currency}
            seatingConfigs={props.seatingConfigs}
            reservationUnits={props.reservationUnits}
            onTarifCreate={props.onTarifCreate}
            onTarifDelete={props.onTarifDelete}
            onTarifPhotoDelete={props.onTarifPhotoDelete}
            coworkingName={getLocalOrEn(props.coworking.title)!}
          />

          <ItemList
            form={form}
            name={'1'}
            title={t("cabinet")}
            currency={props.currency}
            seatingConfigs={props.seatingConfigs}
            ItemForm={CabinetForm}
            reservationUnits={props.reservationUnits}
            onTarifCreate={props.onTarifCreate}
            onTarifDelete={props.onTarifDelete}
            onTarifPhotoDelete={props.onTarifPhotoDelete}
            coworkingName={getLocalOrEn(props.coworking.title)!}
          />

          <ItemList
            form={form}
            name={'2'}
            title={t("meeting_room")}
            currency={props.currency}
            seatingConfigs={props.seatingConfigs}
            ItemForm={MeetingRoomForm}
            reservationUnits={props.reservationUnits}
            onTarifCreate={props.onTarifCreate}
            onTarifDelete={props.onTarifDelete}
            onTarifPhotoDelete={props.onTarifPhotoDelete}
            coworkingName={getLocalOrEn(props.coworking.title)!}
          />

          <ItemList
            form={form}
            name={'4'}
            title={t("conference_hall")}
            currency={props.currency}
            seatingConfigs={props.seatingConfigs}
            ItemForm={HallForm}
            reservationUnits={props.reservationUnits}
            onTarifCreate={props.onTarifCreate}
            onTarifDelete={props.onTarifDelete}
            onTarifPhotoDelete={props.onTarifPhotoDelete}
            coworkingName={getLocalOrEn(props.coworking.title)!}
          />

          <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
        </Form>
      </WrapperContentStyled>
    </DndProvider>
  );
}
