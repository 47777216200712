import styled from 'styled-components';

export const InputTitleStyled = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 5px;
  @media (min-width: 318px) and (max-width: 576px) {
    margin-left: 0;
  }
`;
