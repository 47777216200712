import {Form, notification} from "antd";
import {ResetButton} from "components/buttons/resetButton";
import {IUpload, UploadForm} from "components/inputs/uploadImageInput";
import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {IGallery, IOldFile, INewFile} from "pages/coworking/types";
import {useEffect, useMemo, useState} from "react";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {useTranslation} from "react-i18next";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {isNumber} from "utils/isNumber";

interface IProps {
  photos: IGallery[],
  isActivated: boolean,
  onChange: (newFiles: INewFile[], oldFiles: IOldFile[]) => Promise<void>,
  onPhotoDelete: (id: number) => Promise<void>,
  onNextPageRequest: () => void,
  isLastStep: boolean,
  coworkingName: string,
}

interface IUploadWithDescriptions extends IUpload {
  [key: string]: any
}

interface IForm {
  images: IUploadWithDescriptions,
}

export function Gallery(props: IProps) {
  const [form] = Form.useForm();
  const [t] = useTranslation()

  const initialValues = useMemo(() => {
    const initialValue: any = {images: {images: {fileList: []}}}

    initialValue.images.images.fileList = props.photos.map(((i: any) => {
      initialValue.images[i.file_id] = typeof (i.description) !== 'string' ? i.description : JSON.parse(i.description);
      return {
        url: i.file,
        uid: i.file_id,
        thumbUrl: i.file,
        type: 'image/png',
      }
    }))
    return initialValue
  }, [props.photos])
  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const onFinish = (values: IForm): Promise<void> => {
    setShouldPrompt(false)
    setIsLoading(true)
    return new Promise<void>((resolve, reject) => {
      const newFiles: INewFile[] = values.images.images.fileList.filter(image => !isNumber(image.uid)).map((image, key) => ({
        file: image.originFileObj!,
        order: key,
        id: 1,
        description: values.images[image.uid.toString()]
      }))
      const oldFiles: IOldFile[] = values.images.images.fileList.filter(image => isNumber(image.uid)).map((image, key) => ({
        uid: image.uid,
        file_id: Number(image.uid),
        order: key,
        description: values.images[image.uid.toString()]
      }))
      props.onChange(newFiles, oldFiles).then(() => {
        notification.success({
          message: t('pages.gallery.save_notification')
        })
      }).then(() => {
        if (!props.isActivated) {
          props.onNextPageRequest()
        }
        setShouldPrompt(true)
        resolve()
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const [shouldPrompt, setShouldPrompt] = useState(!props.isLastStep)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
      <WrapperContentStyled className="content">
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={initialValues}
        >
          <PromptOnUnsavedChanges
            shouldPrompt={shouldPrompt}
            onFinish={onFinish}
          />
          <MainCenterTitleStyle style={{marginTop: 0}}>{t('gallery')}</MainCenterTitleStyle>
          <ResetButton onClick={() => form.resetFields()}>{t('reset')}</ResetButton>
          <div style={{clear: 'both', height: 13}}/>
          <UploadForm onDeleteItem={props.onPhotoDelete}
                      form={form}
                      type={'images'}
                      types={['image/png', 'image/jpeg', 'image/svg+xml', 'image/gif']}
                      name={['images']}
                      required={true}
                      maxCount={20}
                      minCount={5}
                      defaultDescriptionName={(index) => `${props.coworkingName} / photo / ${index}`}
          />

          <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
        </Form>
      </WrapperContentStyled>
    </DndProvider>
  );
}
