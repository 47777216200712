import { CloseOutlined, EllipsisOutlined } from "@ant-design/icons"
import { Col } from "antd"
import styled from "styled-components"
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR } from "../../../../style/globalStyle/Color"
import sceduler from './icons/sceduler.svg';


export const CustomEllipsisOutlined = styled(EllipsisOutlined)`
font-size: 18px;
float: right;
display: block;
margin-top: 3px;
margin-left: 8px;
color: ${MAIN_BLUE_COLOR};
`

export const Icon24x7 = styled.div`
  width: 40px;
  height: 40px;
  background-image: url(${sceduler});
  background-size: cover;
  background-position: center;
  margin-right: 15px;
`


export const WrapperReceptionStyled = styled(Col)`
  display: flex;
  justify-content: end;
  @media (min-width: 318px) and (max-width: 991px) {
    justify-content: center;
  }
  cursor: pointer;
`

export const ReceptionStyled = styled.div`
  display: flex;
  justify-content: end;
  @media (min-width: 318px) and (max-width: 991px) {
    justify-content: center;
  }
  position: relative;
`

export const ScedulerButtonStyled = styled.div`
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;
  padding: 8px 16px;
  font-weight: bold;

  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const ModalScedulerStyled = styled.div`
  width: calc(100% + 10px);
  background: white;
  position: absolute;
  z-index: 2;
  top: -5px;
  left: -5px;
  padding: 32px;
  padding-top: 20px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;
  cursor: auto;
  @media (min-width: 318px) and (max-width: 576px) {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    height: 100%;
  }
`

export const LineDecorationStyled = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${MAIN_BORDER_COLOR};
  margin-top: 20px;
  margin-bottom: 20px;
  @media (min-width: 318px) and (max-width: 576px) {
    display: none;
  }
`

export const WrapperScedulerOnTheDay = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
  @media (min-width: 318px) and (max-width: 576px) {
    border-bottom: 1px solid ${MAIN_BORDER_COLOR};
    padding-bottom: 16px;
    margin-top: 16px;
  }
`

export const TitleDayStyled = styled.div`

`

export const TimeStyled = styled.div`
  color: ${MAIN_BLUE_COLOR};
  font-weight: bold;
`

export const WrapperCloseOutlined = styled(CloseOutlined)`
  position: absolute;
  right: 23px;
  top: 23px;
  font-size: 18px;
`
