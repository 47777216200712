import {Form} from 'antd';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomInputStyled} from './styles';

interface IEmail {
  name: string;
  required: boolean;
  onChange?: any;
  normalize?: any;
  value?: string;
  placeholder?: string;
}

export const EmailInput: FC<IEmail> = memo((props) => {
  const [t] = useTranslation();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: props.required,
          message: t('please_enter_a_valid_email'),
          type: 'email'
        }
      ]}
      normalize={props.normalize}
    >
      <CustomInputStyled placeholder={props.placeholder} onChange={props.onChange}/>
    </Form.Item>
  );
});
