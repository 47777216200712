import styled from 'styled-components';
import { MAIN_BORDER_COLOR } from '../../style/globalStyle/Color';

export const WrapperFooterStyled = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 80px;
  background-color: white;
  z-index: 2;
  max-width: 1440px;
  margin-left: auto;
  @media (max-width: 1480px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  margin-right: auto;
  @media (min-width: 318px) and (max-width: 1000px) {
    height: 255px;
    position: relative;
    display: flex;
    justify-content: center;
  }
`;

export const FooterStyled = styled.div`
  height: 80px;
  border-top: 1px solid ${MAIN_BORDER_COLOR};
  padding-top: 23px;
  position: relative;
  @media (min-width: 318px) and (max-width: 1000px) {
    width: calc(100% - 32px);
    margin-left: 16px;
  }
`

export const WrapperLinksStyled = styled.div`
  margin-left: -40px;
  float: left;
  @media (min-width: 318px) and (max-width: 1000px) {
    position: absolute;
    top: 120px;
    text-align: center;
    width: 100%;
    margin: 0;
  }
`

export const WrapperSocialStyled = styled.div`
  float: right;
  margin-right: -25px;
  @media (min-width: 318px) and (max-width: 1000px) {
    position: absolute;
    width: calc(100px + 15%);
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    top: 65px;
  }
`

export const LinkStyled = styled.a`
  display: block;
  float: left;
  margin-left: 40px;
  margin-top: 5px; 

  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;

  color: #9DA3AA;
  @media (min-width: 318px) and (max-width: 1000px) {
    float: none;
    margin-top: 15px;
    margin-left: 0;
  }
`

export const SocialStyled = styled.a`
  width: 27px;
  height: 29px;
  float: right;
  margin-right: 30px;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 318px) and (max-width: 991px) {
    float: none;
    display: block;
    margin: 0px;
  }
`

export const WrapperMobileStyled = styled.a`
  float: right;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  margin-top: 3px;
  margin-right: 30px;
  color: #9DA3AA;
  @media (min-width: 318px) and (max-width: 1000px) {
    float: none;
    text-align: center;
    margin: 0;
    margin-top: -5px;
    display: block;
  }
`