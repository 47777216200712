import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import createCoworkingReducer from './slices/coworking';
import authReducer from './slices/auth';
import profileReducer from './slices/profile';

export const store = configureStore({
  reducer: {
    coworking: createCoworkingReducer,
    auth: authReducer,
    profile: profileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
