import { Cascader, Form } from 'antd';
import React, { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { WrapperCascaderStyled } from './styles';

interface ICascader {
  name: string | Array<any>;
  required: boolean;
  data: any;
  fieldKey?: any;
  isDisabled?: boolean;
  onChange?: any;
  initialValue?: any;
  value?: any;
}

export const CascaderInput: FC<ICascader> = memo((props) => {
  const [t] = useTranslation();
  return (
    <WrapperCascaderStyled>
      <Form.Item initialValue={props.initialValue} name={props.name} rules={[{ required: props.required, message: t('required_field') }]} fieldKey={props.fieldKey}>
        <Cascader allowClear={false} options={props.data} placeholder="" disabled={props.isDisabled} onChange={props?.onChange}/>
      </Form.Item>
    </WrapperCascaderStyled>
  );
});
