export const data24 = {
  monday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  tuesday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  wednesday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  thursday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  friday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  saturday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  sunday: {
    start: '00:00',
    end: '23:59',
    available: true,
  },
  is_24x7: true,
};
