import { Checkbox } from 'antd';
import styled from 'styled-components';

export const CustomCheckBoxStyled = styled(Checkbox)`
  width: 100%;
  border-radius: 0;
  & > label {
    width: 32%;
    position: relative;
  }

  input {
    width: 20px !important;
    height: 20px !important;
    border-radius: 0;
  }

  span {
    width: 20px !important;
    height: 20px !important;
  }

  .ant-checkbox-inner{
    border: 2px solid #2E60CA !important;
    border-radius: 0;
  }
  .ant-checkbox-inner::after {
    left: calc(50% - 4px);
  }

  .ant-checkbox + span {
    position: absolute;
    left: 25px;
    top: 5px;
    width: 100% !important;
  }

  & > span:last-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 20px) !important;
  }
`;
