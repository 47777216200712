import {Form, notification} from "antd";
import {RcFile} from "antd/lib/upload/interface";
import {ResetButton} from "components/buttons/resetButton";
import {IUploadWithFileUrl, UploadForm} from "components/inputs/uploadImageInput";
import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {ICoworking} from "pages/coworking/types";
import {useMemo, useState} from "react";
import {DndProvider} from "react-dnd";
import {TouchBackend} from "react-dnd-touch-backend";
import {useTranslation} from "react-i18next";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";

const fileTypes = ['application/pdf', '', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']

interface IProps {
  coworking: ICoworking,
  isActivated: boolean,
  onChange: (offer: RcFile | string | null, rules: RcFile | string | null, plan: RcFile | string | null) => Promise<void>,
  onNextPageRequest: () => void,
}

interface IForm {
  document_offer: IUploadWithFileUrl,
  document_rules: IUploadWithFileUrl,
  plan: IUploadWithFileUrl
}

export function Docs(props: IProps) {
  const [form] = Form.useForm();
  const [t] = useTranslation()

  const onFinish = (values: IForm): Promise<void> => {
    setIsLoading(true)
    return new Promise<void>((resolve, reject) => {
      let document_rules = values.document_rules.images?.fileList?.[0]
      let document_offer = values.document_offer.images?.fileList?.[0]
      let plan = values.plan.images?.fileList?.[0]

      let offerSend = null, rulesSend = null, planSend = null;

      if (!document_rules) {
        rulesSend = ''
      } else {
        if (!document_rules.fileUrl && document_rules.originFileObj) {
          rulesSend = document_rules.originFileObj
        }
      }
      if (!document_offer) {
        offerSend = ''
      } else {
        if (!document_offer.fileUrl && document_offer.originFileObj) {
          offerSend = document_offer.originFileObj
        }
      }
      if (!plan) {
        planSend = ''
      } else {
        if (!plan.fileUrl && plan.originFileObj) {
          planSend = plan.originFileObj
        }
      }
      props.onChange(offerSend, rulesSend, planSend).then(() => {
        notification.success({
          message: t('pages.docs.save_notification')
        })
      }).then(() => {
        if (!props.isActivated) {
          props.onNextPageRequest()
        }
        resolve()
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const initialValues = useMemo(() => {
    const initialValues: any = {
      plan: {images: {fileList: []}},
      document_rules: {images: {fileList: []}},
      document_offer: {images: {fileList: []}},
    }

    if (props.coworking.document_offer) {
      initialValues.document_offer.images.fileList.push({
        uid: 'eee',
        type: 'application/pdf',
        fileUrl: props.coworking.document_offer
      })
    }
    if (props.coworking.document_rules) {
      initialValues.document_rules.images.fileList.push({
        uid: 'www',
        type: 'application/pdf',
        fileUrl: props.coworking.document_rules
      })
    }
    if (props.coworking.plan) {
      initialValues.plan.images.fileList.push({uid: 'qqq', type: 'application/pdf', fileUrl: props.coworking.plan})
    }
    return initialValues
  }, [props.coworking.document_offer, props.coworking.document_rules, props.coworking.plan])

  const [isLoading, setIsLoading] = useState(false)

  return (
    <DndProvider backend={TouchBackend} options={{enableMouseEvents: true}}>
      <WrapperContentStyled className="content">
        <Form form={form}
              onFinish={onFinish}
              initialValues={initialValues}
        >
          <MainCenterTitleStyle style={{marginTop: 0}}>{t("the_documents")}</MainCenterTitleStyle>
          <ResetButton onClick={() => form.resetFields()}>{t("reset")}</ResetButton>
          <div style={{clear: 'both', height: 13}}/>

          <MainCenterTitleStyle style={{fontSize: 19, float: 'none'}}>{t("coworking_layout")}</MainCenterTitleStyle>
          <UploadForm
            form={form}
            disabledLength={1}
            type={'docs'}
            types={fileTypes}
            name={['plan']}
            maxCount={1}
            minCount={1}
            required={false}
            text={'Drag & drop your docs here (pdf, docs)'}
            hideDescription
          />

          <MainCenterTitleStyle style={{fontSize: 19, float: 'none'}}>{t("contract_offer")}</MainCenterTitleStyle>
          <UploadForm
            form={form}
            disabledLength={1}
            type={'docs'}
            types={fileTypes}
            name={['document_offer']}
            maxCount={1}
            required={false}
            text={'Drag & drop your docs here (pdf, docs)'}
            hideDescription
          />

          <MainCenterTitleStyle style={{fontSize: 19, float: 'none'}}>{t("booking_rules")}</MainCenterTitleStyle>
          <UploadForm
            form={form}
            disabledLength={1}
            type={'docs'}
            types={fileTypes}
            name={['document_rules']}
            maxCount={1}
            required={false}
            text={'Drag & drop your docs here (pdf, docs)'}
            hideDescription
          />

          <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
        </Form>
      </WrapperContentStyled>
    </DndProvider>
  );
}
