import {Form, notification} from "antd";
import {ResetButton} from "components/buttons/resetButton";
import {ListCheckboxInput} from "components/inputs/listCheckboxInput";
import {SelectAddInput} from "components/inputs/selectAddInput";
import {filter} from "lodash";
import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {getLocalOrEn} from "utils/lang";
import {
  ExitIconStyled,
  RowStyled,
  ServicesListStyled,
  WrapperExitStyled,
  WrapperServicesListStyled,
} from "../../updateCoworking/services/styles";
import {IAmenity, IAmenityCategory} from "../types";

interface IAmenityCategoryLocalized {
  category: string,
  amenities: {
    label: string,
    value: number,
    icon: string
  }[]
}

interface IProps {
  possibleAmenities: IAmenityCategory[];
  coworkingAmenities: IAmenity[] | null;
  isActivated: boolean,
  onChange: (existingAmenities: number[], newAmenities: string[]) => Promise<void>,
  onNextPageRequest: () => void,
  isLastStep: boolean,
}

type IForm = Omit<Record<string, number[]>, 'customServices'> & {
  customServices?: string[]
}

export function Amenities(props: IProps) {
  const {t} = useTranslation();
  const [form] = Form.useForm();
  const [isDisabled, setIsDisabled] = useState(false)

  const onFinish = (values: IForm): Promise<void> => {
    setShouldPrompt(false)
    setIsLoading(true)
    return new Promise<void>((resolve, reject) => {
      const {customServices, ...rest} = values;
      props.onChange(Object.entries(rest).flatMap(x => x[1]), customServices ?? []).then(() => {
        notification.success({
          message: t('pages.services.save_notification')
        })
        if (!props.isActivated) {
          props.onNextPageRequest()
        }
        setShouldPrompt(true)
        resolve()
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const possibleAmenitiesMapped: IAmenityCategoryLocalized[] = useMemo(() => {
    return props.possibleAmenities.map(ac => ({
      category: getLocalOrEn(ac.category)!,
      amenities: ac.amenities.map(am => ({
        label: getLocalOrEn(am.abbreviation)!,
        value: am.id,
        icon: am.icon,
      }))
    }))
  }, [props.possibleAmenities])

  const initialValues: IForm = useMemo(() => {
    const coworkingAmenitiesIds = props.coworkingAmenities?.map(x => x.id) || []

    return possibleAmenitiesMapped.reduce((obj, item) => ({
      ...obj,
      [item.category]: item.amenities.filter(x => coworkingAmenitiesIds.includes(x.value)).map(x => x.value)
    }), {customServices: []})
  }, [possibleAmenitiesMapped, props.coworkingAmenities])

  useEffect(() => {
    form.resetFields()
  }, [form, initialValues])

  const customServices = useMemo(() => {
    return filter(possibleAmenitiesMapped, item => {
      return item.category === t("additional_services")
    })
  }, [possibleAmenitiesMapped, t])
  const defaultServices = useMemo(() => {
    return filter(possibleAmenitiesMapped, item => {
      return item.category !== t("additional_services")
    })
  }, [possibleAmenitiesMapped, t])

  const coworkingAmenitiesIds = useMemo(() => {
    if (!props.coworkingAmenities) {
      return []
    }
    return props.coworkingAmenities.map(s => s.id)
  }, [props.coworkingAmenities])

  const [shouldPrompt, setShouldPrompt] = useState(!props.isLastStep)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <WrapperContentStyled className="content">
      <Form form={form} onFinish={onFinish} initialValues={initialValues}>
        <PromptOnUnsavedChanges
          shouldPrompt={shouldPrompt}
          onFinish={onFinish}
        />

        <MainCenterTitleStyle style={{marginTop: 0}}>{t("services")}</MainCenterTitleStyle>
        <ResetButton onClick={() => form.resetFields()}>{t('reset')}</ResetButton>
        <div style={{clear: 'both', height: 13}}/>
        <ServicesListStyled>
          <>
            {defaultServices.map((item) => (
              <ServicesList
                key={item.category}
                item={item}
                coworkingServices={coworkingAmenitiesIds}
              />
            ))}
            {customServices.map((item) => (
              <ServicesList
                key={item.category}
                item={item}
                coworkingServices={coworkingAmenitiesIds}
              />
            ))}
          </>
        </ServicesListStyled>

        <MainCenterTitleStyle style={{fontSize: 19, marginTop: 11}}>{t("add_service")}</MainCenterTitleStyle>
        <div style={{clear: 'both', height: 0}}/>
        <SelectAddInput form={form} name={'customServices'} required={false} multiple={true} data={[]} onChange={() => {
          setIsDisabled(false);
        }}/>

        <NextStepButton loading={isLoading} isActivated={props.isActivated} isDisabled={isDisabled}/>
      </Form>
    </WrapperContentStyled>
  );
}

interface IServicesList {
  item: IAmenityCategoryLocalized,
  coworkingServices: number[]
}

function ServicesList(props: IServicesList) {
  const [isView, setIsView] = useState(true)

  const options = useMemo(() => {
    return props.item.amenities.map((item) => ({
      ...item,
      checked: props.coworkingServices.includes(item.value)
    }))
  }, [props.coworkingServices, props.item.amenities])

  return (
    <WrapperServicesListStyled isView={isView} className={'WrapperServicesList'}>
      <WrapperExitStyled onClick={() => {
        setIsView(!isView)
      }}>
        <MainCenterTitleStyle style={{fontSize: 19, margin: 11}}> {props.item.category}</MainCenterTitleStyle>
        <div style={{clear: 'both', height: 0}}/>
        <ExitIconStyled isView={isView}/>
      </WrapperExitStyled>
      <RowStyled/>
      <ListCheckboxInput
        name={props.item.category}
        options={options}
        required={false}
      />
    </WrapperServicesListStyled>
  );
}
