export const descriptionNewInText = (title: string, itemList: Array<string>) => {
  return convertDescriptionInText(title, itemList, '&#10004;')
}

const convertDescriptionInText = (title: string, itemList: Array<string>, split: string) => {
  let text = title.slice(-1) === ':' ? title : title + ':'
  for (let i of itemList) {
    text = text + `<br />\n${split} ` + i
  }
  return text
}

export const textInDescription = (text: string) => {
  // Try to guess which description converter is correct
  const itemsNewFormat = textInDescriptionNew(text);
  if (itemsNewFormat.length > 0) {
    return itemsNewFormat
  }
  const itemsOldFormat = textInDescriptionOld(text);
  if (itemsOldFormat.length > 0) {
    return itemsOldFormat;
  } else {
    return itemsNewFormat;
  }
}

export const textInDescriptionOld = (text: string) => {
  return convertTextInDescription(text, '-')
}

export const textInDescriptionNew = (text: string) => {
  return convertTextInDescription(text, '&#10004;')
}

const convertTextInDescription = (text: string, split: string) => {
  const itemList = text.split(`<br />\n${split} `)
  itemList.shift()
  return itemList
}
