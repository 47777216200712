import styled from "styled-components";
import { MAIN_BORDER_COLOR } from "../../style/globalStyle/Color";
import exit from './icons/exit.svg';


export const WrapperGaleryStyled = styled.div`
  width:100%;
  height: 0px;
  padding-top:35%;
  margin-bottom: 10px;
  margin-top: 10px;
  position: relative;
  @media (min-width: 318px) and (max-width: 1000px) {
    padding-top: 0;
    height: auto;
  }
`

export const WrapperImagesStyled = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-clip: content-box;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  background-color: grey;

  @media (min-width: 318px) and (max-width: 1000px) {
    display: none;
    &:first-child{
      display: block;
      position: relative;
      left: 0;
      right: 0;
      margin: auto;
      width: 343px !important;
      height: 232px !important;
      max-width: 100%;
      padding: 0;
    }
  }
`

export const WrapperAllPhotosStyled = styled.div`
  position: fixed;
  width: 100%;
  height: calc(100% - 80px);
  background: white;
  z-index: 999;
  left: 0;
  top: 80px;
`

export const ExitIconStyled = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${exit});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 5px;
  right: 24px;
  cursor: pointer;
`

export const WrapperContentStyled = styled.div`
  width: 700px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
`

export const ImagesStyled = styled.div`
  height: 200px;
  flex-grow: 1;
  width: auto;
  background-size: cover;
  background-position: center;
  padding: 4px;
  width: 50%;
  background-clip: content-box;
  float: left;
`

export const MoreImageButtonStyled = styled.div`
  width: 343px;
  height: 40px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  display: none;
  @media (min-width: 318px) and (max-width: 1000px) {
    display: flex;
  }
`