import React from 'react';
import i18next from 'i18next';
import { Select } from 'antd';
import { WrapperLanguageSelect, WrapperOptionsStyled } from './style';
import ru from './icons/ru.svg';
import eng from './icons/eng.svg';

const { Option } = Select;

const languageMap: any = {
  en: { label: 'English', active: true },
  ru: { label: 'Русский', active: false },
};

export const LanguageSelect = () => {
  const selected = localStorage.getItem('i18nextLng') || 'en';

  const [menuAnchor] = React.useState(null);
  React.useEffect(() => {
    document.body.dir = languageMap[selected].dir;
  }, [menuAnchor, selected]);

  return (
    <WrapperLanguageSelect>
      <Select
        defaultValue={selected}
        style={{ width: 80, height: 40}}
        onChange={(e) => {
          i18next.changeLanguage(e, () => {});
          window.location.reload();
        }}
      >
        <Option value="ru"><WrapperOptionsStyled style={{backgroundImage: `url(${ru})`}}/></Option>
        <Option value="en"><WrapperOptionsStyled style={{backgroundImage: `url(${eng})`}}/></Option>
      </Select>
    </WrapperLanguageSelect>
  );
};
