import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../../style/globalStyle/Color';

export const WrapperCascaderStyled = styled.div`
  svg{
    fill: ${MAIN_BLUE_COLOR};
  }
  height: 40px !important;
  .ant-col {
    height: 40px !important;
    .ant-form-item-control-input {
      height: 40px !important;
      input {
        height: 40px !important;
        border-radius: 8px;
      }
    }
  }
`;
