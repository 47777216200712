import {notification} from "antd";
import {RcFile} from "antd/lib/upload/interface";
import {IUpload} from "components/inputs/uploadImageInput";
import {ISubway} from "components/modaAutoCompileForm/YandexLocation";
import {deleteRequest, formDataRequest, postRequest, putRequest} from 'core/fetchWrapper';
import {Activation} from "pages/coworking/Activation/Activation";
import {
  getAmenities,
  getCoworkingAddress,
  getCoworkingAmenities,
  getCoworkingData,
  getCoworkingPhotos,
  getCoworkingReceptionTimetable,
  getCoworkings,
  getCoworkingTariffs,
  getCoworkingTimetable,
  getCrms,
  getReservationUnits,
  getSeatingConfigs,
  throwErrorIfFailed
} from "pages/coworking/api/api";
import {Gallery} from "pages/coworking/Gallery/Gallery";
import {Prompts} from "pages/coworking/Prompts/Prompts";
import {getCoworkingUrlByStep, ICoworkingStep, Steppers} from 'pages/coworking/Steppers/Steppers';
import {WrapperContainerStyled} from "pages/coworking/styles";
import {INewTarif} from "pages/coworking/Tariffs/ItemList";
import {ICoworkingForm, Tariffs} from "pages/coworking/Tariffs/Tariffs";
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {Route, Switch, useHistory, useLocation, useParams, useRouteMatch} from "react-router-dom";
import {data24} from "store/mockData/24_7";
import {userDataSelector} from 'store/slices/auth';
import {textInDescription} from "utils/descriptionTariffUtils";
import {isNumber} from "utils/isNumber";
import {getLocalOrEn, setLocalOrEverything} from "utils/lang";
import {Amenities} from "./Amenities/Amenities";
import {createOrUpdateCoworkingGeneralData, sendCreateOrUpdateCoworking, updateAddress} from "./api/update";
import {Contacts} from "./Contacts/Contacts";
import {Docs} from "./Docs/Docs";
import {General} from "./General/General";
import {Scheduler} from "./Scheduler/Scheduler";
import {
  I18NLangString,
  IAddress,
  IAmenity,
  IAmenityCategory,
  ICoworking,
  ICoworkingStatus,
  ICreateAddress,
  ICreateCoworking,
  ICreateTimetable,
  ICRM,
  IGallery,
  INewFile,
  IOldFile,
  IReservationUnit,
  ISeatingConfig,
  ISpaceConverted,
  ITimetable
} from "./types";

export function Coworking() {
  const [coworkingId, setCoworkingId] = useState<number | null>(null);
  const [coworking, setCoworking] = useState<ICoworking | null>(null);
  const [address, setAddress] = useState<IAddress | null>(null);
  const [timetable, setTimetable] = useState<ITimetable | null>(null);
  const [reception_timetable, setReceptionTimetable] = useState<ITimetable | null>(null);
  const [coworkingAmenities, setCoworkingAmenities] = useState<IAmenity[]>([]);
  const [possibleAmenities, setPossibleAmenities] = useState<IAmenityCategory[]>([]);
  const [spaces, setSpaces] = useState<Record<string, ISpaceConverted[]>>({});
  const [gallery, setGallery] = useState<IGallery[]>([]);
  const [crmList, setCrmList] = useState<ICRM[]>([]);
  const [reservationUnits, setReservationUnits] = useState<IReservationUnit[]>([]);
  const [seatingConfigs, setSeatingConfigs] = useState<ISeatingConfig[]>([]);
  const [isLoading, setIsLoading] = useState<Boolean>(true);
  const userData = useSelector(userDataSelector);
  const match = useRouteMatch();
  const history = useHistory();
  const params = useParams<{ id?: string }>();
  const {t} = useTranslation();

  const location = useLocation()
  useEffect(() => {
    const locationParts = location.pathname.split('/')
    if (locationParts.length <= 2) {
      // /coworking
      getCoworkings().then((coworkings) => {
        const draftCoworking = coworkings.find(coworking => coworking.status === ICoworkingStatus.Draft)
        if (!draftCoworking) {
          setIsLoading(false);
          history.push(`/coworking/${getCoworkingUrlByStep(ICoworkingStep.General)}`);
        } else {
          setCoworkingId(draftCoworking.id);
          history.push(`/coworking/${draftCoworking.id}`);
        }
      })
    } else {
      const possibleId = Number(params.id)
      if (locationParts.length === 3) {
        // /coworking/{id}
        if (possibleId && !Number.isNaN(possibleId)) {
          setCoworkingId(possibleId)
          history.push(`/coworking/${possibleId}/${getCoworkingUrlByStep(ICoworkingStep.General)}`);
        }
      } else {
        // /coworking/{id}/{step}
        if (possibleId && !Number.isNaN(possibleId)) {
          setCoworkingId(possibleId)
        }
      }
    }
  }, [history, location.pathname, params.id])

  const isActivated = useMemo(() => {
    if (!coworking) {
      return false
    }

    return coworking.status === ICoworkingStatus.Active
  }, [coworking])

  const changeStep = (step: ICoworkingStep) => {
    if (coworkingId) {
      history.push(`/coworking/${coworkingId}/${getCoworkingUrlByStep(step)}`);
    }
  }

  useEffect(() => {
    if (!coworkingId) {
      return
    }

    const requests = [
      getCoworkingData(coworkingId).then((coworking) => {
        setCoworking(coworking)
      }).catch(() => {
        setCoworking(null)
      }),
      getCoworkingAddress(coworkingId).then((address) => {
        setAddress(address)
      }).catch(() => {
        setAddress(null)
      }),
      getCoworkingTimetable(coworkingId).then((timetable) => {
        setTimetable(timetable)
      }).catch(() => {
        setTimetable(null)
      }),
      getCoworkingReceptionTimetable(coworkingId).then((reception_timetable) => {
        setReceptionTimetable(reception_timetable)
      }).catch(() => {
        setReceptionTimetable(null)
      }),
      getCoworkingAmenities(coworkingId).then((amenities) => {
        setCoworkingAmenities(amenities)
      }).catch(() => {
        setCoworkingAmenities([])
      }),
      getCoworkingTariffs(coworkingId).then((spaces) => {
        setSpaces(spaces)
      }).catch(() => {
        setSpaces({})
      }),
      getCoworkingPhotos(coworkingId).then((gallery) => {
        setGallery(gallery)
      }).catch(() => {
        setGallery([])
      }),
      getSeatingConfigs().then((seatingConfigs) => {
        setSeatingConfigs(seatingConfigs)
      }).catch(() => {
        setSeatingConfigs([])
      }),
    ]
    Promise.all(requests).then(() => {
      setIsLoading(false)
    })
  }, [coworkingId])

  useEffect(() => {
    getCrms().then((crmList) => {
      setCrmList(crmList)
    }).catch(() => {
      setCrmList([])
    })

    getAmenities().then((amenitiesList) => {
      setPossibleAmenities(amenitiesList)
    }).catch(() => {
      setPossibleAmenities([])
    })

    getReservationUnits().then((reservationUnits) => {
      setReservationUnits(reservationUnits)
    }).catch(() => {
      setReservationUnits([])
    })
  }, [])

  const currentStep = useMemo(() => {
    const split = location.pathname.split('/').pop()
    for (let item in Object.values(ICoworkingStep)) {
      const v = Number(item)
      if (split === getCoworkingUrlByStep(v)) {
        return v
      }
    }
    return ICoworkingStep.General
  }, [location.pathname])

  const maxStep = useMemo(() => {
    if (!coworking || !coworking.title || typeof coworking.title !== "object" || Object.keys(coworking.title).length === 0) {
      return ICoworkingStep.General
    }
    if (!address) {
      return ICoworkingStep.Contacts
    }
    if (!timetable) {
      return ICoworkingStep.Scheduler
    }
    if (!reception_timetable) {
      return ICoworkingStep.Scheduler
    }
    if (coworkingAmenities.length === 0) {
      return ICoworkingStep.Amenities
    }
    if (Object.keys(spaces).length === 0 || coworking.area === 0) {
      return ICoworkingStep.Tariffs
    }
    if (gallery.length < 5) {
      return ICoworkingStep.Gallery
    }
    return ICoworkingStep.ActivationPage
  }, [coworking, address, timetable, reception_timetable, coworkingAmenities, spaces, gallery])

  const showErrorNotification = (e: Error) => {
    console.error(e)
    notification.error({
      message: t('errors.request_failed.text'),
    });
    throw e
  }


  const createCoworkingAddress = (newCoworkingData: { email: string; phone_number: string; subway: ISubway[] }, manager: { phone_number: string; email: string; name: string }, newAddress?: ICreateAddress) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let cityId = address ? address.city.id : 1
        let addressResponse
        if (newAddress) {
          addressResponse = await updateAddress(coworkingId!, newAddress)
          cityId = addressResponse.city.id
        }
        let subway = undefined
        if (newCoworkingData.subway.length > 0 && newCoworkingData.subway[0].line) {
          subway = newCoworkingData.subway.map(s => ({
            ...s,
            cities: [cityId]
          }))
        }
        const body: ICreateCoworking = {
          coworking_email: newCoworkingData.email,
          phone_number: setLocalOrEverything(coworking ? coworking.phone_number : null, newCoworkingData.phone_number),
          subway,
          managers: [
            {
              name: {description: manager.name},
              phone: manager.phone_number,
              email: manager.email,
            },
          ],
        };
        const coworkingResponse = await sendCreateOrUpdateCoworking(coworkingId!, body)
        setCoworking(coworkingResponse)
        if (addressResponse) {
          setAddress(addressResponse)
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  };

  const createCoworkingSchedule = async (timetable: ICreateTimetable) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        let responseTimetable, responseReception;
        const body = {
          is_24x7: timetable?.is_24x7,
          monday:
            {
              end: timetable?.monday.end,
              start: timetable?.monday.start,
              available: timetable?.monday.available,
            },
          tuesday:
            {
              end: timetable?.tuesday.end,
              start: timetable?.tuesday.start,
              available: timetable?.tuesday.available,
            },
          wednesday:
            {
              end: timetable?.wednesday.end,
              start: timetable?.wednesday.start,
              available: timetable?.wednesday.available,
            },
          thursday:
            {
              end: timetable?.thursday.end,
              start: timetable?.thursday.start,
              available: timetable?.thursday.available,
            },
          friday:
            {
              end: timetable?.friday.end,
              start: timetable?.friday.start,
              available: timetable?.friday.available,
            },
          saturday:
            {
              end: timetable.saturday.end,
              start: timetable.saturday.start,
              available: timetable.saturday.available,
            },
          sunday:
            {
              end: timetable.sunday.end,
              start: timetable.sunday.start,
              available: timetable.sunday.available,
            }
        }

        if (timetable.is_24x7) {
          responseTimetable = await postRequest(`/smp_api/coworking/${coworkingId}/timetable/`, data24).then(throwErrorIfFailed)
          responseReception = await postRequest(`/smp_api/coworking/${coworkingId}/reception_timetable/`, body).then(throwErrorIfFailed)
        } else {
          responseTimetable = await postRequest(`/smp_api/coworking/${coworkingId}/timetable/`, body).then(throwErrorIfFailed)
          responseReception = await postRequest(`/smp_api/coworking/${coworkingId}/reception_timetable/`, body).then(throwErrorIfFailed)
        }
        setTimetable(await responseTimetable);
        setReceptionTimetable(await responseReception);
        if (!isActivated) {
          changeStep(ICoworkingStep.Amenities);
        }
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  }

  const createCoworkingAmenities = (amenities: number[], newAmenities: string[]) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        if (newAmenities.length > 0) {
          const payload = newAmenities.map(a => ({
            title: setLocalOrEverything(null, a),
            abbreviation: setLocalOrEverything(null, a)
          }))
          const response: { id: number }[] = await postRequest(`/smp_api/amenity/batch_create/`, payload);
          amenities = amenities.concat(response.map(r => r.id))
        }
        const amenitiesResponse = await postRequest(`/smp_api/coworking/${coworkingId}/amenities/`, {amenities: amenities});
        setCoworkingAmenities(amenitiesResponse)
        await getAmenities().then((amenitiesList) => {
          setPossibleAmenities(amenitiesList)
        })
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  }

  const createGallery = (newFiles: INewFile[], oldFiles: IOldFile[]) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const oldFilesRequests = oldFiles.map(file => {
          return putRequest(`/smp_api/storage/${file.uid}/`, {
            file_id: file.file_id,
            order: file.order,
            description: JSON.stringify(file.description || {en: ''}),
          })
        })

        const newFilesRequests = newFiles.map(file => {
          const formdata = new FormData();
          formdata.append('file', file.file);
          formdata.append("id", file.id.toString());
          formdata.append("order", file.order.toString());
          formdata.append("description", JSON.stringify(file.description || {en: ''}));
          return formDataRequest(`/smp_api/storage/`, formdata)
        }).map(async request => {
          const data = await request;
          if (data.isError) {
            return
          }
          await postRequest(`/smp_api/coworking/${coworkingId}/photos/`, {file_id: data.file_id});
          return data
        })

        await Promise.all([...newFilesRequests, ...oldFilesRequests])
        await getCoworkingPhotos(coworkingId!).then((gallery) => {
          setGallery(gallery)
        })
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  }

  const deletePhoto = (id: number) => {
    return deleteRequest(`/smp_api/coworking/${coworkingId}/photos/`, {file_id: id}).catch(showErrorNotification)
  }

  const createDocs = (offer: RcFile | string | null, rules: RcFile | string | null, plan: RcFile | string | null) => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const formdata = new FormData();
        if (rules) {
          formdata.append('document_rules', rules)
        }
        if (offer) {
          formdata.append('document_offer', offer)
        }
        if (plan) {
          formdata.append('plan', plan)
        }
        const response = await formDataRequest(`/smp_api/coworking/${coworkingId}/`, formdata, 'PATCH')
        setCoworking(response)
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  }

  const createTarif = async (tarif: INewTarif, images: IUpload): Promise<{ coworkSpaceResponse: any, availableSpaceResponse: any }> => {
    return new Promise(async (resolve, reject) => {
      try {
        if (!tarif.id || !tarif.available_space_id) {
          const coworkSpaceResponse = await postRequest(`/smp_api/coworking/${coworkingId}/spaces/`, {
            title: tarif.title,
            description: tarif.description,
            space_type_id: tarif.space_type_id,
            area: tarif.area,
            capacity: tarif.capacity,
          }).then(throwErrorIfFailed);

          const availableSpaceResponse = await postRequest(`/smp_api/coworking/${coworkingId}/available_spaces/`, {
            cowork_space: coworkSpaceResponse.id,
            price_per_unit: tarif.price_per_unit,
            reservation_unit: tarif.reservation_unit,
            cancel_booking_timeout: tarif.cancel_booking_timeout,
            is_trial: tarif.is_trial,
            seating_config: tarif.seating_config,
            can_be_sold: tarif.can_be_sold,
          }).then(throwErrorIfFailed);

          const mapRequest: any = []
          for (let i in images.images?.fileList) {
            if (!isNumber(images.images.fileList[i].uid)) {
              const formdata = new FormData();
              formdata.append('file', images.images.fileList[i].originFileObj!);
              formdata.append("id", coworkingId!.toString());
              formdata.append("space_id", coworkSpaceResponse.id);
              formdata.append("order", i);
              // @ts-ignore
              formdata.append("description", JSON.stringify(images?.[images.images.fileList[i].uid] || {en: ''}));
              mapRequest.push(await formDataRequest(`/smp_api/storage/`, formdata))
            } else {
              mapRequest.push({file_id: images.images.fileList[i].uid, file: images.images.fileList[i].thumbUrl});
            }
          }

          const imagesResponce = await Promise.all(mapRequest.map(async (task: any) => {
            const data = await task;
            if (data.isError) {
              throw new Error();
            }
            return data;
          }));

          coworkSpaceResponse.images = {images: {}}
          coworkSpaceResponse.images.images.fileList = imagesResponce.map(((i: any) => {
            postRequest(`/smp_api/space/${coworkSpaceResponse.id}/photos/`, {file_id: i.file_id}).then(throwErrorIfFailed)
            coworkSpaceResponse.images[i.file_id] = typeof (i.description) !== 'string' ? i.description : JSON.parse(i.description);
            return {
              url: i?.file,
              uid: i?.file_id,
              thumbUrl: i?.file,
              type: 'image/png',
            }
          }))

          coworkSpaceResponse.description = {
            en: textInDescription(coworkSpaceResponse.description.en),
            ru: textInDescription(coworkSpaceResponse.description.ru)
          }

          coworkSpaceResponse.area = Math.floor(coworkSpaceResponse.area)
          availableSpaceResponse.price_per_unit = (availableSpaceResponse.price_per_unit).toString()
          availableSpaceResponse.seating_config = [availableSpaceResponse.seating_config?.id]
          availableSpaceResponse.reservation_unit = [availableSpaceResponse.reservation_unit]
          availableSpaceResponse.cancel_booking_timeout = [availableSpaceResponse.cancel_booking_timeout]
          coworkSpaceResponse.available_space = [availableSpaceResponse]

          getCoworkingTariffs(coworkingId!).then((spaces) => {
            setSpaces(spaces)
          }).catch(() => {
            setSpaces({})
          })
          resolve({
            coworkSpaceResponse,
            availableSpaceResponse
          })
        } else {
          const mapRequestCreate: any = []
          const mapRequestUpdate: any = []
          for (let i in images?.images?.fileList) {
            if (!isNumber(images.images.fileList[i].uid)) {
              const formdata = new FormData();
              formdata.append('file', images.images.fileList[i].originFileObj!);
              formdata.append("id", coworkingId!.toString());
              formdata.append("space_id", tarif.id!.toString());
              formdata.append("order", i);
              // @ts-ignore
              formdata.append("description", JSON.stringify(images?.[images.images.fileList[i].uid] || {en: ''}));
              mapRequestCreate.push(formDataRequest(`/smp_api/storage/`, formdata))
            } else {
              mapRequestUpdate.push(putRequest(`/smp_api/storage/${images.images.fileList[i].uid}/`, {
                // @ts-ignore
                description: JSON.stringify(images?.[images.images.fileList[i].uid] || {en: ''}),
                order: +i,
                file_id: images.images.fileList[i].uid,
              }).then(throwErrorIfFailed))
            }
          }

          const coworkSpaceResponse = await putRequest(`/smp_api/coworking/${coworkingId}/spaces/`, {
            id: tarif.id,
            title: tarif.title,
            description: tarif.description,
            space_type_id: tarif.space_type_id,
            area: tarif.area,
            capacity: tarif.capacity,
          }).then(throwErrorIfFailed);
          const availableSpaceResponse = await putRequest(`/smp_api/coworking/${coworkingId}/available_spaces/`, {
            id: tarif.available_space_id,
            price_per_unit: tarif.price_per_unit,
            reservation_unit: tarif.reservation_unit,
            cancel_booking_timeout: tarif.cancel_booking_timeout,
            is_trial: tarif.is_trial,
            seating_config: tarif.seating_config,
            can_be_sold: tarif.can_be_sold,
          }).then(throwErrorIfFailed);

          const imagesResponceCreate = await Promise.all(mapRequestCreate.map(async (task: any) => {
            const data = await task;
            if (data.isError) {
              throw new Error();
            }
            postRequest(`/smp_api/space/${coworkSpaceResponse.id}/photos/`, {file_id: data.file_id}).then(throwErrorIfFailed)
            return data;
          }));

          const imagesResponceUpdate = await Promise.all(mapRequestUpdate.map(async (task: any) => {
            return await task;
          }));

          coworkSpaceResponse.images = {images: {}}
          coworkSpaceResponse.images.images.fileList = [...imagesResponceUpdate, ...imagesResponceCreate].map(((i: any) => {
            coworkSpaceResponse.images[i.file_id] = typeof (i.description) !== 'string' ? i.description : JSON.parse(i.description);
            return {
              url: i?.file,
              uid: i?.file_id,
              thumbUrl: i?.file,
              type: 'image/png',
            }
          }))

          coworkSpaceResponse.description = {
            en: textInDescription(coworkSpaceResponse.description.en),
            ru: textInDescription(coworkSpaceResponse.description.ru)
          }

          coworkSpaceResponse.area = Math.floor(coworkSpaceResponse.area)
          availableSpaceResponse.price_per_unit = (availableSpaceResponse.price_per_unit).toString()
          availableSpaceResponse.reservation_unit = [availableSpaceResponse.reservation_unit]
          availableSpaceResponse.cancel_booking_timeout = [availableSpaceResponse.cancel_booking_timeout]
          availableSpaceResponse.seating_config = [availableSpaceResponse?.seating_config?.id]
          coworkSpaceResponse.available_space = [availableSpaceResponse]

          getCoworkingTariffs(coworkingId!).then((spaces) => {
            setSpaces(spaces)
          }).catch(() => {
            setSpaces({})
          })
          resolve({
            coworkSpaceResponse,
            availableSpaceResponse
          })
        }
      } catch (e) {
        reject()
      }
    })
  }

  const deleteTarif = (space_id: number) => {
    return deleteRequest(`/smp_api/coworking/${coworkingId}/spaces/`, {space_id}).then(() => {
      getCoworkingTariffs(coworkingId!).then((spaces) => {
        setSpaces(spaces)
      }).catch(() => {
        setSpaces({})
      })
    });
  }

  const deleteTarifPhoto = (space_id: number, file_id: number) => {
    return deleteRequest(`/smp_api/space/${space_id}/photos/`, {file_id});
  }

  const updateCoworkingAreas = (coworking: ICoworkingForm) => {
    return putRequest(`/smp_api/coworking/${coworkingId}/`, coworking).then(throwErrorIfFailed).then(coworking => {
      setCoworking(coworking)
    });
  }

  const coworkingManager = useMemo(() => {
    if (!coworking || coworking.managers.length === 0) {
      return null
    }
    return {
      name: coworking.managers[0].name,
      phone_number: coworking.managers[0].phone,
      email: coworking.managers[0].email
    }
  }, [coworking])

  const requestCoworkingActivation = async () => {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await postRequest(`/smp_api/coworking/${coworkingId}/finish/`, {
          callback_url: 'icowork.pro/homepage/usercoworkings',
          error_url: 'icowork.pro/homepage/usercoworkings',
        }).then(throwErrorIfFailed);
        history.push('/list');
        resolve()
      } catch (e) {
        reject(e)
      }
    }).catch(showErrorNotification)
  }

  const createCoworkingAndGoToContacts = (title: I18NLangString, description: I18NLangString, crm: string[], website: string) => {
    return createOrUpdateCoworking(title, description, crm, website).then((coworking) => {
      history.push(`/coworking/${coworking.id}/${getCoworkingUrlByStep(ICoworkingStep.Contacts)}`);
    })
  }

  const createOrUpdateCoworking = (title: I18NLangString, description: I18NLangString, crm: string[], website: string) => {
    return createOrUpdateCoworkingGeneralData(
      coworkingId, {title, description, website}, {newCrmTitles: crm, existingCrmList: crmList}, {
        first_name: userData.first_name,
        last_name: userData.last_name,
        email: userData.email,
        phone: userData.phone,
      }).then((coworking) => {
      setCoworkingId(coworking.id)
      getCoworkingData(coworking.id).then(coworking => {
        setCoworking(coworking)
      })
      getCrms().then((crmList) => {
        setCrmList(crmList)
      })
      return coworking
    })
  }

  if (isLoading) {
    return null
  }

  return (
    <>
      <Steppers
        currentStep={currentStep}
        maxStep={maxStep}
        onChange={changeStep}
      />

      <WrapperContainerStyled>
        <Switch>
          <Route path={`/coworking/${getCoworkingUrlByStep(ICoworkingStep.General)}`}>
            <General
              title={null}
              description={null}
              crm={null}
              website={null}
              isActivated={isActivated}
              crmList={crmList}
              onChange={createCoworkingAndGoToContacts}
              onNextPageRequest={() => {}}
              isLastStep={false}
            />
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.General)}`}>
            <General
              title={coworking ? coworking.title : null}
              description={coworking ? coworking.description : null}
              crm={coworking ? coworking.crm : null}
              website={coworking ? coworking.web_site : null}
              isActivated={isActivated}
              crmList={crmList}
              onChange={createCoworkingAndGoToContacts}
              onNextPageRequest={() => changeStep(ICoworkingStep.Contacts)}
              isLastStep={false}
            />
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Contacts)}`}>
            {coworking && (
              <Contacts
                coworking={{
                  email: coworking.coworking_email,
                  phone_number: coworking.phone_number,
                  subways: coworking.subway
                }}
                manager={coworkingManager}
                address={address}
                isActivated={isActivated}
                onChange={createCoworkingAddress}
                onNextPageRequest={() => changeStep(ICoworkingStep.Scheduler)}
                isLastStep={maxStep === ICoworkingStep.Contacts}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Scheduler)}`}>
            {coworking && address && (
              <Scheduler
                timetable={timetable}
                receptionTimetable={reception_timetable}
                firstDayOfWeek={address.country.first_day_of_week}
                isActivated={isActivated}
                onChange={createCoworkingSchedule}
                isLastStep={maxStep === ICoworkingStep.Scheduler}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Amenities)}`}>
            {coworking && (
              <Amenities
                possibleAmenities={possibleAmenities}
                coworkingAmenities={coworkingAmenities}
                isActivated={isActivated}
                onChange={createCoworkingAmenities}
                onNextPageRequest={() => changeStep(ICoworkingStep.Tariffs)}
                isLastStep={maxStep === ICoworkingStep.Amenities}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Tariffs)}`}>
            {coworking && address && (
              <Tariffs
                coworking={coworking}
                tariffs={spaces}
                reservationUnits={reservationUnits}
                seatingConfigs={seatingConfigs}
                onTarifCreate={createTarif}
                onTarifDelete={deleteTarif}
                onTarifPhotoDelete={deleteTarifPhoto}
                isActivated={isActivated}
                onChange={updateCoworkingAreas}
                currency={address.country.official_currency}
                onNextPageRequest={() => changeStep(ICoworkingStep.Gallery)}
                isLastStep={maxStep === ICoworkingStep.Tariffs}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Gallery)}`}>
            {coworking && (
              <Gallery
                photos={gallery}
                onPhotoDelete={deletePhoto}
                isActivated={isActivated}
                onChange={createGallery}
                coworkingName={getLocalOrEn(coworking.title)!}
                onNextPageRequest={() => changeStep(ICoworkingStep.Docs)}
                isLastStep={maxStep === ICoworkingStep.Gallery}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.Docs)}`}>
            {coworking && (
              <Docs
                coworking={coworking}
                isActivated={isActivated}
                onChange={createDocs}
                onNextPageRequest={() => changeStep(ICoworkingStep.ActivationPage)}
              />
            )}
          </Route>
          <Route path={`${match.path}/${getCoworkingUrlByStep(ICoworkingStep.ActivationPage)}`}>
            {coworking && address && timetable && reception_timetable && (
              <Activation
                coworking={coworking}
                tariffs={spaces}
                photos={gallery}
                address={address}
                coworkingServices={coworkingAmenities}
                possibleServices={possibleAmenities}
                timetable={timetable}
                receptionTimetable={reception_timetable}
                reservationUnits={reservationUnits}
                isActivated={isActivated}
                onActivationRequest={requestCoworkingActivation}
              />
            )}
          </Route>
        </Switch>
        <Prompts step={currentStep}/>
      </WrapperContainerStyled>
    </>
  )
}
