import { Input } from 'antd';
import styled from 'styled-components';
import size from './icons/size.svg';

export const CustomInputStyled = styled(Input)`
  height: 40px;
  font-size: 16px;
  background: none;
  caret-color: black !important;
  background-color: white !important;
`;

export const WrapperPlaceholderStyled = styled.div`
  width: 100%;
  height: 40px;
  position: absolute;
  top: 0;
  font-size: 16px;
  line-height: 41px;
  padding-left: 12px;
  letter-spacing: 0px;
  text-align: left;
  color: black !important;
`;

export const WrapperSizeInput = styled.div`
  position: relative;
  &:before{
    content: '';
    display: block;
    position: absolute;
    right: 11px;
    top: 11px;
    width: 18px;
    height: 18px;
    z-index: 2;
    background-image: url(${size});
    background-size: cover;
    background-position: center;
  }
  .ant-form-item-label {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  .ant-form-item-required {
    width: 100%;
    height: 100%;
  }
  label::before,
  label::after {
    content: none !important;
    display: none !important;
  }
  label{
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`;

export const ChoiceStyled = styled.div`
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  height: auto;
  padding-left: 4px;
  & div {
    height: 20px;
    line-height: 24px !important;
    color: grey;
  }
`
