import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import {CheckboxListStyled} from './styles';

interface IListCheckbox {
  name: string;
  options: any;
  required: boolean;
  normalize?: any;
  value?: string;
}

export function ListCheckboxInput(props: IListCheckbox) {
  const [t] = useTranslation();
  return (
    <Form.Item
      style={{marginTop: 10}}
      name={props.name}
      rules={[{
        required: props.required,
        message: t('required_field')
      }]}
      normalize={props.normalize}>
      <CheckboxListStyled options={props.options}/>
    </Form.Item>
  );
}
