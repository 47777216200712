/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select, Divider, Input, Form, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import { AddItemButtomStyled, WrappeSelectStyled } from './styles';
import { isNumber } from '../../../utils/isNumber';
import { useTranslation } from 'react-i18next';
import { isEmpty, map } from 'lodash';

const { Option } = Select;

let index = 0;

interface ISelect {
	name: any;//string | Array<any>;
	required: boolean;
	multiple: boolean;
	data: any;
	nameParent?: any;
	form: any;
	onChange?: any;
	errorWhenSpace?: string;
	errorWhenNumber?: string;
	errorWhenAlreadyHaveThis?: string;
	userCRM?: any;
}

export const SelectCRMInput: FC<ISelect> = (props) => {
	const [items, setItems] = useState(props.data || []);
	const [name, setName] = useState('');
	const { t } = useTranslation();
	const children = !isEmpty(items) ? map(items, item => (
		<Option value={item.title} key={item.value}>{item.title}</Option>
	)) : null
	document.getElementById("inputCRM")?.addEventListener("keydown", e => {
		if (e.keyCode === 8) {
			e.stopPropagation()
		}
	})
	const addItem = () => {
		if (!isNumber(name.trim())) {
			if (name.trim() !== '') {
				if (!(items.some((item: { title: string; }) => item.title === name || item.title.toString() === name))) {
					const nameField = props.nameParent ? [...[props.nameParent], ...props.name] : props.name;
					setItems([...items, { title: name || `New item ${index++}`, value: name || `New item ${index++}` }])
					props.form.setFields([
						{
							name: [...props.name, 'addCustomValue'],
							value: '',
						},
					]);
					if (props.multiple) {
						props.form.setFields([
							{
								name: nameField,
								value: [...props.form.getFieldValue(nameField), ...[name || `New item ${index++}`]],
								errors: null,
							},
						]);
					} else {
						props.form.setFields([
							{
								name: nameField,
								value: [name || `New item ${index++}`],
								errors: null,
							},
						]);
					}
					if (props?.onChange) {
						props?.onChange()
					}
				}
				else {
					setName('')
					message.error(props.errorWhenAlreadyHaveThis)
				}
			}
			else {
				message.error(props?.errorWhenSpace)
				setName('')
			}
		}
		else {
			message.error(props?.errorWhenNumber)
			setName('')
		}
		setName('')
	};
	return (
		<WrappeSelectStyled>
			<Form.Item name={props.name} initialValue={props.userCRM ? props.userCRM : []} rules={
				[
					{
						required: props.required,
						message: t('required_field'),
					},
				]}>
				<Select
					allowClear
					mode={props.multiple ? 'multiple' : undefined}
					style={{ width: '100%' }}
					placeholder=""
					onChange={props.onChange}
					dropdownRender={(items) => (
						<div>
							{items}
							<Divider style={{ margin: '4px 0' }} />
							<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
								<Form.Item name={[...props.name, 'addCustomValue']} noStyle>
									<Input id='inputCRM' style={{ flex: 'auto' }} value={name} onPressEnter={(e) => {
										e.stopPropagation()
										addItem()
									}} onChange={(e) => {
										e.stopPropagation()
										setName(e.target.value)
									}} />
								</Form.Item>
								<AddItemButtomStyled name={name} onClick={addItem}>
									<PlusOutlined /> {t("add_item")}
								</AddItemButtomStyled>
							</div>
						</div>
					)}
				>
					{children}
				</Select>
			</Form.Item>
		</WrappeSelectStyled>
	);
}
