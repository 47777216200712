/* eslint-disable jsx-a11y/anchor-is-valid */
import { Select, Divider, Input, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { FC, useState } from 'react';
import { AddItemButtomStyled, WrappeSelectStyled } from './styles';
import { isNumber } from '../../../utils/isNumber';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

let index = 0;

interface ISelect {
  name: any;
  required: boolean;
  multiple: boolean;
  data: any;
  nameParent?: any;
  form: any;
  onChange?: any;
  errorWhenAlreadyHaveThis?: any;
  errorWhenSpace?: any;
  errorWhenNumber?: any;
}

export const SelectAddInput: FC<ISelect> = (props) => {
  const [items, setItems] = useState(props.data || []);
  const [name, setName] = useState('');
  const { t } = useTranslation();
  document.getElementById("input")?.addEventListener("keydown", e => {
		if (e.keyCode === 8) {
			e.stopPropagation()
		}
	})
  const addItem = () => {
    if (!isNumber(name)) {
      if (name) {
        const nameField = props.nameParent ? [...[props.nameParent], ...props.name] : props.name;
        setItems([...items, { title: name || `New item ${index++}`, value: name || `New item ${index++}` }])
        props.form.setFields([
          {
            name: [...props.name, 'addCustomValue'],
            value: '',
          },
        ]);
        if (props.multiple) {
          props.form.setFields([
            {
              name: nameField,
              value: [...props.form.getFieldValue(nameField), ...[name || `New item ${index++}`]],
              errors: null,
            },
          ]);
        } else {
          props.form.setFields([
            {
              name: nameField,
              value: [name || `New item ${index++}`],
              errors: null,
            },
          ]);
        }
        if (props?.onChange) {
          props?.onChange()
        }
      }
    }
    setName('')
  };
  return (
    <WrappeSelectStyled>
      <Form.Item name={props.name} initialValue={[]} rules={
        [
          {
            required: props.required,
            message: t('required_field'),
          },
        ]}>
        <Select
          mode={props.multiple ? 'multiple' : undefined}
          style={{ width: '100%' }}
          placeholder=""
          onChange={props.onChange}
          dropdownRender={(menu) => (
            <div>
              {menu}
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                {/* <Form.Item name={[...props.name, 'addCustomValue']} noStyle> */}
                <Input id='input' style={{ flex: 'auto' }} value={name} onPressEnter={(e) => {
										e.stopPropagation()
										addItem()
									}} onChange={(e) => {
										e.stopPropagation()
										setName(e.target.value)}} />
                {/* </Form.Item> */}
                <AddItemButtomStyled name={name} onClick={addItem}>
                  <PlusOutlined /> {t("add_item")}
                </AddItemButtomStyled>
              </div>
            </div>
          )}
        >
          {items.map((item: any) => (
            <Option value={item.value} key={item.value}>{item.title}</Option>
          ))}
        </Select>
      </Form.Item>
    </WrappeSelectStyled>
  );
}
