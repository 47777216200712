import { CalendarOutlined, CaretDownOutlined } from "@ant-design/icons";
import { Carousel, Col } from "antd";
import styled from "styled-components";
import { DynamicButton } from "../../../components/inputs/dinamicButton";
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR } from "../../../style/globalStyle/Color";
import filter from './icons/filter.svg';
import checkbox from './icons/checkbox.svg';

export const TitleItemStyled = styled.div`
  float: left;
  &::after{
    content: ':';
  }
  font-size: 16px;
`

export const ValueItemStyled = styled.div`
  margin-left: 11px !important;
  float: left;
  font-size: 13px;
  line-height: 27px;
  font-style: italic;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 50%;
`

export const AvatarStyled = styled.div`
  position: absolute;
  left: 10px;
  top: 10px;
  height: calc(100% - 20px);
  width: 70px;
  border-radius: 40px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  background-size: cover;
  background-position: center;
`

export const NameStyled = styled.div`
  position: absolute;
  top: 5px;
  width: calc(100%);
  text-align: center;
`

export const ButtonStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  width: calc(100% - 20px);
  text-align: center;
  height: 40px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  padding-left: 15px;
  padding-right: 15px;
  line-height: 38px;
  border-radius: 30px;
  cursor: pointer;
`

export const PriceStyled = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
  height: 30px;
`


export const WrapperImagesStyled = styled.div`
  height: 400px;
  width: 100%;
  background-size: cover;
  background-position: center;
`

export const WrapperStepStyled = styled.div`
  width: auto !important;
  height: auto !important;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  position: relative;
  border-bottom: none;
  min-height: 120px;
`

export const WrapperDynamicButton = styled(DynamicButton)`
  button{
    width: 520px !important;
    max-width: 100%;
  }
`

export const WrapperPromptStyled = styled.div`
  position: absolute;
  left: 100%;
  top: -1px;
  width: calc(50vw - 350px);
  height: calc(100% + 2px);
  max-width: 400px;
  background-color: rgba(0,0,0,0.0.01);
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-left: none;
  padding: 5px;
`

export const WrapperCaruselStyled = styled(Carousel)`
  clear: both;
  width: calc(100% - 30px);
  height: 400;
  .slick-dots-right{
    right: -20px;

    button{
      background-color: grey !important;
    }
  }
`

export const WrapperMapStyled = styled.div`
  width: 100%;
  height: 720px;
  background-color: grey;
  clear: both;
  @media (min-width: 318px) and (max-width: 991px) {
    height: 360px;
  }
`;

export const CustomWrapperContentStyled = styled.div`
  /* width: 650px; */
  padding-left: 2px;
  padding-right: 5px;
  padding-bottom: 10px;
  overflow-x: hidden;
  position: absolute;
  width: calc(100% - 150px);
  background-color: white;
  z-index: 3 !important;
  @media (min-width: 318px) and (max-width: 576px) {
    padding-left: 10px;
    padding-right: 10px;
    margin: auto !important;
  }
`

export const WrapperTextStyled = styled.div`
  background: ${MAIN_BORDER_COLOR};
  padding-left: 23%;
  padding-right: 23%;
  text-align: center;
  padding-top: 32px;
  padding-bottom: 32px;
  text-align: center;
  border-radius: 8px;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  @media (min-width: 318px) and (max-width: 991px) {
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 10%;
    padding-right: 10%;
  }
`

export const AddressStyled = styled.div`

`
export const MetroStyled = styled.div`
  float: left;
  &:before{
    display: block;
    content: '';
    width: 16px;
    height: 16px;
    background: #B7CE56;
    border-radius: 50%;
    float: left;
    margin-top: 3px;
    margin-right: 5px;
  }
`

export const DistanceStyled = styled.div`
  display: inline-block;
  color: ${MAIN_BLUE_COLOR};
`

export const SharedStyled = styled.div`
  color: ${MAIN_BLUE_COLOR};
  cursor: pointer;
`

export const SaveStyled = styled.div`
  color: ${MAIN_BLUE_COLOR};
  margin-left: 40px;
  cursor: pointer;
`

export const WrapperImagesGaleryStyled = styled.div`
  height: 400px;
  width: 100%;
  background-color: grey;
  margin-top: 20px;
  margin-bottom: 20px;
`

export const FilterStyled = styled.div`
  background-image: url(${filter});
  background-size: cover;
  background-position: center;
  height: 24px;
  width: 24px;
  padding: 8px;
`

export const WrapperFilterStyled = styled.div`
  width: 40px;
  height: 40px;
  padding: 8px;
  float: right;
  border: 1px solid #9DADD0;
  border-radius: 8px;
`

export const WrapperDateStyled = styled.div`
  float: right;
  border: 1px solid #9DADD0;
  border-radius: 8px;
  margin-right: 16px;
  border-radius: 50px;
  display: flex;
  @media (min-width: 318px) and (max-width: 991px) {
    margin-top: 10px;
    float: none;
    height: 80px;
    border-radius: 24px;
    margin-right: 0;
    display: block;
    max-width: 350px;
    margin-left: auto;
    margin-right: auto;
  }
`

export const DateStyled = styled.div`
  width: 190px;
  height: 40px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: start;
  &:first-child{
    border-right: 1px solid #9DADD0;
  }
  @media (min-width: 318px) and (max-width: 991px) {
    float: left;
    width: 100%;
    padding-left: 5px;
    padding-right: 5px;
    &:first-child{
      border-right: none;
      border-bottom: 1px solid #9DADD0;
    }
  }
  /* @media (min-width: 318px) and (max-width: 991px) {
    display: block;
    width: 100%;
  } */
`

export const DateIconStyled = styled(CalendarOutlined)`
  margin-left: 10px;
  margin-right: 10px;
  height: 14px;
  width: 18px;
  color: #9DADD0;
`

export const ViewIconStyled = styled(CaretDownOutlined)`
  position: absolute;
  right: 28px;
  top: 14px;
  height: 4px;
  width: 7px;
  color: #9DADD0;
`

export const TextDateStyled = styled.div`
  color: #9DA3AA;
  font-size: 16px;
`

export const CustomCheckBoxStyled = styled.div`
  float: right;
  margin-right: 24px;
  margin-top: 8px;
  &:before{
    content: '';
    width: 20px;
    height: 20px;
    display: block;
    background-image: url(${checkbox});
    background-size: cover;
    background-position: center;
    float: left;
    margin-right: 8px;
  }
  @media (min-width: 318px) and (max-width: 991px) {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export const WrapperChangeStyled = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 55px;
  margin-bottom: 0px;
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  cursor: pointer;
  overflow: hidden;
`

export const ChoiceStyled = styled.div<any>`
  width: 25%;
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isActive ? `
    color: white;
    background-color: ${MAIN_BLUE_COLOR};
  `:``}
  `

export const TextAboutCoworkingStyled = styled.div`
  position: relative;
  font-weight : 400;
  font-size: 16px;
  margin-top: 5px;
  &::before{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -16px;
    width: 100%;
    height: 1px;
    background-color: ${MAIN_BORDER_COLOR};
  }
  &::after{
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: -16px;
    width: 100%;
    height: 1px;
    background-color: ${MAIN_BORDER_COLOR};
  }
`

export const WrapperAmenytiesStyled = styled.div`
  padding-bottom: 10px;
`

export const AmenitiesItemStyled = styled.div`
  width: 25%;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-top: 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  @media (min-width: 318px) and (max-width: 991px) {
    width: 50%;
  }
  @media (min-width: 318px) and (max-width: 575px) {
    width: 100%;
  }
  .material-icons {
    color: ${MAIN_BLUE_COLOR};
    font-size: 32px;
  }
`

export const MoreAmenitiesButtonStyled = styled.div`
  border: 1px solid ${MAIN_BORDER_COLOR};
  clear: both;
  width: 214px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  cursor: pointer;
`


export const WrapperLinksStyled = styled(Col)`
  display: flex;
  justify-content: end;
  margin-top: 10px;
  @media (min-width: 318px) and (max-width: 991px) {
    justify-content: center;
  }
`

export const WrapperTariffDescriptionChoiceStyled = styled.div`
  margin-top: 32px;
  width: 100%;
  height: 55px;
  margin-bottom: 0px;
  border-radius: 8px;

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 55px;
  cursor: pointer;
  overflow: hidden;
  display: none;
  @media (min-width: 318px) and (max-width: 991px) {
    display: block;
  }
`

export const TariffDescriptionChoiceStyled  = styled.div<any>`
  width: calc(50% - 8px);
  height: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isActive ? `
    color: white;
    background-color: ${MAIN_BLUE_COLOR};
  `:``}
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 50px;
  &:first-child{
    margin-right: 16px;
  }
  `

export const WrapperTariffStyled = styled.div<{isActive: boolean}>`
  @media (min-width: 318px) and (max-width: 991px) {
    ${props => props.isActive ? ``:`
      display: none
    `}
  }
`

export const WrapperTextAboutCoworkingStyled = styled.div<{isActive: boolean}>`
  @media (min-width: 318px) and (max-width: 991px) {
    ${props => props.isActive ? ``:`
      display: none
    `}
  }
`

export const WrapperTariffListStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: start;
  @media (min-width: 318px) and (max-width: 991px) {
    justify-content: center;
  }
`

export const ToTheBottomArrowStyled = styled.div`
  width: 56px;
  height: 56px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 50%;
  margin-top: 32px;
  cursor: pointer;
  display: none;
  @media (min-width: 318px) and (max-width: 991px) {
    display: flex;
  }
`