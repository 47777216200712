import {Form} from "antd";
import {FormItemProps} from "antd/lib/form/FormItem";
import {useCallback, useEffect, useState} from 'react';
import {usePlacesWidget} from 'react-google-autocomplete';
import {CustomInputStyled} from './style';
import {getAddress} from './googleApi';

interface IGoogleAutoCompileInput extends FormItemProps {
  onSelect: any;
}

export const GoogleAutoCompileInput = ({onSelect, ...rest}: IGoogleAutoCompileInput) => {
  const value = Form.useWatch(rest.name!)
  const {ref: antRef}: any = usePlacesWidget({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    options: {
      types: ['geocode', 'establishment'],
    },
    onPlaceSelected: async (place) => {
      if (place.formatted_address) {
        await checkAddress(place.formatted_address);
      }
    },
  });

  const checkAddress = useCallback((address: string) => {
    return getAddress(address).then(result => onSelect(result));
  }, [onSelect])

  useEffect(() => {
    if (value) {
      checkAddress(value)
    }
    setV(value)
  }, [checkAddress, value])

  const [v, setV] = useState(value)
  return (
    <div style={{width: '100%'}}>
      <Form.Item
        noStyle
        {...rest}
      />
      <CustomInputStyled
        value={v}
        onChange={e => setV(e.target.value)}
        ref={c => {
          if (c) antRef.current = c.input;
        }}
        size="large"
      />
    </div>
  );
};
