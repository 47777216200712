import styled from 'styled-components';
import { MAIN_BORDER_COLOR } from '../../../style/globalStyle/Color';

export const WrappeSelectStyled = styled.div`
  height: 40px !important;
  margin-bottom: 20px;
  input {
    height: 32px !important;
  }
  .ant-select-selection-overflow{
    flex-wrap: nowrap;
  }
  .ant-select-selector {
    height: 40px !important;
    overflow: hidden;
  }
  .ant-select-single {
    .ant-select-selector {
      height: 40px !important;
    }
    .ant-select-selection-item {
      line-height: 40px !important;
    }
  }
  .ant-select-selector{
    border-radius: 4px !important;
  }
`;

export const AddItemButtomStyled = styled.a<{ name: string }>`
  flex: none;
  padding: 8px;
  display: block;
  cursor: pointer;
  ${props => !props.name ? `
    color: ${MAIN_BORDER_COLOR} !important;
    cursor: not-allowed;
  `: ``}
`
