import {Col, Form, Row} from 'antd';
import {FC, memo, useEffect} from 'react';
import {useCookies} from 'react-cookie';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {PasswordInput, PasswordInput2} from 'components/inputs/passwordInput';
import {PhoneInput} from 'components/inputs/phoneInput';
import {TextInput} from 'components/inputs/textInput';
import {normalizeName} from 'components/normilize/name';
import {updateUser, updateUserPassword, userDataSelector} from 'store/slices/auth';
import {CenterColumn} from 'style/colomn/centerColumn';
import {LeftColumn} from 'style/colomn/leftColumn';
import {RightColumn} from 'style/colomn/rightColumn';
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {MainCenterTitleStyle} from 'style/titles/mainCenterTitleStyle';
import {CustomDynamicButton} from './styles';

export const UpdateUser: FC = memo(() => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [, setCookie] = useCookies();
  const userData = useSelector(userDataSelector);

  const onFinish = (values: any) => {
    if (values.password) {
      dispatch(updateUserPassword({
        password: values.password,
        old_password: values.old_password,
        second_password: values.second_password,
        id: userData.id,
        email: userData.email,
      }, form))
    }
    delete values.password;
    delete values.old_password;
    delete values.second_password;
    dispatch(updateUser(values, form, setCookie));
  };

  useEffect(() => {
    form.validateFields()
  }, [form, userData])

  if (userData === null) {
    return null
  }
  return (
    <WrapperContentStyled className="content">
      <Form form={form} onFinish={onFinish} initialValues={userData}>
        <MainCenterTitleStyle style={{float: 'none'}}>{t('profile_settings')}</MainCenterTitleStyle>
        <Row>
          <LeftColumn span={24} sm={8}>
            <InputTitleStyled>{t('administrator_name')}</InputTitleStyled>
            <TextInput name={'first_name'} normalize={normalizeName} required={true}/>
          </LeftColumn>
          <CenterColumn span={24} sm={8}>
            <InputTitleStyled>{t('administrator_surname')}</InputTitleStyled>
            <TextInput name={'last_name'} normalize={normalizeName} required={true}/>
          </CenterColumn>
          <RightColumn span={24} sm={8}>
            <InputTitleStyled>{t('phone')}</InputTitleStyled>
            <PhoneInput name={'phone'} required={true}/>
          </RightColumn>
          <Col span={24}>
            <MainCenterTitleStyle style={{float: 'none'}}>{t('password')}</MainCenterTitleStyle>
          </Col>
          <LeftColumn span={24} sm={8}>
            <PasswordInput required={false} name={'old_password'} placeholder={t("enter_your_old_password")}/>
          </LeftColumn>
          <CenterColumn span={24} sm={8}>
            <PasswordInput required={false} name={'password'} placeholder={t("enter_a_new_password")}/>
          </CenterColumn>
          <RightColumn span={24} sm={8}>
            <PasswordInput2 required={false} name={'second_password'} placeholder={t("confirm_new_password")}/>
          </RightColumn>
        </Row>
        <CustomDynamicButton text={t('save')}/>
      </Form>
    </WrapperContentStyled>
  );
});
