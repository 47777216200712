import styled from 'styled-components';

export const MainCenterTitleStyle = styled.div`
  margin-bottom: 8px;
  margin-top: 20px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  float: left;
`;
