import {DynamicButton, IButton} from "components/inputs/dinamicButton";
import {useTranslation} from "react-i18next";

interface IProps extends IButton {
  isActivated: boolean
}

export function NextStepButton({isActivated, ...rest}: IProps) {
  const {t} = useTranslation();
  return <DynamicButton text={t(isActivated ? 'save' : 'next_step')} {...rest}/>
}
