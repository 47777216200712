import {RuleObject} from "antd/lib/form";
import {StoreValue} from "rc-field-form/lib/interface";

export const antRuleRequiredValidator = (rule: RuleObject, value: StoreValue) => {
  return new Promise<void>((resolve, reject) => {
    if (rule.required && !value) {
      reject();
    }
    resolve();
  })
};

export const antRuleMaxLengthValidator = (rule: RuleObject, value: StoreValue) => {
  return new Promise<void>((resolve, reject) => {
    if (value.length > 1000) {
      reject();
    }
    resolve();
  })
};