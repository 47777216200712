import {I18NLangString} from "pages/coworking/types";

export function getLangKey(): keyof I18NLangString {
  return localStorage.getItem('i18nextLng') as keyof I18NLangString || 'en'
}

export function getLocalOrEn<T>(content: Record<"ru" | "en", T> | null): T | undefined {
  if (!content) {
    return undefined
  }
  const key = getLangKey()
  if (!content.hasOwnProperty(key)) {
    return undefined
  }
  return content[key]
}

export function setLocalOrEverything(content: I18NLangString | null, value: string): I18NLangString {
  if (!content || typeof content !== 'object' || Object.keys(content).length === 0) {
    return {
      ru: value,
      en: value,
    }
  }
  return {
    ...content,
    [getLangKey()]: value,
  }
}
