import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export const StyledSpinner = styled(Spin)`
  /* margin-left: 24px; */
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
`;

export const CustomLoadingOutlined = styled(LoadingOutlined)`
  top: calc(50% - 22px);
  svg{
    fill: white;
  }
`
