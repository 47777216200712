import { Col } from "antd";
import styled from "styled-components";

interface ICenterColumn {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
  span?: number;
  distance?: number;
}

export const CenterColumn = styled(Col)<ICenterColumn>`
  ${props => (props.span && props.span === 24) ? `
    padding-right: 0px;
    padding-left: 0px;
  `:``}
  ${props => (props.span && props.span !== 24) ? `
    padding-right: 8px;
    padding-left: 8px;
  `:``}
  @media (min-width: 1600px){
    ${props => (props.xxl && props.xxl === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.xxl && props.xxl !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
  @media (min-width: 1200px){
    ${props => (props.xl && props.xl === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.xl && props.xl !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
  @media (min-width: 992px){
    ${props => (props.lg && props.lg === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.lg && props.lg !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
  @media (min-width: 768px){
    ${props => (props.md && props.md === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.md && props.md !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
  @media (min-width: 576px){
    ${props => (props.sm && props.sm === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.sm && props.sm !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
  @media (min-width: 480px){
    ${props => (props.xs && props.xs === 24) ? `
      padding-right: 0px;
      padding-left: 0px;
    `:``}
    ${props => (props.xs && props.xs !== 24) ? `
      padding-right: 8px;
      padding-left: 8px;
    `:``}
  }
`