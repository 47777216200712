import { CameraFilled } from "@ant-design/icons";
import { FC, memo, useState } from "react";
import { MAIN_BLUE_COLOR } from "../../style/globalStyle/Color";
import { ExitIconStyled, ImagesStyled, MoreImageButtonStyled, WrapperAllPhotosStyled, WrapperContentStyled, WrapperGaleryStyled, WrapperImagesStyled } from "./styles";

interface IGalery {
  urls: Array<string>;
}

export const Galery: FC<IGalery>= memo((props) => {
  const [isView, setIsView] = useState(false)
  return( 
  <WrapperGaleryStyled>
    <WrapperImagesStyled onClick={()=>{setIsView(true)}} style={{backgroundImage: `url(${props.urls?.[0]})`, width: '50%', height: '100%', padding: '0px 4px 0px 0px'}}></WrapperImagesStyled>
    <WrapperImagesStyled onClick={()=>{setIsView(true)}} style={{backgroundImage: `url(${props.urls?.[1]})`,width: '25%', height: '50%', left: '50%', padding: '0px 4px 4px 4px'}}></WrapperImagesStyled>
    <WrapperImagesStyled onClick={()=>{setIsView(true)}} style={{backgroundImage: `url(${props.urls?.[2]})`,width: '25%', height: '50%', left: '75%', padding: '0px 0px 4px 4px'}}></WrapperImagesStyled>
    <WrapperImagesStyled onClick={()=>{setIsView(true)}} style={{backgroundImage: `url(${props.urls?.[3]})`,width: '25%', height: '50%', left: '50%', top: '50%', padding: '4px 4px 0px 4px'}}></WrapperImagesStyled>
    <WrapperImagesStyled onClick={()=>{setIsView(true)}} style={{backgroundImage: `url(${props.urls?.[4]})`,width: '25%', height: '50%', left: '75%', top: '50%', padding: '4px 0px 0px 4px'}}></WrapperImagesStyled>
    
    <MoreImageButtonStyled>
      <CameraFilled style={{fontSize: '17px',color: MAIN_BLUE_COLOR, marginRight: 10}}/>
      Посмотреть все фото
    </MoreImageButtonStyled>
    {isView &&
      <WrapperAllPhotosStyled>
        <WrapperContentStyled className="content">
          {props.urls.map((url)=>
              <ImagesStyled style={{backgroundImage: `url(${url})`}}/>
          )}
        </WrapperContentStyled>
        <ExitIconStyled onClick={()=>{setIsView(false)}}/>
      </WrapperAllPhotosStyled>
    }
  </WrapperGaleryStyled>
  )
});