import { RuleObject } from 'antd/lib/form';

export const isErrorValidate = (rule: RuleObject, value: any, callback: any) => {
  let error = value;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};
