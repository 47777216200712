import {Steps} from "antd";
import {useTranslation} from "react-i18next";
import {WrapperStepperStyle, WrapperViewIcon} from "../../../components/stepper/style";
import {DownOutlined} from "@ant-design/icons";
import {useWindowSize} from "react-use-size";
import {useState} from "react";

const {Step} = Steps;

export enum ICoworkingStep {
  General = 0,
  Contacts = 1,
  Scheduler = 2,
  Amenities = 3,
  Tariffs = 4,
  Gallery = 5,
  Docs = 6,
  ActivationPage = 7,
}

export function getCoworkingUrlByStep(step: ICoworkingStep): string {
  switch (step) {
    case ICoworkingStep.General:
      return 'general'
    case ICoworkingStep.Contacts:
      return 'contacts'
    case ICoworkingStep.Scheduler:
      return 'scheduler'
    case ICoworkingStep.Amenities:
      return 'services'
    case ICoworkingStep.Tariffs:
      return 'tariffs'
    case ICoworkingStep.Gallery:
      return 'gallery'
    case ICoworkingStep.Docs:
      return 'docs'
    case ICoworkingStep.ActivationPage:
      return 'activation'
  }
}

interface IProps {
  currentStep: ICoworkingStep;
  maxStep: ICoworkingStep;
  onChange: (newStep: ICoworkingStep) => void
}

export function Steppers(props: IProps) {
  const [t] = useTranslation();
  const {width} = useWindowSize();
  const [view, setView] = useState(false);
  const currentPage = props.currentStep
  const maxStep = props.maxStep

  const changeStep = (newStep: ICoworkingStep) => {
    if (newStep <= maxStep) {
      props.onChange(newStep)
    }
  }

  return (
    <WrapperStepperStyle currentPage={currentPage} progress={maxStep} isView={view}>
      <Steps
        size='small'
        direction={width < 900 ? 'vertical' : 'horizontal'}
        labelPlacement="vertical"
        current={currentPage > maxStep ? currentPage : maxStep}
        className={view ? '' : 'not-view'}
      >
        <Step title={t('general_information')} onClick={() => changeStep(ICoworkingStep.General)}/>
        <Step title={t('coworking_contacts')} onClick={() => changeStep(ICoworkingStep.Contacts)}/>
        <Step title={t('schedule')} onClick={() => changeStep(ICoworkingStep.Scheduler)}/>
        <Step title={t('services')} onClick={() => changeStep(ICoworkingStep.Amenities)}/>
        <Step title={t('tariffs')} onClick={() => changeStep(ICoworkingStep.Tariffs)}/>
        <Step title={t('gallery')} onClick={() => changeStep(ICoworkingStep.Gallery)}/>
        <Step title={t('the_documents')} onClick={() => changeStep(ICoworkingStep.Docs)}/>
        <Step title={t('activation')} onClick={() => changeStep(ICoworkingStep.ActivationPage)}/>
      </Steps>
      {width < 900 && (
        <WrapperViewIcon style={{transform: view ? 'rotate(0deg)' : ''}} onClick={() => setView(!view)}>
          <DownOutlined/>
        </WrapperViewIcon>
      )}
    </WrapperStepperStyle>
  );
}
