import {Button, Form} from 'antd';
import React from 'react';
import {CustomButtonStyled} from './styles';

export interface IButton extends React.ComponentProps<typeof Button> {
  text?: React.ReactNode;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

export const DynamicButton = ({className, isDisabled, onClick, style, text, ...rest}: IButton) => {
  return (
    <Form.Item
      className={className}
      shouldUpdate
    >
      {({getFieldsError}) => (
        <CustomButtonStyled
          disabled={isDisabled || !!getFieldsError().filter(({errors}) => errors.length).length}
          type="primary"
          htmlType="submit"
          onClick={onClick}
          style={style}
          {...rest}
        >
          {text}
        </CustomButtonStyled>
      )}
    </Form.Item>
  );
};
