import {FC, useState} from 'react';
import { SizeInput } from '../../../components/inputs/sizeInput';
import { InputTitleStyled } from '../../../style/titles/inputTitleStyle';
import { NumberInput } from '../../../components/inputs/numberInput';
import { AreaCabinetValidateAnt } from '../../../components/validate/areaCabinetValidate';
import { PeopleCountCabinetValidateAnt } from '../../../components/validate/countPeopleCabinetValidate';
import { useTranslation } from 'react-i18next';
import {CheckboxInput} from "../../../components/inputs/checkboxInput";
import {LeftColumn} from "../../../style/colomn/leftColumn";

interface ICabinet {
  field: any;
  remove: any;
  form: any;
  nameParent: string;
  disabled?: boolean;
}

export const CabinetForm: FC<ICabinet> = (props) => {
  const [t] = useTranslation()
  const [, forceUpdate] = useState({});
  return (
    <>
      <InputTitleStyled>{t('office_area')}</InputTitleStyled>
      <SizeInput disabled={props.disabled} name={[props.field.name, 'area']} readName={[props.nameParent, props.field.name, 'area']} forceUpdate={forceUpdate}  required={false} validate={AreaCabinetValidateAnt} initialValue={props.form.getFieldValue([props.nameParent,props.field.name, 'area'])}/>
      <LeftColumn xl={8} xs={24}>
        <CheckboxInput onChange={() => {
          forceUpdate({})
        }} name={[props.field.name, 'available_space', '0', 'can_be_sold']} required={false} title={t("can_be_sold")} initialValue={true}/>
      </LeftColumn>
      <InputTitleStyled>{t('number_of_people_in_the_office')}</InputTitleStyled>
      <NumberInput disabled={props.disabled} name={[props.field.name, 'capacity']} required={false} validate={PeopleCountCabinetValidateAnt}/>
    </>
  );
};
