import { Button } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR } from '../../style/globalStyle/Color';
import copyIcon from './tarifInformation/icons/copy.svg';
import basket from './tarifInformation/icons/basket.svg';
import arrow from './tarifInformation/icons/arrow.svg';
import exit from './tarifInformation/icons/exit.svg';
import arrowMobile from './tarifInformation/icons/arrowMobile.svg';

export const WrapperCascaderStyled = styled.div`
  width: 70px;
  display: inline-block;
  height: 22px;
`;

export const WrapperTextStyled = styled.div`
  font-size: 16px;
`;

interface IsView {
  isView?: boolean;
  status?: string;
}

export const WrapperTarifBlock = styled.div`
  width: 100%;
  height: 58px;
  position: relative;
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};

`;

export const WrapperTarifTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 55px;
  margin-left: 13px;
`;



export const WrapperAddTarifButton = styled.div`
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  right: 15px;
  span,
  svg {
    width: 100%;
    height: 100%;
    fill: ${MAIN_BLUE_COLOR};
  }
`;

export const WrapperCloseButton = styled.div`
  position: absolute;
  width: 14px;
  height: 18px;
  top: 31px;
  right: 73px;
  /* background-image: url(${basket});
  background-size: cover;
  background-position: center; */
  cursor: pointer;
`;

export const CopyStyled = styled.div`
  position: absolute;
  right: 111px;
  top: 29px;
  height: 20px;
  width: 20px;
  /* background-image: url(${copyIcon});
  background-size: cover;
  background-position: center; */
  cursor: pointer;
`

export const WrapperViewButton = styled.div`
  position: absolute;
  width: 14px;
  height: 9px;
  top: 35px;
  right: 32px;
  /* background-image: url(${arrow});
  background-size: cover;
  background-position: center; */
  cursor: pointer;
  @media (min-width: 318px) and (max-width: 576px) {
    background-image: url(${arrowMobile}) !important;
    width: 18px;
    height: 18px;
    top: 31px;
  }
`;

export const FullScreenWrapperCloseButton = styled(WrapperCloseButton)`
  @media (min-width: 318px) and (max-width: 576px) {
    display: none;
  }
`;

export const FullScreenCopyStyled = styled(CopyStyled)`
  @media (min-width: 318px) and (max-width: 576px) {
    display: none;
  }
`

export const FullScreenWrapperViewButton = styled(WrapperViewButton)`
  @media (min-width: 318px) and (max-width: 576px) {
    background-image: url(${exit}) !important;
    width: 17px;
    height: 17px;
    right: 23px;
    top: 30px;
  }
`;

export const WrapperContentTarif = styled.div<IsView>`
  ${(props) => (props.isView ? `display: block;` : `display: none;`)}
  position: relative;
  z-index: 1;
  @media (min-width: 318px) and (max-width: 576px) {
    position: fixed;
    left: 0;
    top: 64px;
    width: 100%;
    height: calc(100% - 64px);
    z-index: 9;
    padding: 0 !important;
    background-color: white;
    overflow-y: scroll;
  }
`;

export const PreViewTarif = styled.div<IsView>`
  cursor: pointer;
  padding-left: 140px;
  padding-right: 80px;
  padding-top: 20px;
  padding-bottom: 20px;
  ${(props) => (!props.isView ? `display: block;` : `display: none;`)}
  position: relative;
  height: 268px;
  ${(props) => (props.status === 'faild' ? `border-color: red !important;` : ``)}
  ${(props) => (props.status === 'loading' ? `
    &:after{
      content: '';
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 1px);
      background: rgba(0,0,0,0.3);
      position: absolute;
      z-index: 10;
    }
  ` : ``)}
  @media (min-width: 318px) and (max-width: 576px) {
    height: 300px;
    padding: 0;
    display: block;
  }
`;

export const WrapperPreviewImageTarif = styled.div`
  position: absolute;
  left: 24px;
  top: 64px;
  width: 280px;
  height: 180px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  @media (min-width: 318px) and (max-width: 576px) {
    margin-left: auto;
    margin-right: auto;
    top: 64px;
    left: calc(50% - 140px);
  }
`;

export const LeftButtonStyled = styled(Button)`
  margin-top: 20px;
  margin-right: 2%;
  height: 40px;
  background: white !important;
  color: black !important;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR} !important;
  @media (min-width: 318px) and (max-width: 576px) {
    max-width: calc(100% - 150px);
    span{
      margin-top: 4px;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`

export const RightButtonStyled= styled(Button)`
  margin-top: 20px;
  float: right;
  height: 40px;
  background: ${MAIN_BLUE_COLOR}  !important;
  border-color: ${MAIN_BLUE_COLOR} !important;
  border-radius: 8px;
  color: white !important;
  padding-left: 30px;
  padding-right: 30px;
  font-weight: bold;
  font-size: 16px;
`


export const WrapperItemStyled = styled.div`
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;
  margin-top: 15px;
`

export const TitleTariffStyled = styled.div`
  position: absolute;
  left: 320px;
  top: 141px;
  font-size: 20px;
  font-weight: 700;
  @media (min-width: 318px) and (max-width: 576px) {
    top: 245px;
    left: calc(50% - 140px);
  }
`

export const NumberTariffStyled = styled.div`
  position: absolute;
  left: 24px;
  top: 16px;
  font-size: 16px;
  font-weight: bold;
`

export const WrapperMobileScrollStyled = styled.div<IsView>`
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 70px;
  padding-bottom: 20px;
  position: relative;
  ${(props) => (props.status === 'faild' ? `border-color: red !important;` : ``)}
  ${(props) => (props.status === 'loading' ? `
    &:after{
      content: '';
      display: block;
      left: 0;
      top: 0;
      width: 100%;
      height: calc(100% + 1px);
      background: rgba(0,0,0,0.3);
      position: absolute;
      z-index: 10;
    }
  ` : ``)}
    @media (min-width: 318px) and (max-width: 576px) {
      padding-left: 10px;
      padding-right: 10px;
    }
`

export const WrapperIconsStyled = styled.div<{isPreview?: boolean}>`
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  width: 100px;
  justify-content: space-between;
  align-items: center;
  & span{
    color: ${MAIN_BLUE_COLOR};
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
    path{
      color: ${MAIN_BLUE_COLOR};
      transition-duration: 0.2s;
      transition-timing-function: ease-in-out;
    }
    &:hover{
      color: #393980 !important;
      path{
        fill: #393980 !important;
      }
    }
  }
  .mobile{
    display: none;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    .mobile{
      display: block; 
    }
    .desctop{
      display: none;
    }
  }

${props => (props.isPreview) ? `
  @media (min-width: 318px) and (max-width: 576px) {
    justify-content: right;
    & span{
      display: none !important;
    }
    .mobile{
      display: block !important;
    }
  }
`:``}
` 