import {Col, Form, Row} from "antd";
import {CheckboxInput} from "components/inputs/checkboxInput";
import {GoogleAutoCompileInput} from "components/inputs/googleAutoCompileInput";
import {TextInput} from "components/inputs/textInput";
import metroMarkerIcon from "components/modaAutoCompileForm/icons/metroMarker.svg";
import {I18NLangString} from "pages/coworking/types";
import {
  parseGoogleAddressComponents
} from "pages/updateCoworking/contactsl.tsx/parseAddressComponents";
import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {MAIN_RED_COLOR} from "style/globalStyle/Color";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {getLocalOrEn} from "utils/lang";
import {IResultDetails, Service} from ".";
import {
  ButtonConfirmStyled,
  ButtonExitStyled,
  GoogleChoiceStyled,
  IconMetroStyled,
  TitleMetroStyled,
  WrapperChangeMapStyled,
  WrapperGoogleMap,
  WrapperMetroStyled,
  WrapperPositionMapStyled,
  YandexChoiceStyled,
} from './styled';

export function convertCoordinatesToName(latitude: number | string, longitude: number | string) {
  return Number(latitude).toFixed(6) + Number(longitude).toFixed(6)
}

interface IYandexComponent {
  kind: string,
  name: string
}

export interface ISubway {
  distance: number;
  time: number;
  latitude: string;
  longitude: string;
  line: I18NLangString;
  name: I18NLangString;
}

interface ISubwayWithRoutes extends ISubway {
  routes: any[]
}

interface IProps {
  unparsedAddress: string,
  floorsTotal: number,
  floor: string,
  selectedMetros: ISubway[],
  onRequestClose: () => void,
  onChange: (location: IResultDetails) => void,
  onServiceChange: (service: Service) => void,
}

interface IForm {
  suggest: string,
  line: string,
  state: string,
  city: string,
  zip: string,
  floor: string,
  floors_total?: number,
  latitude?: number,
  longitude?: number,

  [key: string]: boolean | number | string | undefined,
}

function parseLocalAddressComponents(address_components: any[]) {
  let newAddress = '';
  let newCity = '';
  let newState = '';
  let newZip = '';
  let stritNumber = '';
  for (let i of address_components) {
    if (i.types.includes('street_number')) {
      newAddress += i.long_name + ' ';
      stritNumber += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('route')) {
      newAddress += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('subpremise')) {
      newAddress += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('locality')) {
      newCity += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('administrative_area_level_1')) {
      newState += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('administrative_area_level_2')) {
      newState += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('postal_code_suffix')) {
      newZip += i.long_name + ' ';
      continue;
    }
    if (i.types.includes('postal_code')) {
      newZip += i.long_name + ' ';
      continue;
    }
  }
  return {
    address: newAddress,
    city: newCity,
    state: newState,
    zip: newZip,
    streetNumber: stritNumber,
  }
}

export function GoogleLocation({
                                 unparsedAddress,
                                 onChange,
                                 onRequestClose,
                                 floorsTotal,
                                 floor,
                                 selectedMetros,
                                 onServiceChange
                               }: IProps) {
  const {t} = useTranslation();
  const [isViewMap, setIsViewMap] = useState(false);
  const [form] = Form.useForm<IForm>()
  const [listMetro, setListMetro] = useState<ISubwayWithRoutes[]>([])
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [savedAddress, setSavedAddress] = useState<{
    enComponents: IYandexComponent[],
    ruComponents: IYandexComponent[],
    zip: string
  } | null>(null)

  const onFinish = (values: IForm) => {
    if (!savedAddress) {
      throw new Error('No address saved!')
    }
    const details = parseGoogleAddressComponents(savedAddress.enComponents, savedAddress.ruComponents)
    const listMetroCheck: ISubway[] = [];
    if (listMetro) {
      for (let i of listMetro) {
        if (values[convertCoordinatesToName(i.latitude, i.longitude)]) {
          const {routes, ...copy} = i;
          listMetroCheck.push({
            ...copy,
            latitude: Number(copy.latitude).toFixed(6),
            longitude: Number(copy.longitude).toFixed(6),
          });
        }
      }
    }

    onChange({
      ...details,
      floor: values.floor,
      floors_total: values.floors_total!,
      latitude: values.latitude!,
      longitude: values.longitude!,
      listMetro: listMetroCheck,
    })
    onRequestClose();
  }

  const createMap = useCallback((position: any) => {
    const map: google.maps.Map = new google.maps.Map(document.getElementById('googleMap') as HTMLElement, {
      center: position,
      zoom: 16,
    });

    function renderDirections(result: any) {
      const directionsRenderer = new google.maps.DirectionsRenderer({
        suppressMarkers: true,
        preserveViewport: true,
        routeIndex: 1,
        polylineOptions: {
          strokeColor: 'grey',
        },
      });
      directionsRenderer.setDirections(result);
      directionsRenderer.setMap(map);
      return directionsRenderer;
    }

    setMap(map);
    new google.maps.Marker({
      position: position,
      map,
    });

    setIsViewMap(true);

    let request = {
      location: position,
      types: ['subway_station'],
      rankBy: google.maps.places.RankBy.DISTANCE,
    };

    const service = new google.maps.places.PlacesService(map);
    const directionsService = new google.maps.DirectionsService();
    service.nearbySearch(request, (results, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK && results) {
        const svgMarker = {
          url: metroMarkerIcon,
          scaledSize: new google.maps.Size(30, 30),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(15, 40),
        };
        let metroPromises = []
        for (let i of results.slice(0, 3)) {
          if (!i.geometry || !i.geometry.location) {
            continue
          }

          new google.maps.Marker({
            position: i.geometry.location,
            map,
            icon: svgMarker,
          });
          const yandexMetroPromise = window.ymaps.geocode([i.geometry.location.lat(), i.geometry.location.lng()], {
            kind: 'metro',
            results: 1,
            lang: localStorage.getItem("i18nextLng") === 'ru' ? 'ru_RU' : 'en_US'
          });

          metroPromises.push(
            directionsService
              .route({
                origin: position,
                destination: i.geometry.location,
                travelMode: google.maps.TravelMode.WALKING,
              })
              .then(async (response) => {
                let metro: any = {};
                metro.routes = renderDirections(response);
                metro.latitude = response.routes[0].legs[0].end_location.lat();
                metro.longitude = response.routes[0].legs[0].end_location.lng();
                if (form.getFieldValue(metro.id)) {
                  metro.routes.setOptions({map: map, polylineOptions: {strokeColor: MAIN_RED_COLOR}, routeIndex: 10});
                }
                metro.distance = response.routes[0].legs[0].distance?.value || '';
                metro.time = response.routes[0].legs[0].duration?.value || '';
                metro.name = {[localStorage.getItem("i18nextLng") || 'en']: i.name}
                const yandexMetro = await yandexMetroPromise
                const addressComponents: { kind: string, name: string }[] = yandexMetro.geoObjects.get(0).properties.get('metaDataProperty.GeocoderMetaData.Address.Components');
                const line = addressComponents.find(k => k.kind === 'route')
                if (line) {
                  metro.line = {[localStorage.getItem("i18nextLng") || 'en']: line.name}
                }
                return metro
              })
          )
        }
        Promise.all(metroPromises).then(metros => setListMetro(metros))
      }
    });
  }, [form])

  const addNewAddress = useCallback(async (item: any) => {
    const localItem: any = getLocalOrEn<any>(item)
    const address = localItem.formatted_address;
    const parsed = parseLocalAddressComponents(localItem.address_components)

    const coordinates = [
      localItem.geometry.location.lng.toFixed(5),
      localItem.geometry.location.lat.toFixed(5)
    ]

    form.setFieldsValue({
      suggest: address,
      line: parsed.address,
      state: parsed.state,
      city: parsed.city,
      zip: parsed.zip,
      latitude: coordinates[1],
      longitude: coordinates[0],
    })

    setSavedAddress({
      enComponents: item['en'].address_components,
      ruComponents: item['ru'].address_components,
      zip: parsed.zip
    })

    if (parsed.streetNumber) {
      createMap(localItem.geometry.location);
    }
  }, [createMap, form])

  const initialValues: IForm = {
    suggest: unparsedAddress,
    line: "",
    state: "",
    city: "",
    zip: "",
    floors_total: floorsTotal,
    floor: floor,
  }
  if (selectedMetros) {
    for (let metro of selectedMetros) {
      initialValues[convertCoordinatesToName(metro.latitude, metro.longitude)] = true
    }
  }

  const onSelectMetro = (e: any, item: any) => {
    if (e.target.checked) {
      item.routes.setOptions({map: map, polylineOptions: {strokeColor: MAIN_RED_COLOR}, routeIndex: 10});
    } else {
      item.routes.setOptions({map: map, polylineOptions: {strokeColor: 'grey'}, routeIndex: 1});
    }
  };

  return (
    <Form form={form} onFinish={onFinish} initialValues={initialValues}>
      <Row style={{position: 'relative'}}>
        <Col span={24} xl={{span: isViewMap ? 12 : 24}}>
          <MainCenterTitleStyle style={{marginTop: 0}}>{t("add_address")}</MainCenterTitleStyle>

          <Form.Item name='longitude' hidden></Form.Item>
          <Form.Item name='latitude' hidden></Form.Item>

          <div style={{clear: 'both', height: 25}}/>
          <Row>
            <WrapperChangeMapStyled>
              <YandexChoiceStyled isActive={false} onClick={() => onServiceChange(Service.Yandex)}>
                Yandex
              </YandexChoiceStyled>
              <GoogleChoiceStyled isActive={true} onClick={() => onServiceChange(Service.Google)}>
                Google
              </GoogleChoiceStyled>
            </WrapperChangeMapStyled>

            <InputTitleStyled style={{marginLeft: 0}}>{t("find_on_the_map")}</InputTitleStyled>
            <GoogleAutoCompileInput
              name='suggest'
              onSelect={addNewAddress}
              required={true}
            />

            <Col span={24} sm={{span: 24}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('address')}</InputTitleStyled>
              <TextInput
                disabled
                name='line'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 24}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('state')}</InputTitleStyled>
              <TextInput
                disabled
                name='state'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('city')}</InputTitleStyled>
              <TextInput
                disabled
                name={'city'}
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5, marginBottom: 20}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('ZIP')}</InputTitleStyled>
              <TextInput
                disabled
                name='zip'
                required={false}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('floor')}</InputTitleStyled>
              <TextInput
                name='floor'
                required={true}
              />
            </Col>
            <Col span={24} sm={{span: 12}} style={{paddingLeft: 5, marginBottom: 20}}>
              <InputTitleStyled style={{marginLeft: 0}}>{t('floors_total')}</InputTitleStyled>
              <TextInput
                name='floors_total'
                required={true}
              />
            </Col>
            <WrapperPositionMapStyled style={{display: isViewMap ? '' : 'none'}}>
              <WrapperGoogleMap id="googleMap"></WrapperGoogleMap>
            </WrapperPositionMapStyled>
            {listMetro.map((item, index) => (
              <WrapperMetroStyled style={{paddingLeft: 0}} key={index}>
                <IconMetroStyled/>
                <Row>
                  <Col span={22} sm={{span: 17}}>
                    <TitleMetroStyled>
                      {t('name')}: {getLocalOrEn(item.name)}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('name_line')}: {getLocalOrEn(item.line)}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('distance')}: {item.distance} {t('meters')}
                    </TitleMetroStyled>
                    <TitleMetroStyled>
                      {t('walk')}: {(item.time / 60) | 0} {t('minutes')}
                    </TitleMetroStyled>
                  </Col>
                  <Col span={2} sm={{span: 7}} style={{marginTop: 10}}>
                    <CheckboxInput
                      onChange={(e: any) => {
                        onSelectMetro(e, item);
                      }}
                      name={convertCoordinatesToName(item.latitude, item.longitude)}
                      required={false}
                      title={t('views')}
                    />
                  </Col>
                </Row>
              </WrapperMetroStyled>
            ))}
          </Row>
          <ButtonConfirmStyled
            type="primary"
            htmlType="submit"
          >
            {t('confirm')}
          </ButtonConfirmStyled>
        </Col>
        <Col span={0} xl={{span: isViewMap ? 12 : 0}} style={{zIndex: -1}}></Col>
        <ButtonExitStyled
          onClick={() => {
            onRequestClose();
          }}
        />
      </Row>
    </Form>
  )
}
