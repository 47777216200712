import { message } from 'antd';
import { RuleObject } from 'antd/lib/form';

export const uploadImageValidate = (rule: RuleObject, value: any, callback: any) => {
  let error = false;
  let text = '';
  if (rule.required && (!value || value?.fileList?.length === 0)) {
    error = true;
    message.error(localStorage.getItem('i18nextLng') === 'en' ? 'Upload image' : 'Загрузить изображение')
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Required field' : 'Обязательное поле';
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};
