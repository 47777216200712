import {I18NLangString, ITimetable} from "pages/coworking/types";
import {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {InputTitleStyled} from "style/titles/inputTitleStyle"
import {
  CustomEllipsisOutlined,
  Icon24x7,
  LineDecorationStyled,
  ModalScedulerStyled,
  ReceptionStyled,
  ScedulerButtonStyled,
  TimeStyled,
  TitleDayStyled,
  WrapperCloseOutlined,
  WrapperReceptionStyled,
  WrapperScedulerOnTheDay
} from "./styles"

const weekList: { id: "monday" | "tuesday" | "wednesday" | "thursday" | "friday" | "saturday" | "sunday", name: I18NLangString }[] = [
  {id: 'monday', name: {ru: 'пн', en: 'mn'}},
  {id: 'tuesday', name: {ru: 'вт', en: 'ts'}},
  {id: 'wednesday', name: {ru: 'ср', en: 'wd'}},
  {id: 'thursday', name: {ru: 'чт', en: 'th'}},
  {id: 'friday', name: {ru: 'пт', en: 'fr'}},
  {id: 'saturday', name: {ru: 'сб', en: 'st'}},
  {id: 'sunday', name: {ru: 'вс', en: 'sn'}},
]

export function Sceduler({sceduler}: { sceduler: ITimetable }) {
  const [t] = useTranslation()
  const [isView, setIsView] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const body = document.getElementsByTagName('body')[0];
      if (isView) {
        const scrollY = window.scrollY;
        window.scrollTo(0, 0);
        setTimeout(() => {
          body.style.position = 'fixed';
          body.style.top = `-${scrollY}px`;
        }, 20)
      } else {
        const scrollY = body.style.top;
        body.style.top = '';
        body.style.position = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }, [isView])

  return (
    <WrapperReceptionStyled span={24}>
      <ReceptionStyled onClick={() => setIsView(isView => !isView)}>
        {sceduler?.is_24x7 && <Icon24x7/>}
        <ScedulerButtonStyled>
          {sceduler?.is_24x7 && t('reception_opening_hours')}
          {!sceduler?.is_24x7 && t('coworking_working_hours')}
          <CustomEllipsisOutlined/>
        </ScedulerButtonStyled>
        {isView &&
          <ModalScedulerStyled onClick={(e) => e.stopPropagation()}>
            <InputTitleStyled style={{fontSize: 16}}>
              {sceduler?.is_24x7 && t('reception_opening_hours')}
              {!sceduler?.is_24x7 && t('coworking_working_hours')}
            </InputTitleStyled>
            <LineDecorationStyled/>

            {weekList.map((item) => <WrapperScedulerOnTheDay>
              <TitleDayStyled>{item.name.ru}</TitleDayStyled>
              <TimeStyled>{sceduler[item.id].start}-{sceduler[item.id].end}</TimeStyled>
            </WrapperScedulerOnTheDay>)}

            <WrapperCloseOutlined onClick={() => setIsView(isView => !isView)}/>

          </ModalScedulerStyled>
        }
      </ReceptionStyled>
    </WrapperReceptionStyled>
  )
}
