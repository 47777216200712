export function symbolForCurrency(currency: string) {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
  });

  let symbol;
  formatter.formatToParts(0).forEach(({type, value}) => {
    if (type === 'currency') {
      symbol = value;
    }
  });

  return symbol;
}
