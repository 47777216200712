import {ErrorBoundary} from "components/ErrorBoundary/ErrorBoundary";
import ScrollToTop from "components/ScrollToTop";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import App from './App';
import {store} from './store/store';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {CookiesProvider} from 'react-cookie';
import './i18nextInit';
import 'dotenv'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

let environment = 'prod';
switch (process.env.REACT_APP_ENVIRONMENT) {
  case 'local':
    environment = 'local'
    break;
  case 'develop':
    environment = 'develop'
    break;
  case 'stage':
    environment = 'stage'
    break;
}
let dsn: undefined | string = "https://c420a515ac124068848d48f19bab7375@o368670.ingest.sentry.io/5174774";
if (environment === 'develop' || environment === 'local') {
  dsn = undefined;
}

Sentry.init({
  dsn,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  environment,
});

Sentry.setTag("ico.locale", localStorage.getItem("i18nextLng") === 'ru' ? "ru-RU" : "en-EN");
Sentry.setTag("ico.component", "frontend-pro");

ReactDOM.render(
  <ErrorBoundary>
    <CookiesProvider>
      <Provider store={store}>
        <Router>
          <ScrollToTop/>
          <App/>
        </Router>
      </Provider>
    </CookiesProvider>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
