export const getAddress = async (address: any) => {
  try {
    const ruRespunse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${JSON.stringify(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=ru`
    );

    const ruData = await ruRespunse.json();

    const enRespunse = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${JSON.stringify(address)}&key=${process.env.REACT_APP_GOOGLE_API_KEY}&language=en`
    );

    const enData = await enRespunse.json();

    ruData.results[0].address_components.push({
      types: ['unparsed_address'],
      long_name: ruData.results[0].formatted_address,
    });

    enData.results[0].address_components.push({
      types: ['unparsed_address'],
      long_name: enData.results[0].formatted_address,
    });

    return {ru: ruData.results[0], en: enData.results[0]};
  } catch {

  }
};
