import {getLangKey} from "utils/lang";
import {setCookie, eraseCookie} from '../../utils/nativeCookie';

const ROOT_URL = process.env.REACT_APP_SERVER_API_URL

export function getRequest(url: string) {
  const requestOptions: any = {
    headers: {
      'Accept-Language': getLangKey()
    },
    method: 'GET',
  };
  if (window.tokens?.access) {
    requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
  }
  return fetch(ROOT_URL + url, requestOptions).then(async (response) => {
    if (response.status === 401) {
      await getToken(window.tokens?.refresh);
      requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
      return fetch(ROOT_URL + url, requestOptions).then(handleResponse);
    }
    return handleResponse(response);
  });
}

export function postRequest(url: string, body: any) {
  const requestOptions: any = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  };
  if (window.tokens?.access) {
    requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
  }
  return fetch(ROOT_URL + url, requestOptions).then(async (response) => {
    if (response.status === 401) {
      await getToken(window.tokens?.refresh);
      requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
      return fetch(ROOT_URL + url, requestOptions).then(handleResponse);
    }
    return handleResponse(response);
  });
}

export function formDataRequest(url: string, formData: any, method: any = 'POST') {
  const requestOptions: any = {
    method: method,
    headers: {},
    body: formData,
  };
  if (window.tokens?.access) {
    requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
  }
  return fetch(ROOT_URL + url, requestOptions).then(async (response) => {
    if (response.status === 401) {
      await getToken(window.tokens?.refresh);
      requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
      return fetch(ROOT_URL + url, requestOptions).then(handleResponse);
    }
    return handleResponse(response);
  });
}

export function putRequest(url: string, body: any) {
  const requestOptions: any = {
    method: 'PATCH',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(body),
  };
  if (window.tokens?.access) {
    requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
  }
  return fetch(ROOT_URL + url, requestOptions).then(async (response) => {
    if (response.status === 401) {
      await getToken(window.tokens?.refresh);
      requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
      return fetch(ROOT_URL + url, requestOptions).then(handleResponse);
    }
    return handleResponse(response);
  });
}

// prefixed with underscored because delete is a reserved word in javascript
export function deleteRequest(url: string, body: any) {
  const requestOptions: any = {
    headers: {'Content-Type': 'application/json'},
    method: 'DELETE',
    body: JSON.stringify(body),
  };
  if (window.tokens?.access) {
    requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
  }
  return fetch(ROOT_URL + url, requestOptions).then(async (response) => {
    if (response.status === 401) {
      await getToken(window.tokens?.refresh);
      requestOptions.headers.Authorization = 'Bearer ' + window.tokens?.access;
      return fetch(ROOT_URL + url, requestOptions).then(handleResponse);
    }
    return handleResponse(response);
  });
}

// helper functions

function handleResponse(response: any) {
  return response.text().then((text: any) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      data.isError = true;
    }
    return data;
  });
}

async function getToken(refreshToken: string) {
  const requestOptions: any = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({refresh: refreshToken}),
  };
  const responce = await fetch(ROOT_URL + '/smp_api/users/refresh_token/', requestOptions).then(handleResponse);
  if (!responce.isError) {
    setCookie('tokens', responce, 1);
    window.tokens = responce;
  }
  if (responce.isError) {
    window.tokens = null;
    eraseCookie('userData')
    eraseCookie('tokens')
    localStorage.removeItem("credential")
    window.location.href = "/"
  }
}
