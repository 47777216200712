import {Row} from 'antd';
import {CustomInputStyled} from "components/inputs/textInput/styles";
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {LeftColumn} from 'style/colomn/leftColumn';
import {RightColumn} from 'style/colomn/rightColumn';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {SelectDescriptionInput} from '../selectDescriptionInput';

interface IDescriptionTariffInput {
  name: Array<any>;
  required: boolean;
  form: any;
  nameParent?: string;
  onChange?: any;
  description?: any;
  coworking?: any;
  disabled?: boolean;
}

export const DescriptionTariffInput: FC<IDescriptionTariffInput> = memo((props) => {
  const [t] = useTranslation();
  const theTariffIncludes = t("the_tariff_includes")
  const errorWhenSpace = t("not_a_space")
  const errorWhenNumber = t("not_a_number")
  const errorWhenAlreadyHaveThis = t("already_have")
  const descriptionTitle = t("description_title")
  const descriptionItems = t("description_items")
  const userDescriptions = useSelector((state: IDescriptionTariffInput) => state.coworking.description)

  return (
    <Row style={{marginTop: 20}}>
      <LeftColumn span={24} sm={24} onChange={props.onChange}>
        <InputTitleStyled>{descriptionTitle}</InputTitleStyled>
        <CustomInputStyled
          disabled
          value={theTariffIncludes}
        />
      </LeftColumn>
      <RightColumn span={24} sm={24} onChange={props.onChange}>
        <InputTitleStyled>{descriptionItems}</InputTitleStyled>
        <SelectDescriptionInput
          disabled={props.disabled}
          errorWhenAlreadyHaveThis={errorWhenAlreadyHaveThis}
          errorWhenSpace={errorWhenSpace} errorWhenNumber={errorWhenNumber} form={props.form}
          multiple={true} nameParent={props.nameParent} required={props.required}
          data={userDescriptions} name={props.name}/>
      </RightColumn>
    </Row>
  );
});
