import {RuleObject} from 'antd/lib/form';
import {ConvertFromDisplayPriceStringToNumber} from "components/inputs/priceInput";

export const validateNotZeroNumberAnt = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!rule.required) {
      resolve();
    }
    const res = ConvertFromDisplayPriceStringToNumber(value)
    if (Number.isNaN(res)) {
      reject()
    }
    if (res === 0) {
      reject()
    }
    resolve();
  })
};


export const onlyPositiveNumber = (rule: RuleObject, value: string) => {
  return new Promise<void>((resolve, reject) => {
    if (!rule.required) {
      resolve()
    }
    const res = ConvertFromDisplayPriceStringToNumber(value)
    if (Number.isNaN(res)) {
      reject()
    }
    if (res < 0) {
      reject()
    }
    resolve()
  })
};
