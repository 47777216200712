import {RcFile} from "antd/lib/upload/interface";
import {IUpload} from "components/inputs/uploadImageInput";

export type I18NLangString = Record<"ru" | "en", string | undefined>

export enum ICoworkingStatus {
  Approve = "approve",
  Active = "active",
  Disabled = "disabled",
  Draft = "draft"
}

export interface ICoworking {
  id: number;
  status: ICoworkingStatus;
  title: I18NLangString;
  description: I18NLangString;
  crm: ICRM[];
  web_site: string;
  coworking_email: string;
  phone_number: I18NLangString;
  fixed_workplace_count: number,
  nonfixed_workplace_count: number,
  workplace_count_in_office: number,
  area: number,
  document_offer: string | null,
  document_rules: string | null,
  plan: string | null,
  managers: IManager[],
  subway: {
    city: number,
    distance: number,
    latitude: string,
    line: I18NLangString,
    longitude: string,
    name: I18NLangString,
    time: number,
  }[],
}

export interface ICreateCoworking extends Partial<Omit<ICoworking, 'subway' | 'crm'>> {
  crm?: number[],
  subway?: {
    cities: number[],
    distance: number,
    latitude: string,
    line: I18NLangString,
    longitude: string,
    name: I18NLangString,
    time: number,
  }[],
}

export interface IManager {
  email: string,
  phone: string,
  name: { description: string }
}

export interface ICRM {
  id: number;
  name: I18NLangString;
  is_active: boolean;
}

export interface ISeatingConfig {
  id: number,
  name: string,
  description: string,
}

export interface ICountry {
  id: number,
  name: I18NLangString,
  official_currency: string,
  first_day_of_week: number,
}

export interface IAddress {
  country: ICountry,
  city: ICity,
  state: I18NLangString,
  street_line_1: I18NLangString,
  street_line_2: I18NLangString,
  zip_code: string,
  unparsed: string,
  floor: string,
  floors_total: number,
  latitude: string,
  longitude: string,
  map_service: string | null,
}

export interface ICreateAddress extends Omit<IAddress, 'city' | 'country'> {
  country: I18NLangString,
  city: I18NLangString,
}

export interface ICity {
  id: number,
  country: {
    id: number,
    name: I18NLangString,
    official_currency: string
  },
  name: I18NLangString
}

export interface ITimetable {
  is_24x7: boolean;
  monday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  tuesday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  wednesday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  thursday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  friday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  saturday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
  sunday: {
    abbreviation: string;
    available: boolean;
    daynum: number;
    end: string;
    name: string;
    start: string;
  };
}

export interface ICreateTimetable {
  is_24x7: boolean;
  monday: {
    start: string;
    end: string;
    available: boolean;
  };
  tuesday: {
    start: string;
    end: string;
    available: boolean;
  };
  wednesday: {
    end: string;
    start: string;
    available: boolean;
  };
  thursday: {
    end: string;
    start: string;
    available: boolean;
  };
  friday: {
    end: string;
    start: string;
    available: boolean;
  };
  saturday: {
    end: string;
    start: string;
    available: boolean;
  };
  sunday: {
    end: string;
    start: string;
    available: boolean;
  };
}


export interface IAmenity {
  id: number,
  title: I18NLangString,
  abbreviation: I18NLangString,
  icon: string,
}

export interface IAmenityCategory {
  category: I18NLangString;
  amenities: IAmenity[];
}

export interface ICreateAmenities {
  abbreviation: I18NLangString,
  icon: string,
  id: number,
  title: I18NLangString,
}

export interface ISpaceCategory {
  type: {
    id: number,
    type: I18NLangString,
    description: I18NLangString
  },
  spaces: ISpace[]
}

export interface ISpace {
  id: number,
  title: I18NLangString,
  description: I18NLangString,
  is_fixed: boolean,
  capacity: number,
  area: number | null,
  space_type_id: number,
  cowork_id: number,
  available_space: {
    id: number,
    cowork_space: number,
    reservation_unit: number,
    price_per_unit: string,
    current_discount: string,
    is_trial: boolean,
    can_be_sold: boolean,
    cancel_booking_timeout: number,
    max_per_day: null,
    max_per_month: null,
    meeting_room_hours: null,
    seating_config?: ISeatingConfig
  }[],
  is_been_booked_at_least_once: boolean
}

export interface ISpaceConverted extends Omit<ISpace, "description" | "available_space"> {
  payableTarifServices: number[],
  tarifServices: number[],
  description: {
    ru?: string[],
    en?: string[],
  },
  available_space: {
    id: number,
    cowork_space: number,
    reservation_unit: number[],
    price_per_unit: string,
    current_discount: string,
    is_trial: boolean,
    can_be_sold: boolean,
    cancel_booking_timeout: number,
    max_per_day: null,
    max_per_month: null,
    meeting_room_hours: null,
    seating_config?: number
  }[],
  images: IUpload
}

export interface IGallery {
  file_id: number,
  file: string,
  order: number,
  description: I18NLangString,
}

export interface INewFile {
  file: RcFile,
  id: number,
  order: number,
  description: string
}

export interface IOldFile {
  uid: string,
  file_id: number,
  order: number,
  description: string
}

export interface IReservationUnit {
  id: number,
  type: I18NLangString,
  description: string
}
