import styled from 'styled-components';
import { Button, Input } from 'antd';
import { MAIN_BLUE_COLOR, MAIN_BORDER_COLOR } from '../../style/globalStyle/Color';
// import place from './../../icons/place.svg';
import metroIcon from './icons/metro.svg';
import plus from './icons/plus.svg';
import exit from './icons/exit.svg';

export const WrapperModalGoogleForm = styled.div`
  /* width: 669px; */
  width: 90%;
  max-width: 1060px;
  background: white;
  box-shadow: -2px 6px 24px rgba(84, 105, 159, 0.12);
  border-radius: 8px;
  padding: 18px;
  margin-left: auto;
  margin-right: auto;
  max-height: 90%;
  overflow-y: auto;
  border: 6px solid white;
  &::-webkit-scrollbar-track {
    background: #e8edf2 !important;
  }
  &::-webkit-scrollbar-thumb {
    border-left-color: #e8edf2;
    border-right-color: #e8edf2;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    width: 100%;
    height: 100%;
    max-height: 100%;
    margin: 0;
    border-radius: 0;
    overflow-y: auto;
    .ant-col {
      padding: 0 !important;
    }
    -webkit-overflow-scrolling:touch;
  }
`;

export const WrapperBackground = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(19, 20, 24, 0.5);
  backdrop-filter: blur(4px);
  z-index: 20;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @media (min-width: 318px) and (max-width: 576px) {
    height: calc(100% - 64px);
    width: 100vw;
    top: 64px;
    z-index: 999999;
    /* overflow-y: scroll;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;  */
  }
`;

export const CuntomInput = styled(Input)`
  background: #ffffff !important;
  border-radius: 8px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: calc(100% - 10px) !important;
  padding: 10px !important;
`;

export const CustomTitle = styled.div`
  margin-left: 5px;
  margin-top: 17px;
  margin-bottom: 8px;
  /* Main Medium */

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  /* Dark Grey */

  color: #3b485c;
`;

export const WrapperResult = styled.div`
  background: #ffffff !important;
  border-radius: 8px !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  width: calc(100% - 10px) !important;
  height: 140px;
  padding: 10px 0 !important;
  margin-top: 20px;
  overflow: hidden;
`;

export const ButtonResetStyled = styled(Button)`
  line-height: 30px;
  border: none;
  border-bottom: 2px solid ${MAIN_BLUE_COLOR};
  color: ${MAIN_BLUE_COLOR};
  float: right;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  margin-top: 2px;
  padding: 0;
  margin-right: 6px;
`;

export const ButtonConfirmStyled = styled(Button)`
  width: auto;
  height: 40px;
  background: ${MAIN_BLUE_COLOR} !important;
  border-color: ${MAIN_BLUE_COLOR} !important;
  margin-top: 10px;
  float: right;
  border-radius: 8px;
  padding: 8px 14px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const WrapperItemResult = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 39px;
  position: relative;
  padding-left: 32px;
  margin-top: 5px;
  width: 100%;
  height: 39px;
  z-index: 1;
  overflow: hidden;
  cursor: pointer;
  &::after {
    content: '';
    width: 18px;
    height: 24px;
    display: block;
    position: absolute;
    left: 7px;
    top: 5px;
    background-color: grey;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: -1;
  }
  &:hover::before {
    background: rgba(49, 131, 255, 0.2);
  }
`;

export const ButtonExitStyled = styled.div`
  position: absolute;
  right: -10px;
  top: -10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background-image: url(${exit});
  background-size: cover;
  background-position: center;
`;

export const WrapperMapStyled = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 10px;
  padding-top: 30px;
  & > ymaps,
  & > ymaps > ymaps {
    width: 100% !important;
    height: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
  }
  @media (min-width: 318px) and (max-width: 1200px) {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 30px;
    width: calc(100% - 1px);
  }
  @media (min-width: 1200px) {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    left: 100%;
  }
`;

export const WrapperMetroStyled = styled.div`
  width: 100%;
  height: 105px;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  position: relative;
  overflow: hidden;
  @media (min-width: 318px) and (max-width: 576px) {
    .ant-checkbox-wrapper > span:last-child {
      display: none;
    }
  }
  .ant-checkbox-wrapper > span:last-child {
    height: 60px !important;
    top: -10px;
    white-space: normal !important;
    line-height: 20px;
    text-align: center;
  }
`;

export const TitleMetroStyled = styled.div`
  margin-left: 100px;
  margin-top: 2px;
  padding-right: 5px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const IconMetroStyled = styled.div`
  width: 60px;
  height: 60px;
  position: absolute;
  left: 15px;
  top: 20px;
  background-size: cover;
  background-position: center;
  background-image: url(${metroIcon});
`;

export const WrapperPositionMapStyled = styled.div`
  width: 100%;
  height: 400px;
  @media (min-width: 1200px) {
    height: 0px;
    position: static;
    margin-top: -20px;
    display: contents;
  }
`;

export const WrapperAddressStyled = styled.div`
  position: relative;
  width: calc(100%);
  float: left;
  input {
    padding-right: 40px;
  }
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    right: 13px;
    top: 13px;
    background-image: url(${plus});
    background-size: cover;
    z-index: 2;
    cursor: pointer;
  }
`;

export const YandexWrapperIcon = styled.div<any>`
  width: 50px;
  height: 50px;
  float: left;
  margin-left: 10px;
  margin-bottom: 20px;
  fill: #d8d8d8 !important;
  cursor: pointer;
  svg,
  path,
  rect {
    transition-duration: 0.6s;
  }
  svg {
    width: 100%;
    height: 100%;
  }
  rect {
    fill: #ffffff !important;
  }
  ${(props) =>
    props.isActive
      ? `
    svg path:last-child{
      stroke: #9c9c9c !important;
    }
    rect{
      fill: #c2c2c2 !important;
    }
  `
      : ``}
`;

export const GoogleWrapperIcon = styled.div<any>`
  width: 50px;
  height: 50px;
  float: left;
  margin-left: 10px;
  margin-bottom: 20px;
  fill: #d8d8d8 !important;
  cursor: pointer;
  svg,
  path {
    transition-duration: 0.6s;
  }

  ${(props) =>
    props.isActive
      ? `
    svg{
      width: 100%;
      height: 100%;
    }
    svg path:nth-child(1){
      fill: #aaaaaa !important;
    }
    svg path:nth-child(2){
      fill: #7e7e7e !important;
    }
    svg path:nth-child(3){
      fill: #b4b4b4 !important;
    }
    svg path:nth-child(4){
      fill: #858585 !important;
    }
  `
      : ``}
`;

export const WrapperGoogleMap = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: -10px;
  @media (min-width: 1200px) {
    position: absolute !important;
    right: 0;
    top: 0;
    width: calc(100% - 10px);
    height: calc(100% - 30px);
    left: 100%;
    margin-left: 10px;
    margin-top: 30px;
  }
`;

export const WrapperChangeMapStyled = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};

  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 40px;
  cursor: pointer;
  overflow: hidden;
`

export const YandexChoiceStyled = styled.div<any>`
  width: 50%;
  height: 100%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isActive ? `
    color: white;
    background-color: ${MAIN_BLUE_COLOR};
  `:``}
`

export const GoogleChoiceStyled = styled.div<any>`
  width: 50%;
  height: 100%;
  float: left;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => props.isActive ? `
    color: white;
    background-color: ${MAIN_BLUE_COLOR};
  `:``}
`