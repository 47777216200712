import styled from "styled-components";
import { MAIN_BORDER_COLOR } from "../../../style/globalStyle/Color";
import plus from './icons/plus.svg';

export const WrapperCoworkingStyled = styled.div`
  margin-top: 20px;
  width: calc(25% - 20px);
  margin-right: 10px;
  margin-left: 10px;
  height: 444px;
  float: left;
  @media (min-width: 318px) and (max-width: 1300px) {
    width: calc(33.333% - 20px);
    position: relative;
  }
  @media (min-width: 318px) and (max-width: 1100px) {
    width: calc(50% - 20px);
    position: relative;
  }
  @media (min-width: 318px) and (max-width: 800px) {
    width: calc(100% - 10px);
    position: relative;
  }
`

export const EmptyCoworkingStyled = styled.div`
  margin-top: 20px;
  width: calc(25% - 20px);
  margin-right: 20px;
  height: 444px;
  float: left;
  border: 1px solid ${MAIN_BORDER_COLOR};
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: 318px) and (max-width: 1300px) {
    width: calc(33.333% - 20px);
    position: relative;
  }
  @media (min-width: 318px) and (max-width: 1100px) {
    width: calc(50% - 20px);
    position: relative;
  }
  @media (min-width: 318px) and (max-width: 800px) {
    width: calc(100%);
    position: relative;
  }
`

export const EmptyCoworkingIconStyled = styled.div`
  position: absolute;
  left: 63px;
  top: 216px;
  width: 12px;
  height: 12px;
  background-image: url(${plus});
  background-size: cover;
  background-position: center;
`

export const EmptyCoworkingTextStyled = styled.div`
  left: 87px;
  top: 210px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  display: block;
  height: 40px;
  &::before{
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    background-image: url(${plus});
    background-size: cover;
    background-position: center;
    margin-right: 10px;
  }
`