import styled from 'styled-components';
import { MAIN_BLUE_COLOR, MAIN_RED_COLOR } from '../../style/globalStyle/Color';

export const WrapperStepperStyle = styled.div<any>`
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;
  & > div {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  .ant-steps-item,
  .ant-steps-item-tail {
    transition-duration: 0.5s;
    transition-timing-function: ease-in-out;
  }
  .ant-steps-item-tail {
    top: 16px;
    left: 8px;
  }
  .ant-steps-label-vertical .ant-steps-item-content {
    width: 132px;
  }
  overflow: hidden;

  width: 100%;
  /* height: 60px; */
  margin-bottom: 20px;
  margin-top: 18px;
  position: relative;
  background: white;
  min-height: 64px;
  grid-area: stepper;
  .ant-steps-item-title{
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0em;
    text-align: center;
  }

  .ant-steps {
    padding: 15px;
    cursor: pointer;
  }
  display: flex;
  flex-direction: column;
  .ant-steps-item{
    display: table !important;
    .ant-steps-item-title::after{
      display: none !important;
    }
  }
  .ant-steps-item-title{
    padding-right: 0 !important;
  }
  @media (min-width: 318px) and (max-width: 1430px) {
    .ant-steps-item{
      display: block !important;
    }
  }
  .ant-steps-item-icon{
    height: 40px !important;
    width: 40px !important;
    border: 3px solid rgba(0, 0, 0, 0.25);
    span{
      width: 100% !important;
      display: block;
      text-align: center;
      line-height: 34px;
      font-size: 20px;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0em;
    }
  }
  svg{
    margin-top: 7px;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${MAIN_RED_COLOR};
    .ant-steps-icon {
      color: ${MAIN_RED_COLOR};
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: ${MAIN_RED_COLOR};
  }
  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: rgba(0,0,0,0);
    border-color: ${MAIN_RED_COLOR};
    span{
      color:  ${MAIN_RED_COLOR};
    }
  }
  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-tail::after {
    background-color: ${MAIN_RED_COLOR};
  }

  .ant-steps-vertical {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0 !important;
  }
  @media (min-width: 318px) and (max-width: 899px) {
    .ant-steps-item-content {
      margin-bottom: 10px;
      padding-top: 6px;
    }
    .not-view .ant-steps-item {
      opacity: 0;
    }
    .ant-steps-vertical{
      width: 100%;
    }
    .ant-steps {
      padding-left: 20px;
      padding-right: 20px;
    }
    .ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
      left: 20px;
      top: 10px;
    }
    ${(props) => `
            .not-view .ant-steps-item:nth-child(${props.currentPage + 1}) {
                opacity: 1 !important;
                .ant-steps-item-tail{
                    opacity: 0 !important;
                }
            }
            .not-view{
                margin-top: -${props.currentPage * 58 + 1}px;
            }
        `}
    ${(props) => `
            ${props.isView ? `height: 471px;` : `height: 45px;`}
        `}
  }

  ${(props) =>
    props.currentPage >= props.progress
      ? ``
      : `
        .ant-steps-item-active .ant-steps-item-icon{
            border-color: ${MAIN_BLUE_COLOR} !important;
            .ant-steps-icon{
              color: ${MAIN_BLUE_COLOR} !important;
            }
        }
        .ant-steps .ant-steps-item-finish:nth-child(${props.progress}) .ant-steps-item-title:after{
            background-color: ${MAIN_BLUE_COLOR} !important;
        }
        .ant-steps .ant-steps-item-finish:nth-child(${props.currentPage + 1}) .ant-steps-item-icon{
            background-color: ${MAIN_RED_COLOR} !important;
            svg{
                fill: white !important;
            }
        }
    `}
`;

export const WrapperViewIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 22px;
  width: 20px;
  height: 20px;
  cursor: pointer;
  transform: rotate(90deg);
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  span {
    width: 100%;
    height: 100%;
  }
  svg {
    width: 100%;
    height: 100%;
  }
`;
