import Icon, {HeartOutlined, ShareAltOutlined} from "@ant-design/icons";
import {Col, Form, Row} from "antd";
import {Galery} from "components/arbnbGalery";
import {CascaderInput} from "components/inputs/cascaderInput";
import {Tariff} from "components/tariff";
import {
  IAddress,
  IAmenity,
  IAmenityCategory,
  ICoworking,
  IGallery,
  IReservationUnit,
  ISpaceConverted,
  ITimetable
} from "pages/coworking/types";
import {getLocalOrEn} from "utils/lang";
import {ReactComponent as arrow} from "pages/updateCoworking/activationPage/icons/arrow.svg";
import {Sceduler} from "pages/updateCoworking/activationPage/sceduler";
import {
  AddressStyled, ChoiceStyled,
  CustomCheckBoxStyled,
  DateIconStyled,
  DateStyled, FilterStyled,
  MetroStyled,
  SaveStyled,
  SharedStyled,
  TariffDescriptionChoiceStyled, TextAboutCoworkingStyled, TextDateStyled,
  ToTheBottomArrowStyled,
  ViewIconStyled, WrapperChangeStyled,
  WrapperDateStyled, WrapperDynamicButton, WrapperFilterStyled,
  WrapperLinksStyled,
  WrapperTariffDescriptionChoiceStyled, WrapperTariffListStyled,
  WrapperTariffStyled, WrapperTextAboutCoworkingStyled,
  WrapperTextStyled
} from "pages/updateCoworking/activationPage/styles";
import {AmenitiesList} from "pages/updateCoworking/activationPage/viewAmenities";
import {YandexMap} from "pages/updateCoworking/activationPage/yandexMap";
import {useMemo, useState, Fragment} from "react";
import {useTranslation} from "react-i18next";
import {scroller} from "react-scroll";
import {MAIN_BLUE_COLOR, MAIN_BORDER_COLOR} from "style/globalStyle/Color";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";

interface IProps {
  coworking: ICoworking,
  address: IAddress,
  tariffs: Record<string, ISpaceConverted[]>,
  coworkingServices: IAmenity[],
  possibleServices: IAmenityCategory[],
  photos: IGallery[],
  timetable: ITimetable,
  receptionTimetable: ITimetable,
  reservationUnits: IReservationUnit[],
  isActivated: boolean,
  onActivationRequest: () => void,
}

export function Activation(props: IProps) {
  const [t] = useTranslation();
  const [coworkingTariffType, setCoworkingTariffType] = useState("3");
  const [contentType, setContentType] = useState('tariff');

  const groupedTariff = useMemo(() => {
    try {
      return props.tariffs[coworkingTariffType].reduce((m: any, o: any) => {
        if (Array.isArray(m)) {
          m = {};
        }
        if (m[o.available_space?.['0']?.reservation_unit || 0]) {
          m[o.available_space?.['0']?.reservation_unit || 0].push(o);
        } else {
          m[o.available_space?.['0']?.reservation_unit || 0] = [o];
        }
        return m;
      }, []);
    } catch {
      return {};
    }
  }, [coworkingTariffType, props.tariffs])

  const amenities = useMemo(() => {
    return props.coworkingServices.map(s => ({
      id: s.id,
      icon: s.icon,
      label: getLocalOrEn(s.abbreviation) || ""
    }));
  }, [props.coworkingServices])

  const reservationTypes = useMemo(() => {
    return props.reservationUnits.map(u => ({
      label: getLocalOrEn(u.type),
      value: u.id
    }))
  }, [props.reservationUnits])

  const photoUrls = useMemo(() => {
    return props.photos.map(p => p.file)
  }, [props.photos])

  const onFinish = () => {
    props.onActivationRequest()
  }
  
  const completeAddress = useMemo(() => {
    let components = [
      getLocalOrEn(props.address.city.name),
      getLocalOrEn(props.address.street_line_1),
    ]

    if (props.address.zip_code) {
      components.push(props.address.zip_code)
    }

    return components.join(', ')
  }, [props.address.city.name, props.address.street_line_1, props.address.zip_code])

  return (
    <WrapperContentStyled style={{maxWidth: '100%', paddingTop: 47, width: '100%'}}>
      <Form>
        <WrapperTextStyled>
          {t('please_see_below_what_your_coworking_space_will_look_like_and_submit_a_request_for_product_placement')}
          <div style={{marginTop: 18}}/>
          {t('if_you_want_to_change_something_go_back_to_the_required_step')}
        </WrapperTextStyled>

        <div style={{marginTop: 48}}/>
        <Row>
          <Col span={24} order={3} lg={{order: 1, span: 12}}>
            <MainCenterTitleStyle
              style={{float: 'none'}}>{getLocalOrEn(props.coworking.title) || t('no_data')}</MainCenterTitleStyle>
            <AddressStyled>{completeAddress}</AddressStyled>
            {props.coworking.subway.map((item: any, index: number) => (
              <MetroStyled key={index}>
                {getLocalOrEn(item.name)}
              </MetroStyled>
            ))}
          </Col>
          <Col span={24} order={1} lg={{order: 2, span: 12}}>
            <Sceduler sceduler={props.timetable.is_24x7 ? props.receptionTimetable : props.timetable}/>
            <WrapperLinksStyled span={24}>
              <SharedStyled>
                <ShareAltOutlined/>
                <div style={{
                  borderBottom: `2px solid ${MAIN_BLUE_COLOR}`,
                  display: 'inline-block',
                  marginLeft: 10
                }}>{t('share_this')}</div>
              </SharedStyled>
              <SaveStyled>
                <HeartOutlined/>
                <div style={{
                  borderBottom: `2px solid ${MAIN_BLUE_COLOR}`,
                  display: 'inline-block',
                  marginLeft: 10
                }}>{t('save')}</div>
              </SaveStyled>
            </WrapperLinksStyled>
          </Col>
          <Col span={24} order={2} lg={{order: 3}}>
            <Galery urls={photoUrls}/>
          </Col>
        </Row>

        <ToTheBottomArrowStyled
          onClick={() => {
            setTimeout(() => {
              scroller.scrollTo("simular-listing", {
                duration: 1100,
                delay: 0,
                smooth: 'easeInOutQuart',
                offset: -20,
              })
            }, 10)
          }}
        ><Icon component={arrow}/></ToTheBottomArrowStyled>

        <WrapperTariffDescriptionChoiceStyled id="simular-listing">
          <TariffDescriptionChoiceStyled isActive={contentType === 'tariff'} onClick={() => setContentType('tariff')}>
            {t('pages.activation.tarifs')}
          </TariffDescriptionChoiceStyled>
          <TariffDescriptionChoiceStyled isActive={contentType === 'aboutTariff'}
                                         onClick={() => setContentType('aboutTariff')}>
            {t('pages.activation.about')}
          </TariffDescriptionChoiceStyled>
        </WrapperTariffDescriptionChoiceStyled>

        <WrapperTariffStyled isActive={contentType === 'tariff'}>
          <Row style={{marginTop: 10}}>
            <Col span={0} lg={{span: 8}} style={{alignItems: 'center'}}>
              <MainCenterTitleStyle style={{margin: 0}}>{t('rates_for_renting_a_workplace')}</MainCenterTitleStyle>
            </Col>
            <Col span={24} lg={{span: 16}}>
              <Row>
                <Col span={24} lg={{span: 6}} xl={{span: 9}} xxl={{span: 11}}>
                  <CustomCheckBoxStyled>{t('book_for_one_day')}</CustomCheckBoxStyled>
                </Col>
                <Col span={24} lg={{span: 16}} xl={{span: 13}} xxl={{span: 11}}>
                  <WrapperDateStyled>
                    <DateStyled>
                      <DateIconStyled/>
                      <ViewIconStyled/>
                      <TextDateStyled>04.12.2020</TextDateStyled>
                    </DateStyled>
                    <DateStyled>
                      <DateIconStyled/>
                      <ViewIconStyled/>
                      <TextDateStyled>05.12.2020</TextDateStyled>
                    </DateStyled>
                  </WrapperDateStyled>
                </Col>
                <Col span={0} lg={{span: 2}}>
                  <WrapperFilterStyled>
                    <FilterStyled/>
                  </WrapperFilterStyled>
                </Col>
              </Row>
            </Col>

            <Col span={24} lg={{span: 0}} style={{marginTop: 32}}>
              <div style={{width: 'calc(100% - 70px)', float: 'left'}}>
                <CascaderInput
                  name={'qw'}
                  required={false}
                  data={[
                    {label: t('workplaces'), value: '3'},
                    {label: t('cabinet'), value: '1'},
                    {label: t('meeting_room'), value: '2'},
                    {label: t('conference_hall'), value: '4'},
                  ]}
                  onChange={(values: Array<string>) => {
                    setCoworkingTariffType(values[0])
                  }}
                  initialValue={['3']}
                />
              </div>
              <WrapperFilterStyled>
                <FilterStyled/>
              </WrapperFilterStyled>
            </Col>

            <Col span={0} lg={{span: 24}}>
              <WrapperChangeStyled>
                <ChoiceStyled isActive={coworkingTariffType === '3'} onClick={() => setCoworkingTariffType('3')}
                              style={{borderRight: `1px solid ${MAIN_BORDER_COLOR}`}}>
                  {t('workplaces')}
                </ChoiceStyled>
                <ChoiceStyled isActive={coworkingTariffType === '1'} onClick={() => setCoworkingTariffType('1')}
                              style={{borderRight: `1px solid ${MAIN_BORDER_COLOR}`}}>
                  {t('cabinet')}
                </ChoiceStyled>
                <ChoiceStyled isActive={coworkingTariffType === '2'} onClick={() => setCoworkingTariffType('2')}
                              style={{borderRight: `1px solid ${MAIN_BORDER_COLOR}`}}>
                  {t('meeting_room')}
                </ChoiceStyled>
                <ChoiceStyled isActive={coworkingTariffType === '4'} onClick={() => setCoworkingTariffType('4')}>
                  {t('conference_hall')}
                </ChoiceStyled>
              </WrapperChangeStyled>
            </Col>

            <Col>
              {Object.keys(groupedTariff).map((key) => {
                return (
                  <Fragment key={key}>
                    <MainCenterTitleStyle style={{borderBottom: '2px solid black', display: 'inline-block'}}>
                      {t('pages.activation.for_x', {
                        x: reservationTypes.find(type => type.value.toString() === key)?.label
                      })}
                    </MainCenterTitleStyle>
                    <div style={{clear: 'both', height: 20}}/>

                    <WrapperTariffListStyled>
                      {groupedTariff[key].map((item: any) => (
                        <Tariff
                          item={item}
                          key={item.id}
                          reservationType={reservationTypes.find(type => type.value.toString() === key)}
                        />
                      ))}
                    </WrapperTariffListStyled>
                    <div style={{clear: 'both'}}/>
                  </Fragment>
                );
              })}
            </Col>
          </Row>
        </WrapperTariffStyled>

        <WrapperTextAboutCoworkingStyled isActive={contentType === 'aboutTariff'}>
          <MainCenterTitleStyle style={{marginTop: 64}}>{t('about_coworking')}</MainCenterTitleStyle>
          <div style={{clear: 'both'}}/>

          <TextAboutCoworkingStyled>{getLocalOrEn(props.coworking.description)}</TextAboutCoworkingStyled>

          <MainCenterTitleStyle style={{marginTop: 20}}>{t('services')}</MainCenterTitleStyle>
          <div style={{clear: 'both'}}/>
          <AmenitiesList amenities={amenities}/>
          <MainCenterTitleStyle style={{marginTop: 20}}>{t('on_the_map')}</MainCenterTitleStyle>
          <div style={{clear: 'both'}}/>

          {<YandexMap data={props.address} metro={props.coworking.subway}/>}
        </WrapperTextAboutCoworkingStyled>

        <WrapperDynamicButton isDisabled={props.isActivated} onClick={onFinish} text={t('send_a_request_for_accommodation')}/>
      </Form>
    </WrapperContentStyled>
  );
}
