import styled from 'styled-components';
import { MAIN_BORDER_COLOR } from 'style/globalStyle/Color';

export const AddItemButtomStyled = styled.a<{ name: string }>`
  flex: none;
  padding: 8px;
  display: block;
  cursor: pointer;
  ${props => !props.name ? `
    color: ${MAIN_BORDER_COLOR} !important;
    cursor: not-allowed;
  `: ``}
`
