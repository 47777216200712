import {EyeFilled, EyeInvisibleFilled} from '@ant-design/icons';
import {Form} from 'antd';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {CustomInputStyled} from './styles';
import {MAIN_BLUE_COLOR} from '../../../style/globalStyle/Color';
import {RuleObject} from "antd/lib/form";
import {validatePasswordRequired} from "../../validate/passwordValidate";

interface IPassword {
  name: string;
  required?: boolean;
  onChange?: any;
  normalize?: any;
  value?: string;
  placeholder?: string;
  validator?: (rule: RuleObject, value: string, callback: any) => void;
}

export const PasswordInput: FC<IPassword> = memo<IPassword>((props) => {
  const [t] = useTranslation();
  const rules = [
    {
      required: props.required,
      message: t('required_field'),
      validator: validatePasswordRequired,
    },
  ]
  if (props.validator) {
    rules.push({
      required: props.required,
      message: t('required_field'),
      validator: props.validator,
    })
  }
  return (
    <Form.Item
      name={props.name}
      rules={rules}
      normalize={props.normalize}
    >
      <CustomInputStyled
        placeholder={props.placeholder}
        onChange={props.onChange}
        type="password"
        iconRender={visible => (visible ? <EyeFilled style={{color: MAIN_BLUE_COLOR}}/> :
          <EyeInvisibleFilled style={{color: MAIN_BLUE_COLOR}}/>)}
      />
    </Form.Item>
  );
});

interface IPassword2 {
  name: string;
  required: boolean;
  onChange?: any;
  normalize?: any;
  value?: string;
  passwordName?: string;
  placeholder?: string;
}

export const PasswordInput2: FC<IPassword2> = memo((props) => {
  const [t] = useTranslation();
  return (
    <Form.Item
      name={props.name}
      dependencies={['password']}
      hasFeedback
      rules={[
        {
          required: props.required,
          message: t('required_field'),
        },
        ({getFieldValue}) => ({
          validator(_, value) {
            if (!value || getFieldValue(props.passwordName || 'password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
          },
        }),
      ]}
      normalize={props.normalize}
    >
      <CustomInputStyled
        placeholder={props.placeholder}
        onChange={props.onChange}
        type="password"
        iconRender={visible => (visible ? <EyeFilled style={{color: MAIN_BLUE_COLOR}}/> :
          <EyeInvisibleFilled style={{color: MAIN_BLUE_COLOR}}/>)}
      />
    </Form.Item>
  );
});
