import { Input } from 'antd';
import styled from 'styled-components';
import { MAIN_BORDER_COLOR } from '../../../style/globalStyle/Color';

export const CustomInputStyled = styled(Input)`
  border: 1px solid ${MAIN_BORDER_COLOR};
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
`;
