import { createSlice, current } from '@reduxjs/toolkit';
import { message } from 'antd';
import { getRequest, postRequest, putRequest } from '../../core/fetchWrapper';
import { formatMessageError } from '../../utils/formatMessageError';
import { AppThunk, RootState } from '../store';
import { setUserCoworkings } from './coworking';

declare global {
  interface Window {
    tokens: any;
  }
}

export const initialState: any = {
  userData: null,
  prompts: null,
  isPrompt: true,
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: () => {
      return initialState;
    },
    setUserData: (state, action) => {
      state.userData = { email: current(state)?.userData?.email, ...action.payload };
    },
    setPrompts: (state, action) => {
      state.prompts = action.payload;
    },
    setIsPrompts: (state, action) => {
      state.isPrompt = action.payload;
    },
  },
});

export const { setUserData, setPrompts, setIsPrompts, resetAuth } = AuthSlice.actions;

export const userDataSelector = (state: RootState) => state.auth.userData;

export const promptsSelector = (state: RootState) => state.auth.prompts;

export const isPromptSelector = (state: RootState) => state.auth.isPrompt;

export const createUser =
  (values: any, form: any, redirect: any): AppThunk =>
    async (dispatch: any) => {
      try {
        const response = await postRequest('/smp_api/users/', values);
        if (response.isError === true) {
          formatMessageError(response, form);
        } else {
          const tokens = await postRequest('/smp_api/users/obtain_token/', values);
          window.tokens = tokens;
          dispatch(setUserData(response));
          redirect();
        }
      } catch (error) {
        console.warn(' Error testAuth', error);
      }
    };

export const updateUser =
  (values: any, form: any, setCookie: any): AppThunk =>
    async (dispatch: any) => {
      try {
        const response = await postRequest('/smp_api/users/update_info/', values);
        if (response.isError === true) {
          formatMessageError(response, form);
          message.error(localStorage.getItem('i18nextLng') === 'ru' ? 'Пользовательские данные не обновленны.' : 'User data has not been updated.');
        } else {
          dispatch(setUserData(response));
          setCookie('userData', response, { path: '/' });
          message.success(localStorage.getItem('i18nextLng') === 'ru' ? 'Пользовательские данные обновленны.' : 'User data updated.');
        }
      } catch (error) {
        message.error(localStorage.getItem('i18nextLng') === 'ru' ? 'Пользовательские данные не обновленны.' : 'User data has not been updated.');
        console.warn(' Error testAuth', error);
      }
    };

export const updateUserPassword =
  (values: any, form: any): AppThunk =>
    async (dispatch: any) => {
      try {
        const id = values.id;
        delete values.id;
        const response = await putRequest(`/api/user/user/${id}/`, values);
        if (response.isError === true) {
          formatMessageError(response, form);
          message.error(localStorage.getItem('i18nextLng') === 'ru' ? 'Пароль не обновлен.' : 'User data has not been updated.');
        } else {
          dispatch(setUserData(response));
          message.success(localStorage.getItem('i18nextLng') === 'ru' ? 'Пароль обновлен.' : 'User data updated.');
        }
      } catch (error) {
        message.error(localStorage.getItem('i18nextLng') === 'ru' ? 'Пароль не обновлен.' : 'User data has not been updated.');
        console.warn(' Error testAuth', error);
      }
    };

export const loginUser =
  (values: any, form: any, history: any): AppThunk =>
    async (dispatch: any) => {
      try {
        const response = await postRequest('/smp_api/users/obtain_token/', values);
        if (response.isError === true) {
          formatMessageError({ 'field-to-update': [localStorage.getItem('i18nextLng') === 'en' ? 'The email or password is incorrect.' : 'Email или пароль неправильные.'] }, form);
        } else {
          window.tokens = response;
          console.warn(response, "response")
          const userData = await getRequest('/smp_api/users/info/');

          const responce = await getRequest(`/smp_api/coworking/`);
          const coworkingsData = {
            draft: responce.filter((item: any) => item.status === 'draft'),
            approve: responce.filter((item: any) => item.status === 'approve'),
            active: responce.filter((item: any) => item.status === 'active'),
            disabled: responce.filter((item: any) => item.status === 'disabled'),
          };
          dispatch(setUserData(userData));
          dispatch(setUserCoworkings(coworkingsData));

          if (coworkingsData.draft?.[0]) {
            history.push('/coworking/' + coworkingsData.draft?.[0]?.id);
          } else {
            history.push('/homePage/updateuser');
          }
        }
      } catch (error) { }
    };

export const passwordRecovery =
  (values: any): AppThunk =>
    async () => {
      try {
        await postRequest('/smp_api/users/password_recovery/', values);
        message.success(localStorage.getItem('i18nextLng') === 'ru' ? 'Инстукция была отправлена ​​на почту.' : 'The instruction was sent by mail.');
      } catch (error) {
        console.warn(' Error testAuth', error);
      }
    };

export const passwordReset =
  (values: any, form: any, history: any): AppThunk =>
    async () => {
      try {
        const response = await postRequest('/smp_api/users/password_reset/', values);
        if (response.isError === true) {
          formatMessageError(response, form);
        } else {
          history.push('/');
        }
      } catch (error) {
        console.warn(' Error testAuth', error);
      }
    };

export default AuthSlice.reducer;
