import {Form} from 'antd';
import {FormItemProps} from "antd/lib/form/FormItem";
import {useTranslation} from 'react-i18next';
import {CustomInputStyled} from './styles';

interface IText extends FormItemProps {
  required?: boolean;
  message?: any;
  validate?: any;

  onChange?: any;
  value?: string;
  onClick?: any;
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
}

export function TextInput({readOnly, disabled, onChange, onClick, value, id, required, message, validate, ...rest}: IText) {
  const [t] = useTranslation();
  return (
    <Form.Item
      rules={[
        {
          required: required,
          message: message || t('required_field'),
          validator: validate,
        },
      ]}
      {...rest}
    >
      <CustomInputStyled
        readOnly={readOnly}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        value={value}
        id={id}
      />
    </Form.Item>
  );
}
