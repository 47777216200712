import { RuleObject } from 'antd/lib/form';

export const PeopleCountCabinetValidateAnt = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';

  if (value.length > 5) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Too much value' : 'Слишком большое значение';
  }

  if (value === '' || value === undefined) {
    error = false;
  }
  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};
