import { Button } from 'antd';
import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../../style/globalStyle/Color';

export const CustomButtonStyled = styled(Button)`
  width: auto;
  height: 40px;
  background: ${MAIN_BLUE_COLOR} !important;
  border-color: ${MAIN_BLUE_COLOR} !important;
  margin-top: 28px;
  float: right;
  border-radius: 8px;
  padding: 8px 14px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.2px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
