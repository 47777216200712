import { RuleObject } from 'antd/lib/form';

export const stringValidateAnt = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text:any = '';
  if (value?.length > 50) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Maximum number of characters 50' : 'Максимальное количество символов 50';
  }

  if (rule.required && !value) {
    error = true;
    if(rule.message === 'null'){
      text = ''
    }else{
      text = rule.message || localStorage.getItem('i18nextLng') === 'en' ? 'Required field' : 'Обязательное поле'
    }
  }
  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};

export const stringValidateAnt25 = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';
  if (value?.length > 25) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Maximum number of characters 25' : 'Максимальное количество символов 25';
  }

  if (rule.required && !value) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Required field' : 'Обязательное поле';
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};

export const stringValidateAnt200 = (rule: RuleObject, value: string, callback: any) => {
  let error = false;
  let text = '';
  if (value?.length > 200) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Maximum number of characters 200' : 'Максимальное количество символов 200';
  }

  if (rule.required && !value) {
    error = true;
    text = localStorage.getItem('i18nextLng') === 'en' ? 'Required field' : 'Обязательное поле';
  }

  rule.message = text;
  if (error) {
    callback('Error!');
  } else {
    callback();
  }
};
