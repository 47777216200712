import styled from 'styled-components';

export const WrapperContentStyled = styled.div`
  /* width: 650px; */
  width: 100%;
  padding-left: 2px;
  padding-right: 5px;
  padding-bottom: 10px;
  overflow-x: hidden;
  min-height: 100%;
  @media (min-width: 318px) and (max-width: 900px) {
    padding-left: 10px;
    padding-right: 10px;
    margin: auto !important;
    width: 100% !important;
    position: relative;
  }
`;
