import { Form } from 'antd';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { formatSize } from '../../validate/formatSize';
import { onlyAnyNumber } from '../../validate/onlyNumber';
import { stringValidateAnt } from '../../validate/stringValidate';
import { CustomInputStyled } from './styles';

interface INumber {
  name: string | Array<any>;
  required: boolean;
  forceUpdate?: any;
  fieldKey?: any;
  validate?: any;
  disabled?: boolean;
}

export const NumberInput: FC<INumber> = memo<INumber>((props) => {
  const [t] = useTranslation();
  return (
    <Form.Item
      name={props.name}
      rules={[
        {
          required: props.required,
          message: t('required_field'),
          validator: props.validate || stringValidateAnt,
        },
      ]}
      normalize={(value) => formatSize(onlyAnyNumber(value))}
      fieldKey={props.fieldKey}
    >
      <CustomInputStyled
        disabled={props.disabled}
        onChange={() => {
          if (props.forceUpdate) {
            props.forceUpdate({});
          }
        }}
      />
    </Form.Item>
  );
});
