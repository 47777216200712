import {Coworking} from "components/coworking";
import {getCoworkings} from "pages/coworking/api/api";
import {ICoworking, ICoworkingStatus} from "pages/coworking/types";
import {EmptyCoworkingStyled, EmptyCoworkingTextStyled, WrapperCoworkingStyled} from "pages/home/coworkings/styles";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {WrapperContentStyled} from "style/globalStyle/wrapperContentStyled";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";

export function List() {
  const {t} = useTranslation()
  const history = useHistory()
  const [coworkings, setCoworkings] = useState<ICoworking[]>([])

  useEffect(() => {
    getCoworkings().then((coworkings) => {
      setCoworkings(coworkings)
    })
  }, [])

  const publishedCoworkings = useMemo(() => {
    return coworkings.filter(c => c.status === ICoworkingStatus.Approve)
  }, [coworkings])
  const waitingForPublishCoworkings = useMemo(() => {
    return coworkings.filter(c => c.status === ICoworkingStatus.Active)
  }, [coworkings])
  const disabledCoworkings = useMemo(() => {
    return coworkings.filter(c => c.status === ICoworkingStatus.Disabled)
  }, [coworkings])

  const onCreateCoworking = () => {
    history.push('/coworking')
  }

  return (
    <WrapperContentStyled style={{maxWidth: '100%', paddingTop: 47, width: '100%'}}>
      <MainCenterTitleStyle style={{marginTop: 0}}>{t('my_coworking_spaces')}</MainCenterTitleStyle>
      <div style={{clear: 'both', height: 13}}/>
      {publishedCoworkings.map(item => (
        <WrapperCoworkingStyled key={item.id}>
          <Coworking item={item}/>
        </WrapperCoworkingStyled>
      ))}
      {waitingForPublishCoworkings.map(item => (
        <WrapperCoworkingStyled key={item.id}>
          <Coworking item={item}/>
        </WrapperCoworkingStyled>
      ))}
      {disabledCoworkings.map(item => (
        <WrapperCoworkingStyled key={item.id}>
          <Coworking item={item}/>
        </WrapperCoworkingStyled>
      ))}
      <EmptyCoworkingStyled onClick={onCreateCoworking}>
        <EmptyCoworkingTextStyled>{t('add_coworking_space')}</EmptyCoworkingTextStyled>
      </EmptyCoworkingStyled>
    </WrapperContentStyled>
  )
}