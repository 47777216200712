import React, {FC, memo, useEffect} from 'react';
import ReactDOM from 'react-dom';
import {
  ButtonExitStyled,
  ModalStyled,
  TextStyled,
  WrapperModalStyled
} from './styles';

const GenericModal: FC<any> = memo((props) => {
  const close = () => {
    ReactDOM.render(
      <></>,
      document.getElementById('modal')
    );
  }

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const body = document.getElementsByTagName('body')[0];
      const scrollY = window.scrollY;
      body.style.position = 'fixed';
      body.style.top = `-${scrollY}px`;
      return (() => {
        const scrollY = body.style.top;
        body.style.position = '';
        body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      })
    }
  }, [])

  return (
    <WrapperModalStyled>
      <ModalStyled>
        <TextStyled>{props.message}</TextStyled>

        <ButtonExitStyled onClick={close}/>
      </ModalStyled>
    </WrapperModalStyled>
  );
});


export const createGenericModal = (message: React.ReactNode) => {
  ReactDOM.render(
    <GenericModal message={message}/>,
    document.getElementById('modal')
  );
}