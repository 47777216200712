import {getRequest, postRequest} from "core/fetchWrapper";
import {
  I18NLangString,
  IAddress,
  IAmenity,
  IAmenityCategory,
  ICoworking,
  ICRM,
  IGallery,
  IReservationUnit,
  ISeatingConfig,
  ISpace,
  ISpaceCategory,
  ISpaceConverted,
  ITimetable
} from "pages/coworking/types";
import {textInDescription} from "utils/descriptionTariffUtils";

interface IFailedRequest {
  details: string,
  isError: true
}

export function throwErrorIfFailed<T>(response: T | IFailedRequest) {
  if (typeof response === 'object' && !Array.isArray(response) && response !== null && 'isError' in response) {
    throw new Error('Request failed')
  }
  return response
}

export function getCoworkings(): Promise<ICoworking[]> {
  return getRequest(`/smp_api/coworking/`).then(throwErrorIfFailed)
}

export function getCoworkingData(id: number): Promise<ICoworking> {
  return getRequest(`/smp_api/coworking/${id}/`).then(throwErrorIfFailed)
}

export function getCoworkingAddress(id: number): Promise<IAddress> {
  return getRequest(`/smp_api/coworking/${id}/address/`).then(throwErrorIfFailed)
}

export function getCoworkingTimetable(id: number): Promise<ITimetable> {
  return getRequest(`/smp_api/coworking/${id}/timetable/`).then(throwErrorIfFailed)
}

export function getCoworkingReceptionTimetable(id: number): Promise<ITimetable> {
  return getRequest(`/smp_api/coworking/${id}/reception_timetable/`).then(throwErrorIfFailed)
}

export function getCoworkingAmenities(id: number): Promise<IAmenity[]> {
  return getRequest(`/smp_api/coworking/${id}/amenities/`).then(throwErrorIfFailed)
}

export function getCoworkingTariffs(id: number): Promise<Record<string, ISpaceConverted[]>> {
  return getRequest(`/smp_api/coworking/${id}/spaces/`).then(throwErrorIfFailed).then(async (response: ISpaceCategory[]) => {
    let tariffs: Record<string, ISpace[]> = response.reduce((obj, item) => {
      return {
        ...obj,
        [item.type.id.toString()]: item.spaces
      }
    }, {})

    const spacePromises = Object.values(tariffs).flatMap(spaces => spaces.flatMap(space => ({
      id: space.id,
      imagePromise: getRequest(`/smp_api/space/${space.id}/photos/`).then(throwErrorIfFailed),
      amenitiesPromise: postRequest(`/smp_api/coworking/${id}/space/amenities/`, {id: space.id}).then(throwErrorIfFailed)
    })))

    const spaceResults = await Promise.all(spacePromises.map(async sp => {
      const res = await Promise.all([sp.imagePromise, sp.amenitiesPromise])
      return Promise.resolve({
        id: sp.id,
        images: res[0],
        amenities: res[1]
      })
    }))

    return Object.fromEntries(Object.entries(tariffs).map(([key, spaces]) => {
      return [key, spaces.map(space => {
        const promises = spaceResults.find(sp => sp.id === space.id)!
        const images: IGallery[] = promises.images
        const amenities = promises.amenities

        let descriptions: Record<string, I18NLangString> = {}
        const file = images.length > 0 ? {
          url: images[0].file,
          uid: images[0].file_id.toString(),
          thumbUrl: images[0].file,
          type: 'image/png',
          name: ''
        } : {
          uid: '',
          name: ''
        }
        const expected: ISpaceConverted = {
          ...space,
          description: {},
          tarifServices: amenities.filter((i: any) => i.price_per_unit === 0).map((i: any) => i.id),
          payableTarifServices: amenities.filter((i: any) => i.price_per_unit !== 0).map((i: any) => i.id),
          available_space: space.available_space.map(s => ({
            ...s,
            seating_config: s.seating_config ? s.seating_config.id : undefined,
            reservation_unit: [s.reservation_unit]
          })),
          images: {
            images: {
              fileList: images.map(((i) => {
                descriptions[i.file_id] = i.description;
                return {
                  url: i.file,
                  uid: i.file_id.toString(),
                  thumbUrl: i.file,
                  type: 'image/png',
                  name: ''
                }
              })),
              file
            },
            isError: false,
            order: [],
            ...descriptions
          }
        }

        if (space.description.en) {
          expected.description.en = textInDescription(space.description.en)
        }
        if (space.description.ru) {
          expected.description.ru = textInDescription(space.description.ru)
        }

        return expected
      })]
    }))
  })
}

export function getCoworkingPhotos(id: number): Promise<IGallery[]> {
  return getRequest(`/smp_api/coworking/${id}/photos/`).then(throwErrorIfFailed).then((photos : IGallery[]) => {
    return photos.sort((a, b) => a.order - b.order)
  })
}

export function getCrms(): Promise<ICRM[]> {
  return getRequest('/smp_api/crm/').then(throwErrorIfFailed)
}

export function getAmenities(): Promise<IAmenityCategory[]> {
  return getRequest(`/smp_api/amenity/`).then(throwErrorIfFailed)
}

export function getReservationUnits(): Promise<IReservationUnit[]> {
  return getRequest(`/smp_api/reservation/unit/`).then(throwErrorIfFailed)
}

export function getSeatingConfigs(): Promise<ISeatingConfig[]> {
  return getRequest(`/smp_api/seating_config/`).then(throwErrorIfFailed)
}
