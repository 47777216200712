import {Form} from 'antd';
import {FC, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {Link, useHistory} from 'react-router-dom';
import {DynamicButton} from 'components/inputs/dinamicButton';
import {EmailInput} from 'components/inputs/emailInput';
import {EmptyItemError} from 'components/inputs/emptyItemError/styles';
import {PasswordInput} from 'components/inputs/passwordInput';
import {loginUser} from 'store/slices/auth';
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {InputTitleStyled} from 'style/titles/inputTitleStyle';
import {MainCenterTitleStyle} from 'style/titles/mainCenterTitleStyle';
import {WrapperSignInStyled, WrapperTextStyled} from './styles';

export const SignIn: FC = memo(() => {
  const [t] = useTranslation();
  let history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const onFinish = (values: any) => {
    dispatch(loginUser(values, form, history));
  };

  return (
    <WrapperSignInStyled>
      <WrapperContentStyled className="content" style={{maxWidth: 410, marginLeft: 'auto', marginRight: 'auto'}}>
        <Form form={form} onFinish={onFinish} onChange={() => form.setFields([{name: "field-to-update", errors: []}])}>

          <MainCenterTitleStyle>{t('authorization')}</MainCenterTitleStyle>

          <div style={{clear: 'both', height: 13}}/>

          <InputTitleStyled>{t('email')}</InputTitleStyled>
          <EmailInput name={'email'} required={true}/>

          <InputTitleStyled>{t('password')}</InputTitleStyled>
          <PasswordInput name={'password'} required={true}/>

          <EmptyItemError name={'field-to-update'} shouldUpdate></EmptyItemError>

          <DynamicButton text={t('sign_in')} style={{width: '100%'}}/>

          <WrapperTextStyled>{t('perhaps_you_want_to')} <Link
            to="/signup">{t('register')}</Link>.</WrapperTextStyled>
          <WrapperTextStyled><Link to="/resetpassword">{t('forgot_your_password')}</Link></WrapperTextStyled>
        </Form>
      </WrapperContentStyled>
    </WrapperSignInStyled>
  );
});
