export async function get_subway_name_for_language_by_coordinates(coordinates: [Number, Number], lang: string) {
  const url = new URL('https://geocode-maps.yandex.ru/1.x/')
  const params: Record<string, string> = {
    apikey: process.env.REACT_APP_YANDEX_API_KEY!,
    geocode: coordinates.join(','),
    kind: 'metro',
    results: '1',
    format: 'json',
    lang,
  }
  url.search = new URLSearchParams(params).toString();

  const response = await fetch(url.toString())
  if (!response.ok) {
    throw new Error('Could not get response')
  }
  const json = await response.json()
  if (json.response.GeoObjectCollection.metaDataProperty.GeocoderResponseMetaData.found === '0') {
    throw new Error('No subway found for provided coordinates')
  }
  return json.response.GeoObjectCollection.featureMember[0].GeoObject.name
}