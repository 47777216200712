import {NextStepButton} from "pages/coworking/commons/NextStepButton";
import {PromptOnUnsavedChanges} from "pages/coworking/commons/PromptOnUnsavedChanges";
import {useEffect, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";
import {WrapperContentStyled} from 'style/globalStyle/wrapperContentStyled';
import {Form, notification, Row} from 'antd';
import {LeftColumn} from "style/colomn/leftColumn";
import {InputTitleStyled} from "style/titles/inputTitleStyle";
import {EmailInput} from "components/inputs/emailInput";
import {ResetButton} from "components/buttons/resetButton";
import {MainCenterTitleStyle} from "style/titles/mainCenterTitleStyle";
import {getLocalOrEn} from "utils/lang";
import {I18NLangString, IAddress, ICreateAddress} from "../types";
import {PhoneInput} from "components/inputs/phoneInput";
import {RightColumn} from "style/colomn/rightColumn";
import {WrapperAddressForm} from "components/modaAutoCompileForm";
import {TextInput} from "components/inputs/textInput";
import {ISubway} from "components/modaAutoCompileForm/YandexLocation";

interface IProps {
  coworking: { email: string, phone_number: I18NLangString, subways: ISubway[] } | null,
  manager: { phone_number: string, email: string, name: { description: string } } | null,
  address: IAddress | null,
  isActivated: boolean,
  onChange: (coworking: {
    email: string,
    phone_number: string,
    subway: ISubway[]
  }, manager: {
    phone_number: string,
    email: string,
    name: string
  }, address?: ICreateAddress) => Promise<void>,
  onNextPageRequest: () => void,
  isLastStep: boolean,
}

export interface IForm {
  coworking_email: string,
  phone_number: string,
  nameManager: string,
  phoneManager: string,
  emailManager: string,

  zip: string,
  address: string,
  country: I18NLangString,
  city: I18NLangString,
  street_line_1: I18NLangString,
  street_line_2: I18NLangString,
  state: I18NLangString,
  building_name: I18NLangString,
  map_service: string,

  floor: string,
  floors_total: number,
  latitude: number,
  longitude: number,
  listMetro: ISubway[],
}

export function Contacts(props: IProps) {
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values: IForm) => {
    setShouldPrompt(false)
    setIsLoading(true)
    return new Promise<void>(async (resolve, reject) => {
      const address = values.street_line_1 ? {
        country: values.country,
        city: values.city,
        state: values.state,
        street_line_1: values.street_line_1,
        street_line_2: values.street_line_2,
        unparsed: values.address,
        zip_code: values.zip,
        latitude: values.latitude.toString(),
        longitude: values.longitude.toString(),
        floor: values.floor.toString(),
        floors_total: values.floors_total,
        map_service: values.map_service
      } : undefined

      props.onChange({
        email: values.coworking_email,
        phone_number: values.phone_number,
        subway: values.listMetro
      }, {
        phone_number: values.phoneManager,
        email: values.emailManager,
        name: values.nameManager,
      }, address).then(() => {
        notification.success({
          message: t('pages.contacts.save_notification')
        })
      }).then(() => {
        if (!props.isActivated) {
          props.onNextPageRequest()
        }
        setShouldPrompt(true)
        resolve()
      })
    }).finally(() => {
      setIsLoading(false)
    })
  }

  const initialValue = useMemo(() => {
    let initialValue = {
      coworking_email: props.coworking?.email,
      phone_number: props.coworking ? getLocalOrEn(props.coworking.phone_number) : '',
      nameManager: props.manager?.name.description,
      phoneManager: props.manager?.phone_number,
      emailManager: props.manager?.email,
      floor: props.address?.floor,
      floors_total: props.address?.floors_total.toString(),
      address: props.address?.unparsed,
      latitude: props.address?.latitude,
      longitude: props.address?.longitude,
      listMetro: props.coworking?.subways,
      map_service: props.address?.map_service
    };
    if (initialValue.coworking_email === 'example@example.com') {
      initialValue.coworking_email = '';
    }
    return initialValue
  }, [props.address?.floor, props.address?.floors_total, props.address?.latitude, props.address?.longitude, props.address?.map_service, props.address?.unparsed, props.coworking, props.manager])
  useEffect(() => {
    form.resetFields()
  }, [form, initialValue])

  const [shouldPrompt, setShouldPrompt] = useState(!props.isLastStep)
  const [isLoading, setIsLoading] = useState(false)

  return (
    <WrapperContentStyled className="content">
      <Form
        form={form}
        initialValues={initialValue}
        onFinish={onFinish}
      >
        <PromptOnUnsavedChanges
          shouldPrompt={shouldPrompt}
          onFinish={onFinish}
        />
        <MainCenterTitleStyle style={{marginTop: 0}}>{t('coworking_contacts')}</MainCenterTitleStyle>
        <ResetButton
          onClick={() => {
            form.resetFields(['coworking_email', 'phone_number']);
          }}
        >
          {t('reset')}
        </ResetButton>
        <div style={{clear: 'both', height: 25}}/>
        <InputTitleStyled>{t('address')}*</InputTitleStyled>
        <WrapperAddressForm/>

        <Row style={{width: '100%'}}>
          <LeftColumn span={12}>
            <InputTitleStyled>{t('coworking_email')}*</InputTitleStyled>
            <EmailInput name={'coworking_email'} required={true}/>
          </LeftColumn>
          <RightColumn span={12}>
            <InputTitleStyled>{t('coworking_phone')}*</InputTitleStyled>
            <PhoneInput name={'phone_number'} required={true}/>
          </RightColumn>
        </Row>

        <MainCenterTitleStyle style={{fontSize: 19, marginTop: 20}}>{t('manager_contacts')}</MainCenterTitleStyle>
        <div style={{clear: 'both', height: 25}}/>

        <InputTitleStyled>{t('fistname_lastname')}*</InputTitleStyled>
        <TextInput name={'nameManager'} required={true}/>

        <Row style={{width: '100%'}}>
          <LeftColumn span={12}>
            <InputTitleStyled>{t('phone')}*</InputTitleStyled>
            <PhoneInput name={'phoneManager'} required={true}/>
          </LeftColumn>
          <RightColumn span={12}>
            <InputTitleStyled>{t('email')}*</InputTitleStyled>
            <EmailInput name={'emailManager'} required={true}/>
          </RightColumn>
        </Row>

        <NextStepButton loading={isLoading} isActivated={props.isActivated}/>
      </Form>
    </WrapperContentStyled>
  );
}
