/* eslint-disable jsx-a11y/anchor-is-valid */
import { Form, Row } from 'antd';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { PasswordInput, PasswordInput2 } from '../../../components/inputs/passwordInput';
import { passwordReset } from '../../../store/slices/auth';
import { LeftColumn } from '../../../style/colomn/leftColumn';
import { RightColumn } from '../../../style/colomn/rightColumn';
import { WrapperContentStyled } from '../../../style/globalStyle/wrapperContentStyled';
import { InputTitleStyled } from '../../../style/titles/inputTitleStyle';
import { MainCenterTitleStyle } from '../../../style/titles/mainCenterTitleStyle';
import { DynamicButtonStyled, WrapperNewPasswordStyled, WrapperTextStyled } from './styles';
import { Base64 } from 'js-base64';

function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

export const NewPassword: FC = memo(() => {
  const [t] = useTranslation()
  let history = useHistory();
  let query = useQuery();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [, forceUpdate] = useState({});
  useEffect(() => {
    forceUpdate({});
  }, []);

  const onFinish = (values: any) => {
    dispatch(passwordReset({...values,...{token: Base64.decode(query.get("token") || '')}},form,history))
  };

  return (
    <WrapperNewPasswordStyled>
      <WrapperContentStyled className="content" style={{maxWidth: 410, marginLeft: 'auto', marginRight: 'auto'}}>
        <Form form={form} onFinish={onFinish} onChange={()=>form.setFields([{ name: "field-to-update", errors: []}])}>

          <MainCenterTitleStyle>{t("enter_a_new_password")}</MainCenterTitleStyle>
          <div style={{clear:'both', height: 13}}/>

          <Row>
            <LeftColumn span={24} sm={12}>
              <InputTitleStyled>{t('password')}</InputTitleStyled>
              <PasswordInput name={'password'} required={true} />
            </LeftColumn>
            <RightColumn span={24} sm={12}>
              <InputTitleStyled>{t('password_repeat')}</InputTitleStyled>
              <PasswordInput2 name={'new_password'} required={true} />
            </RightColumn>
          </Row>

          <DynamicButtonStyled text={t("save")} />
          <WrapperTextStyled style={{textAlign: 'center'}}>{t('perhaps_you_want_to')} <a onClick={() => history.push('/')}>{t('log_in')}</a>.</WrapperTextStyled>
        </Form>
      </WrapperContentStyled>
    </WrapperNewPasswordStyled>
  );
});