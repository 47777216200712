import { Button } from 'antd';
import styled from "styled-components";
import { MAIN_BLUE_COLOR, MAIN_RED_COLOR } from '../../../style/globalStyle/Color';
import exit from './icons/exit.svg';


export const WrapperModalStyled = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(19, 20, 24, 0.5);
  backdrop-filter: blur(4px);
  display: block;
  z-index: 20;
`

export const ModalStyled = styled.div`
  width: 350px;
  height: 180px;
  background-color: #f0f0f0;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  border-radius: 8px;
`

export const TextStyled = styled.div`
  margin: 20px;
  font-size: 20px;
  text-align: center;
`

export const ButtonStyled = styled(Button)`
  margin-top: 20px;
  width: 44%;
  margin-left: 4%;
  height: 40px;
  background: ${MAIN_BLUE_COLOR};
  border-color: ${MAIN_BLUE_COLOR};
  color: white;
  float: left;
`

export const ButtonSuccessStyled= styled(Button)`
  margin-top: 20px;
  width: 44%;
  margin-left: 4%;
  height: 40px;
  background: ${MAIN_RED_COLOR};
  border-color: ${MAIN_RED_COLOR} !important;
  color: white !important;
  float: left;
  &:hover{
    color: ${MAIN_RED_COLOR} !important;
  }
`

export const ButtonExitStyled = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background-image: url(${exit});
  background-size: cover;
  background-position: center;
`;