import {Modal, Form} from "antd";
import {GoogleLocation} from "components/modaAutoCompileForm/GoogleLocation";
import {IForm} from "pages/coworking/Contacts/Contacts";
import {I18NLangString} from "pages/coworking/types";
import React, {useEffect, useState} from 'react';
import {TextInput} from '../inputs/textInput';
import {WrapperAddressStyled} from './styled';
import {ISubway, YandexLocation} from './YandexLocation';

export interface IResultDetails {
  country: I18NLangString,
  city: I18NLangString,
  street_line_1: I18NLangString,
  street_line_2: I18NLangString,
  state: I18NLangString,
  building_name: I18NLangString,
  unparsed: string,
  zip_code: string
  floor: string,
  floors_total: number,
  latitude: number,
  longitude: number,
  listMetro: ISubway[],
}

export enum Service {
  Google = 'GOOGLE',
  Yandex = 'YA',
}

export function WrapperAddressForm() {
  const [isViewModal, setIsViewModal] = useState(false);
  const [serviceName, setServiceName] = useState(Service.Yandex);
  const form = Form.useFormInstance<IForm>()
  const address = Form.useWatch('address', form)
  const floors_total = Form.useWatch('floors_total', form)
  const floor = Form.useWatch('floor', form)
  const listMetro = Form.useWatch('listMetro', form)
  const mapService = Form.useWatch('map_service', form)

  useEffect(() => {
    setServiceName((mapService as Service) ?? Service.Yandex)
  }, [mapService])

  useEffect(() => {
    if (window.innerWidth <= 576) {
      const body = document.getElementsByTagName('body')[0];
      if (isViewModal) {
        const scrollY = window.scrollY;
        window.scrollTo(0, 0);
        setTimeout(() => {
          body.style.position = 'fixed';
          body.style.top = `-${scrollY}px`;
        }, 20)
      } else {
        const scrollY = body.style.top;
        body.style.top = '';
        body.style.position = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }
  }, [isViewModal])

  const onYandexLocationSelected = (data: IResultDetails) => {
    form.setFieldsValue({
      address: data.unparsed,
      latitude: data.latitude,
      longitude: data.longitude,
      floor: data.floor,
      floors_total: data.floors_total,
      zip: data.zip_code,
      building_name: data.building_name,
      country: data.country,
      city: data.city,
      state: data.state,
      street_line_1: data.street_line_1,
      street_line_2: data.street_line_2,
      listMetro: data.listMetro,
      map_service: Service.Yandex
    })
  }

  const onGoogleLocationSelected = (data: IResultDetails) => {
    form.setFieldsValue({
      address: data.unparsed,
      latitude: data.latitude,
      longitude: data.longitude,
      floor: data.floor,
      floors_total: data.floors_total,
      zip: data.zip_code,
      building_name: data.building_name,
      country: data.country,
      city: data.city,
      state: data.state,
      street_line_1: data.street_line_1,
      street_line_2: data.street_line_2,
      listMetro: data.listMetro,
      map_service: Service.Google
    })
  }

  return (
    <>
      <WrapperAddressStyled onClick={() => setIsViewModal(true)}>
        <TextInput
          readOnly
          name='address'
          required={true}
        />
      </WrapperAddressStyled>

      <Form.Item name='longitude' hidden></Form.Item>
      <Form.Item name='latitude' hidden></Form.Item>
      <Form.Item name='floor' hidden></Form.Item>
      <Form.Item name='floors_total' hidden></Form.Item>
      <Form.Item name='zip' hidden></Form.Item>
      <Form.Item name='building_name' hidden></Form.Item>
      <Form.Item name='country' hidden></Form.Item>
      <Form.Item name='city' hidden></Form.Item>
      <Form.Item name='state' hidden></Form.Item>
      <Form.Item name='street_line_1' hidden></Form.Item>
      <Form.Item name='street_line_2' hidden></Form.Item>
      <Form.Item name='listMetro' hidden></Form.Item>
      <Form.Item name='map_service' hidden></Form.Item>

      {window.ymaps && (
        <Modal
          visible={isViewModal}
          closable={false}
          footer={null}
          onCancel={() => setIsViewModal(false)}
          width={1060}
        >
          {serviceName === Service.Yandex && (
            <YandexLocation
              unparsedAddress={address}
              selectedMetros={listMetro}
              floorsTotal={floors_total}
              floor={floor}
              onRequestClose={() => setIsViewModal(false)}
              onChange={onYandexLocationSelected}
              onServiceChange={name => setServiceName(name)}
            />
          )}
          {serviceName === Service.Google && (
            <GoogleLocation
              unparsedAddress={address}
              selectedMetros={listMetro}
              floorsTotal={floors_total}
              floor={floor}
              onRequestClose={() => setIsViewModal(false)}
              onChange={onGoogleLocationSelected}
              onServiceChange={name => setServiceName(name)}
            />
          )}
        </Modal>
      )}
    </>
  );
}
