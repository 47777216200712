import {Input} from 'antd';
import {MaskedInput} from 'antd-mask-input';
import styled from 'styled-components';

export const CustomInputStyled = styled(Input)`
  height: 40px;
  font-size: 16px;
`;

export const TitleTimeStyled = styled.div`
  font-size: 15px;
  margin-right: 30px;
  font-weight: 500;
  width: 100px;
  line-height: 50px;
  float: left;
`;

export const CustomMaskedInput = styled(MaskedInput)`
  width: 139px;
  height: 40px;
  font-size: 16px;
  border-radius: 4px;
  &::placeholder{
    font-weight: 600;
  }
  @media (min-width: 318px) and (max-width: 576px) {
    width: 80px;
  }
`
