/* eslint-disable @typescript-eslint/no-unused-vars */
export function setCookie(name: string, value: any, days: number) {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 1 * 1 * 4 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (JSON.stringify(value) || '') + expires + '; path=/';
}
export function eraseCookie(name: string) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
