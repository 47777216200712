import styled from "styled-components"
import { MAIN_BORDER_COLOR } from "../../style/globalStyle/Color";
import photo from './icons/photo.svg';


export const WrapperTariffStyled = styled.div`
  width: 302px;
  height: 386px;
  box-shadow: 4px 4px 8px rgba(88, 100, 159, 0.06);
  border-radius: 8px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  float: left;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-bottom: 40px;
  margin-right: 16px;
  @media (min-width: 318px) and (max-width: 991px) {
    margin-right: 0px;
  }
`

export const TariffAvatarStyled = styled.div`
  height: 45%;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`

export const WrapperMorePhotoButtun = styled.div`
  position: absolute;
  top: 124px;
  right: 8px;
  width: 121px;
  height: 40px;
  background: #FFFFFF;
  border-radius: 8px;
  cursor: pointer;

  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  padding-left: 44px;
  padding-top: 8px;

  &::after{
    content: '';
    position: absolute;
    display: block;
    left: 18px;
    top: 12px;
    width: 17px;
    height: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${photo});
  }
`

export const PriceTariffStyled = styled.div`
  color: #2E60CA;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 700;
  margin-left: 15px;
  letter-spacing: -0.4px;
`

export const MoreInformationTariffStyled = styled.div`
  left: 16px;
  bottom: 70px;
  position: absolute;
  width: 270px;
  height: 40px;
  border: 1px solid ${MAIN_BORDER_COLOR};
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;

  cursor: pointer;
`

export const ReservationTariffStyled = styled.div`
  left: 16px;
  bottom: 22px;
  position: absolute;
  width: 270px;
  height: 40px;
  background: #9DADD0;
  border-radius: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  color: white;

  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.3px;

  cursor: pointer;
`

export const TitleTariffStyled = styled.div`
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  margin-left: 15px;
  margin-top: 2px;
`