import {filter} from "lodash";
import {FC, memo, useState} from "react";
import {useTranslation} from "react-i18next";
import {AmenitiesItemStyled, MoreAmenitiesButtonStyled, WrapperAmenytiesStyled} from "./styles";

interface IAmenitiesList {
  amenities: { id: number, icon: string, label: string }[];
}

export const AmenitiesList: FC<IAmenitiesList> = memo((props) => {
  const defaultIcon = 'done'
  const [t] = useTranslation()
  const [isView, setIsView] = useState(false)
  const notUndefined = filter(props?.amenities, item => typeof item !== 'undefined')
  return (
    <WrapperAmenytiesStyled>
      {(notUndefined.length > 12 && !isView) &&
        <>
          {notUndefined.slice(0, 12).map(i => (
            <AmenitiesItemStyled key={i.id}>
              <span className="material-icons">
                {i.icon !== '' ? i.icon : defaultIcon}
              </span>
              {i?.label}
            </AmenitiesItemStyled>
          ))}
          <MoreAmenitiesButtonStyled
            onClick={() => setIsView(true)}>{t("show_all_services")} ({notUndefined.length})</MoreAmenitiesButtonStyled>
        </>
      }
      {(notUndefined.length <= 12 || isView) &&
        <>
          {notUndefined.map(i => (
            <AmenitiesItemStyled key={i.id}>
              <span className="material-icons">
                {i.icon !== '' ? i.icon : defaultIcon}
              </span>
              {i.label}
            </AmenitiesItemStyled>
          ))}
        </>
      }
    </WrapperAmenytiesStyled>
  );
});
