import {Form, Modal} from "antd";
import {Location} from "history";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Prompt, useHistory} from "react-router-dom";

interface IProps {
  shouldPrompt?: boolean,
  onFinish?: (values: any) => Promise<void>
}

export function PromptOnUnsavedChanges({shouldPrompt, onFinish}: IProps) {
  const history = useHistory()
  const {t} = useTranslation();
  const form = Form.useFormInstance()
  const [isModalOpen, setIsModalOpen] = useState<Location | null>(null)

  const handleBlockedRoute = (location: Location, isTouched: boolean) => {
    if (isTouched && shouldPrompt) {
      form.validateFields().then(() => {
        setIsModalOpen(location)
      })
      return false
    }

    return true
  }

  const handleConfirmNavigationClick = () => {
    if (onFinish) {
      form.validateFields()
        .then(values => onFinish(values))
        .then(() => {
          form.resetFields()
          isModalOpen && history.push(isModalOpen)
          setIsModalOpen(null)
        })
    } else {
      isModalOpen && history.push(isModalOpen)
      setIsModalOpen(null)
    }
  }

  return (
    <>
      <Form.Item noStyle shouldUpdate>
        {(form => (
          <Prompt
            when={form.isFieldsTouched(false)}
            message={(location) => handleBlockedRoute(location, form.isFieldsTouched(false))}
          />
        ))}
      </Form.Item>
      <Modal
        open={isModalOpen !== null}
        onCancel={() => setIsModalOpen(null)}
        onOk={handleConfirmNavigationClick}
        okText={t('confirm_changes_prompt.confirm')}
        cancelText={t('confirm_changes_prompt.cancel')}
      >
        {t('confirm_changes_prompt.text')}
      </Modal>
    </>
  )
}
