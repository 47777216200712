import {onlyPositiveNumber, validateNotZeroNumberAnt} from "components/validate/numberValidate";
import React from 'react';
import {Form} from 'antd';
import {useTranslation} from 'react-i18next';
import {symbolForCurrency} from "utils/price";
import {CustomInputNumberStyled, WrapperPlaceholderStyled, WrapperPriceInput} from './styles';

interface IRadio {
  name: string | Array<any>;
  required: boolean;
  forceUpdate?: any;
  fieldKey?: any;
  isDisabled?: boolean;
  validator?: any
  currency: string;
}

export function ConvertFromDisplayPriceStringToNumber(value: string) {
  return Number(value.replaceAll(' ', '').replaceAll(',', ''))
}

export function PriceInput(props: IRadio) {
  const [t] = useTranslation();

  const ConvertFromNumberToDisplayPriceString = (value: number | null) => {
    if (value === null) {
      return ''
    }
    const currencyFractionDigits = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: props.currency,
    }).resolvedOptions().maximumFractionDigits;
    return value.toLocaleString('en-US', {
      maximumFractionDigits: currencyFractionDigits
    })
  }

  return (
    <WrapperPriceInput>
      <Form.Item
        initialValue={"0.00"}
        name={props.name}
        normalize={ConvertFromNumberToDisplayPriceString}
        rules={[
          {
            required: props.required && !props.isDisabled,
            message: t('incorrect_input'),
            validator: validateNotZeroNumberAnt
          },
          {
            required: props.required && !props.isDisabled,
            message: t('incorrect_input'),
            validator: onlyPositiveNumber
          }
        ]}
        fieldKey={props.fieldKey}
        label={
          <WrapperPlaceholderStyled>
            {!props.isDisabled &&
              <>
                {symbolForCurrency(props.currency)}
              </>
            }
          </WrapperPlaceholderStyled>
        }
      >
        <CustomInputNumberStyled
          max={99999999999}
          onPressEnter={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onChange={() => {
            if (props.forceUpdate) {
              props.forceUpdate({});
            }
          }}
          placeholder={'N/A'}
          disabled={props.isDisabled}
        />
      </Form.Item>
    </WrapperPriceInput>
  );
}
