import styled from 'styled-components';
import { MAIN_BLUE_COLOR } from '../../../style/globalStyle/Color';

export const WrapperSignInStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  padding-bottom: 30px;
`;

export const WrapperTextStyled = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  a{
    border-bottom: 1px solid ${MAIN_BLUE_COLOR} !important;
    font-weight: bold !important;
  }
`