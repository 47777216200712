import { Form } from "antd";
import styled from "styled-components";

export const EmptyItemError = styled(Form.Item)`
  margin: 0 !important;
  padding: 0 !important;
  height: auto !important;
  min-height: 0 !important;
  .ant-form-item-control-input{
    margin: 0 !important;
    padding: 0 !important;
    height: 0 !important;
    min-height: 0 !important;
  }
`