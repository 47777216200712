import { Form } from 'antd';
import styled from "styled-components";
import { MAIN_BORDER_COLOR } from '../../../style/globalStyle/Color';
import exit from './icons/exit.svg';

export const WrapperServicesListStyled = styled.div<any>`
  position: relative;
  max-height: 56px;
  overflow: hidden;
  border: 1px solid ${MAIN_BORDER_COLOR};
  transition-duration: 0s;
  ${props => props.isView ? `
    max-height: 400px;
  `:``}
  padding-left: 8px;
  padding-right: 8px;
`

export const RowStyled = styled.div`
  width: calc(100% + 8px);
  height: 1px;
  background-color: ${MAIN_BORDER_COLOR};
  margin-left: -4px;
`

export const ExitIconStyled = styled.div<any>`
  width: 15px;
  height: 9px;
  background-image: url(${exit});
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 24px;
  right: 24px;
  ${props => !props.isView ? `
    transform: rotate(180deg);
  `:``}
`

export const FormStyled = styled(Form)`
`

export const ServicesListStyled = styled.div`
  & .WrapperServicesList:first-child{
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }
  & .WrapperServicesList:last-child{
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
`

export const WrapperExitStyled = styled.div`
  position: relative;
  height: 56px;
  width: 100%;
  cursor: pointer;
`