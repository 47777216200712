export const formatMessageError = (values: any, form: any) => {
  for (let i in values) {
    form.setFields([
      {
        name: i,
        errors: values[i],
      },
    ]);
  }
};
